exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi {\n  font-size: 12px;\n  float: left;\n  margin-top: 3px;\n  display: block;\n  width: 100%;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallProfileImageWrapper_2Br1y3NUF9rDKkVtha5TIc {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallProfileImage_trADNS1YBfKuV_phJSw-E {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n  background-size: cover;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallProfileImageText_1sXg3aD0Xk6kRDiiWKEpjD {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallName_35h0Urh7UXEj_HK4diBzaZ {\n  float: none;\n  width: calc(100% - 40px);\n  height: 16px;\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-weight: 600;\n  white-space: nowrap;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallMetadata_1FRiOXznROXQCdVou84_Vg {\n  float: none;\n  width: calc(100% - 40px);\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #858585;\n  margin-top: 2px;\n}\n\n.IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi .IA_personaSmallModifiedDate_3JVeQe-ZT8Rfz05L6CEDrW {\n  float: none;\n  height: 12px;\n  width: 100%;\n  margin-top: 1px;\n}", ""]);

// Exports
exports.locals = {
	"IA_personaSmall": "IA_personaSmall_1TN-_Qe8_aQwzZLMYKMnZi",
	"IA_personaSmallProfileImageWrapper": "IA_personaSmallProfileImageWrapper_2Br1y3NUF9rDKkVtha5TIc",
	"IA_personaSmallProfileImage": "IA_personaSmallProfileImage_trADNS1YBfKuV_phJSw-E",
	"IA_personaSmallProfileImageText": "IA_personaSmallProfileImageText_1sXg3aD0Xk6kRDiiWKEpjD",
	"IA_personaSmallName": "IA_personaSmallName_35h0Urh7UXEj_HK4diBzaZ",
	"IA_personaSmallMetadata": "IA_personaSmallMetadata_1FRiOXznROXQCdVou84_Vg",
	"IA_personaSmallModifiedDate": "IA_personaSmallModifiedDate_3JVeQe-ZT8Rfz05L6CEDrW"
};