exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_articles_3gHU1MVY7H6blHhD0G_qZF {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_C2SF3r4eFuTdCNRXi-M4t {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2rNAnCR-1nLC4lL4jsmBYn {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_23i1u22y9q-XyCSYHKn4ux {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_articles": "IA_articles_3gHU1MVY7H6blHhD0G_qZF",
	"IA_filters": "IA_filters_C2SF3r4eFuTdCNRXi-M4t",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2rNAnCR-1nLC4lL4jsmBYn",
	"IA_paginationPageCount": "IA_paginationPageCount_23i1u22y9q-XyCSYHKn4ux"
};