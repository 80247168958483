import * as React from 'react';
import * as styles from './TextField.css';
import { IATooltip } from '../tooltip/Tooltip';
import { IAIcon } from '../icon/Icon';

export interface ITextFieldProps {
  id?: string;
  label?: string;
  text: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  secure?: boolean;
  style?: React.CSSProperties;
  inputFieldStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  darkMode?: boolean;
  borderRadius?: number;
  onChange: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  toolTipText?: string;
  placeholderIcon?: string
  highlightColor?: string;
  onlyPaste?: boolean;
  showClearTextButton?: boolean;
  onEnterKeyPressed?: () => void;
  getRefCallback?: (inputElement: HTMLInputElement) => void;
  clearTextCallback?: () => void;
}

export interface ITextFieldState {
  text: string;
}

export class IATextField extends React.Component<ITextFieldProps, ITextFieldState> {
  private inputRef: HTMLInputElement;
  constructor(props: ITextFieldProps) {
    super(props);
    this.state = {
      text: props.text
    };
  }

  public componentWillReceiveProps(props: ITextFieldProps): void {
    this.setState({ text: props.text });
  }

  private onChange(text: string): void {
    this.setState({ text });
    this.props.onChange(text);
  }

  private onChangeOnlyPaste(event) {
    // only allow delete beside pasting
    var inputType = event.nativeEvent.inputType;
    if (inputType == "deleteContentBackward" || inputType == "deleteContentForward") {
      var text = event.target.value;
      this.onChange(text);
    }
  }

  private onPaste(event) {
    const text = event.clipboardData.getData("text/plain");
    this.onChange(text);
  }

  private onCut(event) {
    var text = event.currentTarget.value;
    event.clipboardData.setData("text/plain", text);
    text = "";
    this.onChange(text); // clear state
    event.preventDefault();
  }

  public render(): JSX.Element {
    let label;
    if (this.props.label) {
      label = <div style={{ float: "left", ...this.props.labelStyle }}>{this.props.label}</div>;
      if (this.props.required) {
        label = <div style={{ float: "left", ...this.props.labelStyle }}>{this.props.label} <span style={{ color: "red" }}>*</span></div>;
      }
    }
    const style = this.props.style ?? {};
    if (!style["--highlight-color"]) {
      style["--highlight-color"] = this.props.highlightColor ?? "black";
    }
    return (
      <div
        className={(this.props.darkMode ? styles.IA_textFieldDarkMode : styles.IA_textField) + " " + (!this.props.className ? this.props.className : "")}
        style={style}>
        {this.props.label &&
          label
        }
        {this.props.toolTipText &&
          <IATooltip
            content="Tooltip"
            type="bubble"
            rootStyles={{
              marginTop: 2,
              marginLeft: 5
            }}
            componentContent={(
              <div>{this.props.toolTipText}</div>
            )}
          >
            <IAIcon
              title="Info"
              size={14}
              color="Black"
            />
          </IATooltip>
        }
        <input
          id={this.props.id}
          ref={(ref) => {
            this.inputRef = ref;
            if (this.props.getRefCallback) {
              this.props.getRefCallback(ref);
            }
          }}
          type={this.props.secure ? "password" : "text"}
          placeholder={this.props.placeholder}
          value={this.state.text != undefined ? this.state.text : ""}
          onChange={(event) => this.props.onlyPaste ? this.onChangeOnlyPaste(event) : this.onChange(event.target.value)}
          onBlur={this.props.onBlur ? () => this.props.onBlur() : undefined}
          onFocus={this.props.onFocus ? () => this.props.onFocus() : undefined}
          disabled={this.props.disabled}
          style={{
            borderRadius: this.props.borderRadius,
            paddingRight: this.props.showClearTextButton ? 20 : undefined,
            ...this.props.inputFieldStyle
          }}
          onPaste={this.props.onlyPaste ? (event) => this.onPaste(event) : undefined}
          onCut={this.props.onlyPaste ? (event) => this.onCut(event) : undefined}
          onKeyDown={(event) => {
            if (this.props.onEnterKeyPressed && event.key === "Enter") {
              this.props.onEnterKeyPressed();
            }
          }}
        />
        {this.props.placeholderIcon && !this.state.text &&
          <div className={styles.IA_placeholderIcon}>
            <IAIcon
              title={this.props.placeholderIcon}
              size={14}
              color={this.props.darkMode ? '#a8a8a8' : 'black'}
            />
          </div>
        }
        {this.props.showClearTextButton && this.state.text &&
          <div
            style={{
              position: "absolute",
              right: 5,
              height: this.inputRef?.getBoundingClientRect().height,
              display: "flex",
              alignItems: "center",
              bottom: 0
            }}
          >
            <IAIcon
              url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Dismiss/SVG/ic_fluent_dismiss_48_regular.svg"
              size={13}
              color={this.props.highlightColor}
              style={{
                marginTop: 3,
              }}
              onClick={() => {
                if (this.props.clearTextCallback) {
                  this.props.clearTextCallback();
                } else {
                  this.onChange("");
                }
              }}
            />
          </div>
        }
      </div>
    );
  }
}