import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Players.css';
import { IACard } from '../../../card/Card';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { LocalizationService } from '../../../../services/LocalizationService';
import { PlayerEditPanel } from './playerEditPanel/PlayerEditPanel';
import { IPlayer, IPlayerGroup } from '../../../../interfaces/IPlay';
import PlayService from '../../../../services/PlayService';
import { IAHeader } from '../../../header/Header';
import { IASpinner } from '../../../spinner/Spinner';
import { IAIconDropDown } from '../../../iconDropDown/IconDropDown';
import { PlayHelper } from '../../PlayHelper';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { Group } from '../../reusableComponents/group/Group';
import { ConnectingNewPlayerPopup } from './connectingNewPlayerPopup/ConnectingNewPlayerPopup';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { Helper } from '../../../../Helper';
import { LicensePopup } from './licensePopup/LicensePopup';
import { IAListView } from '../../../listView/ListView';
import { GettingStartedOverlay } from '../gettingStartedOverlay/GettingStartedOverlay';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  isLoading: boolean;
  selectedPlayerId?: string;
  selectedUserSorting?: IIconDropDown;
  playerToDelete?: string;
  isShowingLicensePopup: boolean;
  restartPlayerState: "success" | "failure";
  restartedPlayerTitle: string
  restartAllPlayersPopup: boolean;
  loadingLicence: boolean;
}

@inject('store')
@observer
export class Players extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private cardViewService: IACardViewService = new IACardViewService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      isShowingLicensePopup: false,
      restartPlayerState: undefined,
      restartedPlayerTitle: undefined,
      restartAllPlayersPopup: false,
      loadingLicence: false,
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    if (!this.props.showSkeleton) {
      this.fetchData();
    }
  }

  public componentWillUnmount(): void {
    this.props.store.playlists = undefined;
    this.props.store.playlistsCount = undefined;
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton) {
      this.fetchData();
    }
  }

  private fetchData() {
    this.setState({ isLoading: true });
    this.getGroups();
    this.getLicense();
  }

  private getLicense(): void {
    this.setState({ loadingLicence: true });
    PlayService.getPlayerCountOnTenant(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((numberOfPlayersOnTenant: number) => {
      PlayService.getSubscriptions(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((subscriptions) => {
        if (subscriptions?.length > 0) {
          PlayService.getSubscription(this.props.store.environment, this.props.store.token, this.props.store.tenantId, subscriptions[0].subscriptionId).then((subscription: any) => {
            console.log(subscription);
            this.props.store.hasDiscount = subscription.discounts?.length > 0;
            this.props.store.subscription = subscription;
            if (subscription && subscription.addOns?.length > 0) {
              this.props.store.licensedNumberOfPlayers = subscription.addOns[0].quantity + 1;
            }
            this.props.store.numberOfPlayersOnTenant = numberOfPlayersOnTenant;
            this.setState({ loadingLicence: false });
          });
        } else {
          this.props.store.numberOfPlayersOnTenant = numberOfPlayersOnTenant;
          this.setState({ loadingLicence: false });
        }
      });
    })
  }

  private getGroups(): void {
    PlayService.getGroups(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then(groups => {
      this.props.store.groups = groups.value;
      this.props.store.groupsCount = groups.count;
      this.getPlayers();
    });
  }

  private getPlayers(): void {
    if (this.props.store.groups !== undefined) {
      PlayService.getPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then((players) => {
        this.props.store.players = players.value;
        this.props.store.playersCount = players.count;
        this.setState({ isLoading: false });
      });
    }
  }

  private restartPlayer(playerId: string): void {
    const restartedPlayerTitle = this.props.store.players.find(player => player.id === playerId)?.title;
    PlayService.restartPlayer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, playerId)
      .then(() => { this.setState({ restartPlayerState: "success", restartedPlayerTitle }) })
      .catch(() => this.setState({ restartPlayerState: "failure", restartedPlayerTitle }))
  }

  private addPlayerToGroup(player: IPlayer, maxCardWidthAvailable, currentRowNumber, numberOfCardsPerRow): any {
    let playerToReturn;
    let labels = [];
    let preview;
    labels.push(PlayHelper.getPlayerState(player, this.localizationService));
    if (player.playlistTitle) {
      labels.push(
        {
          text: player.playlistTitle,
          icon: "Playlist3",
          backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
          textColor: this.props.store.darkMode ? "#ffffff" : "#333333",
          borderRadius: 5
        }
      );
    }

    if ((localStorage.getItem("IA_playersViewType") && JSON.parse(localStorage.getItem("IA_playersViewType")).value === "list" || localStorage.getItem("IA_playersViewType") == undefined) || this.props.store.isMobile) {
      const columns = this.props.store.isMobile ?
        [
          {
            header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
            type: "text",
            text: player.title,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          },
          {
            header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
            type: "icon",
            iconUrl: PlayHelper.getPlayerState(player, this.localizationService).iconUrl,
            tooltip: PlayHelper.getPlayerState(player, this.localizationService).text,
            color: PlayHelper.getPlayerState(player, this.localizationService).textColor,
            iconSize: 18,
            marginTop: 16
          }
        ]
        :
        [
          {
            header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
            type: "text",
            text: player.title,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          },
          {
            header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
            type: "icon",
            iconUrl: PlayHelper.getPlayerState(player, this.localizationService).iconUrl,
            tooltip: PlayHelper.getPlayerState(player, this.localizationService).tooltip,
            color: PlayHelper.getPlayerState(player, this.localizationService).textColor,
            iconSize: 18,
            marginTop: 16
          },
          {
            header: this.localizationService.strings.PlayAdmin_Playlist?.toUpperCase(),
            type: "text",
            text: player.playlistTitle,
            backgroundColor: PlayHelper.getPlayerState(player, this.localizationService).backgroundColor,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          },
          {
            header: this.localizationService.strings.PlayAdmin_Modified?.toUpperCase(),
            type: "date",
            date: player.modified,
            color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
          },
          {
            header: this.localizationService.strings.PlayAdmin_ModifiedBy?.toUpperCase(),
            type: "text",
            text: player?.modifiedBy?.displayName,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }
        ];
      playerToReturn = (
        {
          id: player.id,
          columns: columns,
          buttons: [
            // {
            //   action: "follow",
            //   icon: player.isFavorite ? "Star Filled" : "Star",
            //   color: this.props.store.highlightColor
            // },
            {
              action: "delete",
              icon: "Trash",
              color: this.props.store.highlightColor
            },
            {
              action: "edit",
              icon: "Edit",
              color: this.props.store.highlightColor
            },
            {
              action: "restart",
              icon: "Restart",
              color: this.props.store.highlightColor,
              svgStyles: { rotate: '90deg' }
            },
          ]
        }
      );
    }
    if (localStorage.getItem("IA_playersViewType") && JSON.parse(localStorage.getItem("IA_playersViewType")).value === "card" && !this.props.store.isMobile) {
      playerToReturn = (
        <IACard
          key={`player_${player.id}`}
          id={player.id}
          showSkeleton={false}
          title={player.title ? player.title : this.localizationService.strings.PlayAdmin_NewPlayer}
          date={new Date(player.modified)}
          author={player.modifiedBy?.displayName}
          darkMode={this.props.store.darkMode}
          cover={player.playlistId ? {
            imageURL: preview ? preview : this.props.store.darkMode ? "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default-dark.jpg" : "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default.jpg"
          } : {
            html: (
              <div className={styles.IA_newPlayerCover}>
                {player?.displayCode}
              </div>
            )
          }}
          labels={labels}
          onClickOpen={() => this.setState({ selectedPlayerId: player.id })}
          width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
          isMobile={this.props.store.isMobile}
          alwaysShowFooter
          footerElements={[
            {
              icon: "Edit",
              color: this.props.store.highlightColor,
              onClick: () => this.setState({ selectedPlayerId: player.id })
            },
            {
              icon: "Trash",
              color: this.props.store.highlightColor,
              onClick: () => this.setState({ playerToDelete: player.id })
            },
            {
              icon: "Restart",
              color: this.props.store.highlightColor,
              onClick: () => this.restartPlayer(player.id),
              svgStyles: { rotate: '90deg' }
            },
            // {
            //   icon: player.isFavorite ? "Star Filled" : "Star",
            //   color: this.props.store.highlightColor,
            //   onClick: () => alert("TODO")
            // }
          ]
          }
          styles={{
            marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : 20,
            marginBottom: 20,
            width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }}
          design={{
            highlightColor: this.props.store.highlightColor,
            showDropShadow: true,
            borderRadius: 5,
            backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
            hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
          }}
        />
      );
    }
    return playerToReturn;
  }

  private addGroup(groupName: string, playersInGroup: any[]): any {
    return (
      <Group
        key={groupName}
        label={groupName}
        count={playersInGroup?.length}
        darkMode={this.props.store.darkMode}
      >
        {(localStorage.getItem("IA_playersViewType") && JSON.parse(localStorage.getItem("IA_playersViewType")).value === "list" || localStorage.getItem("IA_playersViewType") == undefined) ?
          <IAListView
            id="ListViewOfPlayers"
            listElements={playersInGroup}
            backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
            hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
            buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
            defaultAction={"edit"}
            darkMode={this.props.store.darkMode}
            selectedAction={(action: string, id: string) => {
              switch (action) {
                case "edit":
                  this.setState({ selectedPlayerId: id });
                  break;
                case "delete":
                  this.setState({ playerToDelete: id });
                  break;
                case "restart":
                  this.restartPlayer(id)
                  break;
                case "follow":
                  alert("TODO");
                  break;
                default:
                  break;
              }
            }}
          />
          :
          playersInGroup
        }
      </Group>
    );
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deletePlayer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.playerToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedPlayerId: undefined, playerToDelete: undefined });
      this.getPlayers();
    });
  }

  private removeGettingStarted(): void {
    Helper.setLocalStorage("IA_GettingStarted_Players", "hide");
    this.forceUpdate();
  }

  public render(): JSX.Element {
    const groups: JSX.Element[] = [];
    const markers = []
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, 20, 250, 5);
    if (this.props.store.players?.length > 0) {
      // Failing players
      const failingPlayers = [];
      let currentRowNumber = 1;
      this.props.store.players.forEach((player: IPlayer) => {
        if (PlayHelper.getPlayerState(player, this.localizationService).tooltip === this.localizationService.strings.PlayAdmin_PlayerStateFailing) {
          failingPlayers.push(this.addPlayerToGroup(player, maxCardWidthAvailable, currentRowNumber, numberOfCardsPerRow));
          if (currentRowNumber == numberOfCardsPerRow) {
            currentRowNumber = 1;
          } else {
            currentRowNumber++;
          }
        }
      });
      if (failingPlayers?.length > 0) {
        groups.push(this.addGroup(this.localizationService.strings.PlayAdmin_FailingPlayers, failingPlayers));
      }
      // New players
      const newPlayers = [];
      currentRowNumber = 1;
      this.props.store.players.forEach((player: IPlayer) => {
        if (!player.playlistId) {
          newPlayers.push(this.addPlayerToGroup(player, maxCardWidthAvailable, currentRowNumber, numberOfCardsPerRow));
          if (currentRowNumber == numberOfCardsPerRow) {
            currentRowNumber = 1;
          } else {
            currentRowNumber++;
          }
        }
      });
      if (newPlayers?.length > 0) {
        groups.push(this.addGroup(this.localizationService.strings.PlayAdmin_NewPlayers, newPlayers));
      }
      // Players without a group
      const playersInGroup = [];
      currentRowNumber = 1;
      this.props.store.players.forEach((player: IPlayer) => {
        let playerHasNoExistingGroup = true;
        if (!(player.groups == undefined || player.groups?.length === 0)) {
          if (this.props.store.groups == undefined || this.props.store.groups.length === 0) {
            playerHasNoExistingGroup = true;
          } else {
            this.props.store.groups.forEach((group: IPlayerGroup) => {
              player.groups.forEach((id: string) => {
                if (id === group.id) {
                  playerHasNoExistingGroup = false;
                }
              });
            });
          }
        }
        if (player.playlistId && playerHasNoExistingGroup) {
          playersInGroup.push(this.addPlayerToGroup(player, maxCardWidthAvailable, currentRowNumber, numberOfCardsPerRow));
          if (currentRowNumber == numberOfCardsPerRow) {
            currentRowNumber = 1;
          } else {
            currentRowNumber++;
          }
        }
      });
      if (playersInGroup?.length > 0) {
        groups.push(this.addGroup(this.props.store.groups == undefined || this.props.store.groups.length === 0 ? this.localizationService.strings.PlayAdmin_AllPlayers : this.localizationService.strings.PlayAdmin_PlayersWithoutGroup, playersInGroup));
      }
      // Players with a group
      if (this.props.store.groups?.length > 0) {
        this.props.store.groups.forEach((group: IPlayerGroup) => {
          const playersInGroup = [];
          // Players
          let currentRowNumber = 1;
          this.props.store.players.forEach((player: IPlayer) => {
            if (player.groups?.length > 0 && player.groups?.indexOf(group.id) != -1) {
              playersInGroup.push(this.addPlayerToGroup(player, maxCardWidthAvailable, currentRowNumber, numberOfCardsPerRow));
              if (currentRowNumber == numberOfCardsPerRow) {
                currentRowNumber = 1;
              } else {
                currentRowNumber++;
              }
            }
          });
          if (playersInGroup?.length > 0) {
            groups.push(this.addGroup(group.title, playersInGroup));
          }
        });
      }
      // Map
      if (this.props.store.players && this.props.store.players.length > 0) {
        this.props.store.players.forEach((player: IPlayer) => {
          if (player.title) {
            markers.push(
              {
                markerOffset: -30,
                name: player.title,
                coordinates: [player.longitude, player.latitude],
                onClick: () => this.setState({ selectedPlayerId: player.id })
              }
            );
          }
        });
      }
    }
    return (
      <div
        className={styles.IA_players}
      >
        <PlayerEditPanel
          id={this.state.selectedPlayerId}
          onClose={() => {
            this.setState({ selectedPlayerId: undefined });
            this.props.store.playlists = undefined;
            this.props.store.playlistsCount = undefined;
            this.getGroups();
          }}
        />
        <IAHeader
          id="play-players-header"
          title={this.localizationService.strings.PlayAdmin_YourPlayers}
          description={this.localizationService.strings.PlayAdmin_YourPlayersDesc}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#7b9ac2"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          onCollapseOrExpand={() => this.forceUpdate()}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.removeGettingStarted();
              console.log(this.props.store.numberOfPlayersOnTenant + " >= " + this.props.store.licensedNumberOfPlayers)
              if (this.props.store.numberOfPlayersOnTenant >= this.props.store.licensedNumberOfPlayers) {
                this.setState({ isShowingLicensePopup: true });
              } else {
                this.props.store.isShowingConnectNewPlayerPopUp = true;
              }
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add",
            disbaled: this.state.loadingLicence
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourPlayers,
              text: this.localizationService.strings.PlayAdmin_YourPlayersDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-player.png",
            },
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_ArePlayersRunning,
              text: this.localizationService.strings.PlayAdmin_ArePlayersRunningDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-player-error.png",
            }
          ]}
        />
        {!this.props.store.isMobile &&
          <div className={styles.IA_filters}>
            {/* <IAIconDropDown
              options={PlayHelper.getSortingOptions(this.localizationService)}
              selectedOption={localStorage.getItem("IA_playersSortingOption") ? JSON.parse(localStorage.getItem("IA_playersSortingOption")) : PlayHelper.getSortingOptions(this.localizationService)[0]}
              // label={this.localizationService.strings.PlayAdmin_Sorting}
              disabled
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_playersSortingOption", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 320,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
              darkMode={this.props.store.darkMode}
            />
            <IAIconDropDown
              options={PlayHelper.getUserSortingOptions(this.localizationService)}
              selectedOption={this.state.selectedUserSorting}
              // label={this.localizationService.strings.PlayAdmin_UserSorting}
              disabled
              placeholder={this.localizationService.strings.PlayAdmin_UserSortingPlaceholder}
              clearable
              onChange={(selectedUserSorting: IIconDropDown) => this.setState({ selectedUserSorting }, () => this.getPlayers())}
              style={{
                minWidth: 170,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
              darkMode={this.props.store.darkMode}
            /> */}
            <IAIconDropDown
              options={PlayHelper.getViewTypes("players")}
              selectedOption={localStorage.getItem("IA_playersViewType") ? JSON.parse(localStorage.getItem("IA_playersViewType")) : PlayHelper.getViewTypes()[1]}
              // label={this.localizationService.strings.PlayAdmin_ViewType}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_playersViewType", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 70,
                float: "right",
                marginBottom: 20
              }}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
            />
            <IAButton
              label="Refresh all"
              icon="Restart"
              buttonColor={this.props.store.darkMode ? "#414141" : "#fff"}
              textColor={this.props.store.darkMode ? "#cccccc" : "#808080"}
              iconColor={this.props.store.darkMode ? "#cccccc" : "#808080"}
              borderColor={this.props.store.darkMode ? "" : "#cccccc"}
              iconMarginTop={4}
              iconRotationDegree={90}
              style={{
                float: "right",
                marginRight: 20,
                height: 38,
                borderRadius: 5,
                padding: "0 10px"
              }}
              onClick={() => {
                this.setState({ restartAllPlayersPopup: true });
                PlayService.restartPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id)
              }}
            />
          </div>
        }
        {this.state.isLoading ?
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginLeft: "calc(50% - 20px)"
            }}
          />
          :
          <>
            {localStorage.getItem("IA_playersViewType") && JSON.parse(localStorage.getItem("IA_playersViewType")).value === "map" && !this.props.store.isMobile ?
              <ComposableMap
                projection="geoAzimuthalEqualArea"
                projectionConfig={{
                  rotate: [-10.0, -52.0, 0],
                  scale: 2000
                }}
              >
                <Geographies geography={"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"}>
                  {({ geographies }) =>
                    geographies
                      .filter(d => d.properties.REGION_UN === "Europe")
                      .map(geo => (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill="#EAEAEC" s
                          stroke="#D6D6DA"
                        />
                      ))
                  }
                </Geographies>
                {markers.map(({ name, coordinates, markerOffset, onClick }) => (
                  <Marker
                    key={name}
                    coordinates={coordinates}
                    onClick={() => onClick()}
                  >
                    <g
                      fill="none"
                      stroke="#fd5e1d"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(-12, -24)"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </g>
                    <text
                      textAnchor="middle"
                      y={markerOffset}
                      style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
                    >
                      {name}
                    </text>
                  </Marker>
                ))}
              </ComposableMap>
              :
              groups
            }
          </>
        }
        {!localStorage.getItem("IA_GettingStarted_Players") &&
          <GettingStartedOverlay
            onHide={() => this.removeGettingStarted()}
            height={localStorage.getItem("play-players-header") ? window.innerHeight - 210 : window.innerHeight - 390}
            top={localStorage.getItem("play-players-header") ? 126 : 300}
            videoUrl={this.localizationService.strings.PlayAdmin_GettingStartedPlayersVideoUrl}
            posterUrl={this.localizationService.strings.PlayAdmin_GettingStartedPlayersPosterUrl}
            title={this.localizationService.strings.PlayAdmin_GettingStartedPlayersTitle?.toUpperCase()}
          />
        }
        {this.props.store.isShowingConnectNewPlayerPopUp &&
          <ConnectingNewPlayerPopup
            close={() => this.props.store.isShowingConnectNewPlayerPopUp = false}
            connectNow={(playerId: string) => {
              this.props.store.isShowingConnectNewPlayerPopUp = false;
              this.setState({ selectedPlayerId: playerId });
            }}
          />
        }
        {this.state.isShowingLicensePopup &&
          <LicensePopup
            close={() => this.setState({ isShowingLicensePopup: false })}
          />
        }
        {this.state.playerToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeletePlayer}
            close={() => this.setState({ playerToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.playerToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ playerToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.restartPlayerState && this.state.restartedPlayerTitle &&
          <Popup
            headline={this.state.restartPlayerState == "success" ? this.localizationService.strings.PlayAdmin_RestartPlayerSuccess.replace("[Player]", this.state.restartedPlayerTitle) : this.localizationService.strings.PlayAdmin_RestartPlayerFailure}
            close={() => this.setState({ restartPlayerState: undefined, restartedPlayerTitle: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <IAButton
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                label={this.localizationService.strings.PlayAdmin_Close?.toUpperCase()}
                onClick={() => this.setState({ restartPlayerState: undefined, restartedPlayerTitle: undefined })}
                borderRadius={5}
                style={{
                  float: "left"
                }}
              />
            }
          />
        }
        {this.state.restartAllPlayersPopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_RestartingAllPlayers}
            close={() => this.setState({ restartAllPlayersPopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <IAButton
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                label={this.localizationService.strings.PlayAdmin_Close?.toUpperCase()}
                onClick={() => this.setState({ restartAllPlayersPopup: false })}
                borderRadius={5}
                style={{
                  float: "left"
                }}
              />
            }
          />
        }
      </div>
    );
  }


}