exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_1TCP1zGZZckPtLjuOm3Qwl {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_3-n8f2MV1Exh_cWNpH1qE, .IA_dividerDarkMode_39WxyGLhKLBnuVfhPxuy3b {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_2fCHbw8g-zbE-3R5ipBnrj {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_1TCP1zGZZckPtLjuOm3Qwl",
	"IA_divider": "IA_divider_3-n8f2MV1Exh_cWNpH1qE",
	"IA_dividerDarkMode": "IA_dividerDarkMode_39WxyGLhKLBnuVfhPxuy3b",
	"IA_billingInfo": "IA_billingInfo_2fCHbw8g-zbE-3R5ipBnrj"
};