exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_slides_1p7PghkjLdpgmjpYA-TG2S {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_1L5ZWpSsDGUGC0k9rtVNsY {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2bqY70mQGOfPQXLH7VHqow {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1TR3iBUs-yfflb8A_eg3Ko {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_slides": "IA_slides_1p7PghkjLdpgmjpYA-TG2S",
	"IA_filters": "IA_filters_1L5ZWpSsDGUGC0k9rtVNsY",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2bqY70mQGOfPQXLH7VHqow",
	"IA_paginationPageCount": "IA_paginationPageCount_1TR3iBUs-yfflb8A_eg3Ko"
};