import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './License.css';
import { Store } from '../../stores';
import { IListElement, IPlayCompanyInformation, IPlayer, IPlayProfile } from '../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Helper } from '../../../../Helper';
import PlayService from '../../../../services/PlayService';
import { Group } from '../../reusableComponents/group/Group';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import DateTimeService from '../../../../services/DateTimeService';
import { IASpinner } from '../../../spinner/Spinner';
import { CompanyInformation } from './companyInformation/CompanyInformation';
import { IAPivot } from '../../../pivot/Pivot';
import { IAHeader } from '../../../header/Header';
import { CompanyInformationForm } from './companyInformation/CompanyInformationForm';
import '../../../../styles/BasicIA.css'
import { IAListView } from '../../../listView/ListView';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  profileList: JSX.Element[];
  playerToDelete?: string;
  selectedProfileId?: string;
  loading: boolean;
  saving: boolean;
  saved: boolean;
  loadingLicence: boolean;
  wantedNumberOfPlayers: number;
  selectedPivot: string;
  showContactInformationPopup: boolean;
}

@inject('store')
@observer
export class License extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      profileList: undefined,
      loading: false,
      loadingLicence: false,
      wantedNumberOfPlayers: 1,
      selectedPivot: "license",
      showContactInformationPopup: false,
      saving: false,
      saved: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.display && !this.props.display) {
      this.getProfiles();
      if (!this.props.store.licensedNumberOfPlayers || this.props.store.licensedNumberOfPlayers === 1) {
        this.getLicense();
      } else {
        this.setState({ wantedNumberOfPlayers: this.props.store.licensedNumberOfPlayers });
      }
      this.getCompanyInformation();
    }
  }

  private getLicense(addCreditCard?: boolean): void {
    this.setState({ loadingLicence: true });
    PlayService.getSubscriptions(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((subscriptions) => {
      if (subscriptions?.length > 0) {
        PlayService.getSubscription(this.props.store.environment, this.props.store.token, this.props.store.tenantId, subscriptions[0].subscriptionId).then((subscription: any) => {
          if (subscription && subscription.addOns?.length > 0) {
            this.props.store.subscription = subscription;
            this.props.store.licensedNumberOfPlayers = subscription.addOns[0].quantity + 1;
            this.props.store.hasDiscount = subscription.discounts?.length > 0
            this.setState({ wantedNumberOfPlayers: subscription.addOns[0].quantity + 1 });
          }
          this.setState({ loadingLicence: false });
          if (addCreditCard && this.props.store.subscription?.hostedPageLinks?.paymentInfo) {
            window.open(`${this.props.store.subscription?.hostedPageLinks?.paymentInfo}`)
          }
        });
      } else {
        this.setState({ loadingLicence: false });
      }
      PlayService.getPlayerCountOnTenant(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((numberOfPlayersOnTenant: number) => {
        this.props.store.numberOfPlayersOnTenant = numberOfPlayersOnTenant;
        this.setState({ loadingLicence: false });
      })
    });
  }

  private getCompanyInformation(): void {
    PlayService.getCustomer(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((companyInformation: IPlayCompanyInformation) => {
      if (companyInformation) {
        this.props.store.companyInformation = companyInformation;
      }
    });
  }

  private getListElement(profile: IPlayProfile, player: IPlayer): IListElement {
    return ({
      object: profile,
      id: player.id,
      columns: [
        {
          header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
          type: "text",
          text: player.title,
          backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
          color: this.props.store.darkMode ? "#ffffff" : "#333333",
        },
        {
          header: this.localizationService.strings.PlayAdmin_LastActive?.toUpperCase(),
          type: "text",
          text: Math.round((new Date().getTime() - new Date(player.lastSuccessfulRequest).getTime()) / (1000 * 3600 * 24)) > 0 ? `${Math.round((new Date().getTime() - new Date(player.lastSuccessfulRequest).getTime()) / (1000 * 3600 * 24))} ${Math.round((new Date().getTime() - new Date(player.lastSuccessfulRequest).getTime()) / (1000 * 3600 * 24)) > 1 ? this.localizationService.strings.PlayAdmin_DaysAgo : this.localizationService.strings.PlayAdmin_DayAgo}` : `${new DateTimeService().ConvertToHHMM(player.lastSuccessfulRequest)}`,
          backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
          color: this.props.store.darkMode ? "#ffffff" : "#333333",
        },
        {
          header: this.localizationService.strings.PlayAdmin_ModifiedBy?.toUpperCase(),
          type: "text",
          text: player.modifiedBy?.displayName,
          backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
          color: this.props.store.darkMode ? "#ffffff" : "#333333",
        }
      ],
      buttons: [
        {
          action: "delete",
          icon: "Trash",
          color: this.props.store.highlightColor
        }
      ]
    })
  }

  private getProfiles(): void {
    const profileList = [];
    let numberOfPlayersOnTenant = 0;
    this.setState({ loading: true });
    PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then(async (profiles: IPlayProfile[] | 401) => {
      if (profiles === 401) {
        this.props.store.showErrorPopup = true;
      } else {
        profiles = Helper.sortAlphabeticalByTitle(profiles);
        if (profiles.length > 1) {
          profiles.forEach(async (profile: IPlayProfile) => {
            const profileUsers: IListElement[] = [];
            if (profile.title) {
              await PlayService.getPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id).then((players) => {
                if (players && players.count > 0) {
                  numberOfPlayersOnTenant = numberOfPlayersOnTenant + players.count;
                  players.value.forEach((player: IPlayer) => {
                    profileUsers.push(this.getListElement(profile, player));
                  });
                }
              });
              profileList.push(
                <Group
                  key={profile.id}
                  label={profile.title}
                  count={profileUsers?.length}
                  darkMode={this.props.store.darkMode}
                >
                  <IAListView
                    id="ListViewOfLicenceProfileUsers"
                    listElements={profileUsers}
                    backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    defaultAction={undefined}
                    darkMode={this.props.store.darkMode}
                    selectedAction={(action: string, id: string, profile: any) => {
                      switch (action) {
                        case "delete":
                          this.setState({ playerToDelete: id, selectedProfileId: profile?.id });
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                </Group>
              );
              this.props.store.numberOfPlayersOnTenant = numberOfPlayersOnTenant;
              this.setState({ profileList, loading: false }, () => this.forceUpdate());
            }
          });
        } else {
          const profileUsers: IListElement[] = [];
          await PlayService.getPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profiles[0].id).then((players) => {
            if (players && players.count > 0) {
              numberOfPlayersOnTenant = numberOfPlayersOnTenant + players.count;
              players.value.forEach((player: IPlayer) => {
                profileUsers.push(this.getListElement(profiles[0], player));
              });
            }
          });
          profileList.push(
            <IAListView
              id="ListViewOfLicenceProfileUsers2"
              listElements={profileUsers}
              backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              defaultAction={undefined}
              darkMode={this.props.store.darkMode}
              selectedAction={(action: string, id: string, profile: any) => {
                switch (action) {
                  case "delete":
                    this.setState({ playerToDelete: id, selectedProfileId: profile?.id });
                    break;
                  default:
                    break;
                }
              }}
            />
          );
          this.props.store.numberOfPlayersOnTenant = numberOfPlayersOnTenant;
          this.setState({ profileList, loading: false }, () => this.forceUpdate());
        }
      }
    });
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deletePlayer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.selectedProfileId, id).then(() => {
      this.props.store.playerToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedProfileId: undefined, playerToDelete: undefined });
      this.getProfiles();
    });
  }

  private changeSubscription(): void {
    this.setState({ saving: true });
    const numberOfBilledPlayers = this.state.wantedNumberOfPlayers - 1;
    if (this.props.store.subscription?.subscriptionId) {
      PlayService.updateSubscription(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.subscription.subscriptionId, numberOfBilledPlayers).then(() => {
        this.props.store.licensedNumberOfPlayers = this.state.wantedNumberOfPlayers;
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false, saving: false, });
        }, 1000);
      });
    } else {
      PlayService.createSubscription(this.props.store.environment, this.props.store.token, this.props.store.tenantId, numberOfBilledPlayers).then(() => {
        this.props.store.licensedNumberOfPlayers = this.state.wantedNumberOfPlayers;
        this.setState({ saved: true });
        this.getLicense(true);
        setTimeout(() => {
          this.setState({ saved: false, saving: false, });
        }, 1000);
      }).catch(() => {
        this.setState({ saved: true });
        setTimeout(() => {
          this.setState({ saved: false, saving: false, });
        }, 1000);
      });
    }
  }

  public render(): JSX.Element {
    const pivots = [
      {
        key: "license",
        text: this.localizationService.strings.PlayAdmin_License
      },
      {
        key: "companyInformation",
        text: this.localizationService.strings.PlayAdmin_CompanyInformation
      }
    ]
    return this.props.display ? (
      <>
        {this.state.playerToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeletePlayer}
            close={() => this.setState({ playerToDelete: undefined, selectedProfileId: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.playerToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ playerToDelete: undefined, selectedProfileId: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.showContactInformationPopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_CompanyInformationPopup}
            close={() => this.setState({ showContactInformationPopup: false })}
            darkMode={this.props.store.darkMode}
            style={{
              height: "80vh",
              top: "10vh"
            }}
            content={
              <CompanyInformationForm
                showButtonAtTheBottom
                onSaved={() => {
                  this.setState({ showContactInformationPopup: false });
                  this.changeSubscription();
                }}
              />
            }
          />
        }
        <IAHeader
          id="play-license-header"
          title={this.localizationService.strings.PlayAdmin_License}
          description={this.localizationService.strings.PlayAdmin_LicenseDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#666666"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-black.png",
              headline: this.localizationService.strings.PlayAdmin_License,
              text: this.localizationService.strings.PlayAdmin_LicenseDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-licens.png"
            }
          ]}
        />
        <IAPivot
          highlightColor={this.props.store.highlightColor}
          items={pivots}
          style={{
            fontSize: "14px",
            color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
            textTransform: "uppercase"
          }}
          selectedKey={this.state.selectedPivot}
          select={(selectedPivot: "profile" | "groups" | "permissions") => {
            this.setState({ selectedPivot });
          }}
        />
        <div
          className={styles.IA_pivotContent}
        >
          <CompanyInformation
            display={this.state.selectedPivot === "companyInformation"}
          />
          {this.state.selectedPivot === "license" &&
            <>
              <div className={styles.IA_licenseBox}>
                <h1 style={{ width: "calc(100% - 200px)" }}>{this.localizationService.strings.PlayAdmin_HowManyPlayers}</h1>
                {this.state.saving ?
                  <div
                    style={{
                      float: "right",
                      position: "relative",
                      marginTop: 20
                    }}
                  >
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
                      <polyline
                        className={"IA_check"}
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="10"
                        strokeMiterlimit="20"
                        points="15,60 40,80 85,20"
                        style={{
                          animation: this.state.saved ? "dash 500ms forwards" : "none"
                        }}
                      />
                    </svg>
                  </div>
                  :
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_Update?.toUpperCase()}
                    buttonColor={"#ffffff"}
                    disbaled={this.props.store.licensedNumberOfPlayers === this.state.wantedNumberOfPlayers || this.state.wantedNumberOfPlayers < this.props.store.numberOfPlayersOnTenant}
                    textColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    onClick={() => {
                      if (this.props.store.licensedNumberOfPlayers !== this.state.wantedNumberOfPlayers) {
                        if (this.props.store.companyInformation) {
                          this.changeSubscription();
                        } else {
                          this.setState({ showContactInformationPopup: true });
                        }
                      }
                    }}
                    borderRadius={5}
                    showSpinner={this.state.loading}
                    style={{
                      float: "right",
                      position: "relative",
                      marginTop: 20
                    }}
                  />
                }
                <div className={styles.IA_licenseLabel}>
                  {this.state.loadingLicence ?
                    <IASpinner
                      color={"#ffffff"}
                      style={{
                        float: "left",
                        clear: "both",
                        margin: "43px auto",
                        marginLeft: "calc(50% - 20px)",
                      }}
                    /> :
                    this.state.wantedNumberOfPlayers
                  }
                </div>
                <input
                  className={styles.IA_licenseSlider}
                  type="range"
                  min="1"
                  max="200"
                  value={this.state.wantedNumberOfPlayers}
                  onChange={(event) => {
                    this.setState({ wantedNumberOfPlayers: parseInt(event.target.value) });
                  }}
                  style={{
                    width: "100%",
                  }}
                />
                {this.state.wantedNumberOfPlayers === 1 ?
                  <div className={styles.IA_licensePrize}>
                    {this.localizationService.strings.PlayAdmin_Free}
                  </div>
                  :
                  (this.state.wantedNumberOfPlayers < this.props.store.numberOfPlayersOnTenant ?
                    <div className={styles.IA_licensePrize}>
                      {this.localizationService.strings.PlayAdmin_LicenseCanNotBeSmallerThanNumberOfPlayers}
                    </div>
                    :
                    (this.props.store.hasDiscount ?
                      <div className={styles.IA_licensePrize}>
                        {`${this.state.wantedNumberOfPlayers} x 0 ${this.localizationService.strings.PlayAdmin_DkkPerMonth} = ${this.state.wantedNumberOfPlayers * 0} ${this.localizationService.strings.PlayAdmin_DkkPerMonth}`}
                      </div>
                      :
                      <div className={styles.IA_licensePrize}>
                        {`${this.state.wantedNumberOfPlayers - 1} x 99 ${this.localizationService.strings.PlayAdmin_DkkPerMonth} = ${(this.state.wantedNumberOfPlayers - 1) * 99} ${this.localizationService.strings.PlayAdmin_DkkPerMonth}`}
                      </div>
                    )
                  )
                }
              </div>
              <div style={{
                marginTop: 30,
                float: "left",
                width: "100%"
              }}>
                <h1>{this.props.store.numberOfPlayersOnTenant ? `${this.localizationService.strings.PlayAdmin_ProfilesAndPlayers} (${this.props.store.numberOfPlayersOnTenant})` : this.localizationService.strings.PlayAdmin_ProfilesAndPlayers}</h1>
                <div className={styles.IA_license}>
                  {this.state.loading ?
                    <IASpinner
                      color={this.props.store.highlightColor}
                      style={{
                        float: "left",
                        marginLeft: "calc(50% - 20px)",
                        clear: "both"
                      }}
                    />
                    :
                    this.state.profileList
                  }
                </div>
              </div>
            </>
          }
        </div>
      </>
    )
      :
      (<></>);
  }


}