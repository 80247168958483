exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weather_2OaTtGgKz59QnVIDWUdgOR {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n}\n\n.IA_weatherIcon_1PkmynRW4_aHrOv3406N_l {\n  height: 65%;\n  width: fit-content;\n}\n\n.IA_weatherTemperature_2CZGVwteuHK1TOn48vyTGH {\n  position: relative;\n  text-align: left;\n\n}\n\n.IA_weatherArea_1Eo5FcEb480IDUEcvndHYu {\n  position: relative;\n  text-align: left;\n  font-weight: lighter;\n  clear: right;\n}", ""]);

// Exports
exports.locals = {
	"IA_weather": "IA_weather_2OaTtGgKz59QnVIDWUdgOR",
	"IA_weatherIcon": "IA_weatherIcon_1PkmynRW4_aHrOv3406N_l",
	"IA_weatherTemperature": "IA_weatherTemperature_2CZGVwteuHK1TOn48vyTGH",
	"IA_weatherArea": "IA_weatherArea_1Eo5FcEb480IDUEcvndHYu"
};