exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_1L6vwAGBGMJoWdXvyw79tj {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .likes_1krOBqXuo2cpI8oNj1SQM1 {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .spinner_2FBYCPbQM29y4dKgksiyjG {\n  height: 18px;\n  width: 18px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_2Wr1boiRJ6F33ThDYoCdAO 700ms linear infinite;\n  -moz-animation: spin_2Wr1boiRJ6F33ThDYoCdAO 700ms linear infinite;\n  animation: spin_2Wr1boiRJ6F33ThDYoCdAO 700ms linear infinite;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .comments_4kA0-BfUJsyCPGR_gWsP8 {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .likesText_2J6pGBOWbAc34ACyjw7bgP {\n  float: left;\n  margin-left: 5px;\n  margin-top: -1px;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .likesTextVertical_1bJAdVv84SukY9kyM0QalK {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_1L6vwAGBGMJoWdXvyw79tj .commentText_1pjYNNvwZZhIk3uRyFv-C6 {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_2Wr1boiRJ6F33ThDYoCdAO {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2Wr1boiRJ6F33ThDYoCdAO {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2Wr1boiRJ6F33ThDYoCdAO {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_1L6vwAGBGMJoWdXvyw79tj",
	"likes": "likes_1krOBqXuo2cpI8oNj1SQM1",
	"spinner": "spinner_2FBYCPbQM29y4dKgksiyjG",
	"spin": "spin_2Wr1boiRJ6F33ThDYoCdAO",
	"comments": "comments_4kA0-BfUJsyCPGR_gWsP8",
	"likesText": "likesText_2J6pGBOWbAc34ACyjw7bgP",
	"likesTextVertical": "likesTextVertical_1bJAdVv84SukY9kyM0QalK",
	"commentText": "commentText_1pjYNNvwZZhIk3uRyFv-C6"
};