import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';

import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IOption } from '../../../../../../interfaces/IOption';
import { IPowerBIPage, IPowerBIReport } from '../../../../../../interfaces/IPlay';
import { IANumberField } from '../../../../../numberField/NumberField';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
  reportOptions?: IOption[];
  reports?: IPowerBIReport[];
  pages?:IPowerBIPage[];
  colorThemeForPreview?: any;
  errorOccurred?: boolean;
}

@inject('store')
@observer
export class PowerBIWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      reportOptions: [{key: "", value: "Select Report"}],
      reports: [],
      errorOccurred: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.getPowreBIWorkspaces();
  }

  private getPowreBIWorkspaces(): void{
    try{
      PlayService.getPowerBiIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then((workspaces)=>{
        if(workspaces == undefined){
          this.setState({errorOccurred: true});
        }else{
          workspaces.forEach((workspace)=>{
            this.getPowerBIReports(workspace.id);
          })
        } 
      });
    }catch(error){
      console.error(error);
      this.setState({errorOccurred: true});
    }
    
   
  }
  
  private getPowerBIReports(workspaceId: string): void{
    try{
      PlayService.getPowerBIReports(this.props.store.environment, this.props.store.token, this.props.store.tenantId, workspaceId).then((reports) => {
        if(reports == undefined){
          this.setState({errorOccurred: true});
        }else{
          var options = this.state.reportOptions;   
          let updatedReports = this.state.reports;
          reports.forEach(
            (report) => {
              options.push({key: report.id, value: report.name});
              report.workspaceId = workspaceId;
              updatedReports.push(report);
            });
         
          this.setState({reports: updatedReports});
          this.setState({reportOptions: options});
          
          if(this.props.store.widgetToEdit.powerbi.reportId){
            PlayService.getPowerBIPages(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.reports.find(x => x.id == this.props.store.widgetToEdit.powerbi.reportId).workspaceId, this.props.store.widgetToEdit.powerbi.reportId).then((pages) => {
              if(pages == undefined){

              }else{
                this.setState({pages: pages});
              }
              this.props.forceUpdate();
              setTimeout(() => {
                this.props.forceUpdate();
              }, 500);
            });
          }else{
            this.props.forceUpdate();
            setTimeout(() => {
              this.props.forceUpdate();
            }, 500);
          }
        }
        
      });
    }catch(error){
      console.error(error);
      this.setState({errorOccurred: true});
    }
   
  }

  private fetchContent(): void {
    this.props.loadingContent(true);
    try{
      PlayService.getPowerBIAcessToken(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.widgetToEdit.powerbi.datasetId, this.props.store.widgetToEdit.powerbi.reportId).then((tokenResponse) => {
        if(tokenResponse == undefined){
          this.setState({errorOccurred: true});
        }else{
          this.props.store.widgetToEdit.powerbi.accessToken = tokenResponse.token;
          this.props.loadingContent(false);
          this.props.forceUpdate();
          setTimeout(() => {
            this.props.forceUpdate();
          }, 500);
        }
       
      });
    }catch(error){
      this.setState({errorOccurred: true});
    }finally{
      this.props.loadingContent(false);
    }
  
  }

  private updateReportId(reportId: string): void{
    this.props.store.widgetToEdit.powerbi.reportId = reportId;
    this.props.store.widgetToEdit.powerbi.reportEmbedUrl = this.state.reports.find(x => x.id === reportId)?.embedUrl;
    this.props.store.widgetToEdit.powerbi.datasetId = this.state.reports.find(x => x.id === reportId)?.datasetId;
    PlayService.getPowerBIPages(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.reports.find(x => x.id == reportId).workspaceId, this.props.store.widgetToEdit.powerbi.reportId).then((pages) => {
      if(pages == undefined){
        this.setState({errorOccurred: true});
      }else{
        this.setState({pages: pages});
        this.props.forceUpdate();
        this.fetchContent();
      }
    });
    
  }

  private updatePage(pageName: string): void{
    this.props.store.widgetToEdit.powerbi.pageName = pageName;
    this.props.forceUpdate();
  }

  render(): React.ReactNode {

    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />

          <IADropDown
            label={this.localizationService.strings.PlayAdmin_PowerBI_Report}
            selectedOption={this.props.store.widgetToEdit.powerbi.reportId}
            options={this.state.reportOptions}
            onChange={(selectedReport: string) => {
              this.updateReportId(selectedReport);
            }}
            style={{
              float: "left",
              width: "100%"
            }}
            borderRadius={5}
          />
          {
            this.state.pages && <IADropDown
                                  label={this.localizationService.strings.PlayAdmin_PowerBI_Page}
                                  selectedOption={this.props.store.widgetToEdit.powerbi.pageName}
                                  options={this.state.pages.map((page)=>{return {key: page.name, value: page.displayName}})}
                                  onChange={(selectedPage: string) => {
                                    this.updatePage(selectedPage);
                                  }}
                                  style={{
                                    float: "left",
                                    width: "100%"
                                  }}
                                  borderRadius={5}
                                />
          }
{this.state.errorOccurred && 
  <div style={{ clear: "both", float: "left", marginTop: 15, fontStyle: "italic", width: "100%", color: "red" }}>{this.localizationService.strings.PlayAdmin_PowerBI_ValidationError} <br></br>{this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message1} <a href="https://docs.intraactive.net/intraactive-replay/integrations/#section-header-two-2ve33" target='_blank'>{this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message2}</a> {this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message3}</div>}
        <IANumberField
            label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.duration = duration;
              this.props.forceUpdate();
            }}
            min={0}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            styles={{
              width: "100%",
              clear: "both",
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
          />

        </IAGroup>
       
      </>
    );
  }


}
