exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenu_jJ8-JBQzL_jZIjNcIjp7B {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.IA_canteenMenuOverlay_254VGVtmaPJ65oHamIq2zu {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.IA_headline_243SZqakIimEdKEJzNNHX2 {\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n.IA_column_2zz5gyd6uYeuWLXzbBjBc8 {\n  float: left;\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenu": "IA_canteenMenu_jJ8-JBQzL_jZIjNcIjp7B",
	"IA_canteenMenuOverlay": "IA_canteenMenuOverlay_254VGVtmaPJ65oHamIq2zu",
	"IA_headline": "IA_headline_243SZqakIimEdKEJzNNHX2",
	"IA_column": "IA_column_2zz5gyd6uYeuWLXzbBjBc8"
};