exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n.IA_Checkbox_1namf53CKm8wfyXqX7dZhp {\n  display: -webkit-inline-box;\n}\n\n.IA_Checkbox_1namf53CKm8wfyXqX7dZhp input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n  background-color: #ffffff !important;\n}\n\n.IA_CheckboxInput_2cyDxSgS6YUVVYBLgz4Pnz{\n  margin-right: 7px;\n}\n\n.container_279lZYq4nJw0FSZTnPc6qd {\n  display: block;\n  position: relative;\n  padding-left: 25px;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.checkmark_1ggbbwp_PIz1bCxKOJOFAn {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 18px;\n  width: 18px;\n  border-radius: 2px;\n  background-color: #cccccc;\n}\n\n.IA_dot_2XBQIgP-3NqUkrUG_dn0ID {\n  border-radius: 2px;\n  width: 9px;\n  height: 9px;\n  margin: 5px;\n  box-sizing: border-box;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_Checkbox": "IA_Checkbox_1namf53CKm8wfyXqX7dZhp",
	"IA_CheckboxInput": "IA_CheckboxInput_2cyDxSgS6YUVVYBLgz4Pnz",
	"container": "container_279lZYq4nJw0FSZTnPc6qd",
	"checkmark": "checkmark_1ggbbwp_PIz1bCxKOJOFAn",
	"IA_dot": "IA_dot_2XBQIgP-3NqUkrUG_dn0ID"
};