exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_SkeletonLight_2WN4UHi7fkgBActNXJWLiS {\n  background: linear-gradient(to right, #e1e1e1 8%, #e9e9e9 38%, #e1e1e1 54%);\n}\n\n.IA_SkeletonDark_pMUNoHe4JIimes91jQnb- {\n  background: linear-gradient(to right, #2d2d2d 8%, #393939 38%, #2d2d2d 54%);\n}\n\n.IA_Skeleton_BG2v6aHhje7kIXE-1MUyi {\n  background-color: rgba(0, 0, 0, .15);\n  animation-duration: 1.5s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n  background-size: 500px 500px;\n  animation-name: skeletonAnimation_2DIHOWGam02esb3QBHnPHD;\n}\n\n@keyframes skeletonAnimation_2DIHOWGam02esb3QBHnPHD {\n  0% {\n    background-position: -500px 0;\n  }\n\n  100% {\n    background-position: 500px 0;\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_SkeletonLight": "IA_SkeletonLight_2WN4UHi7fkgBActNXJWLiS",
	"IA_SkeletonDark": "IA_SkeletonDark_pMUNoHe4JIimes91jQnb-",
	"IA_Skeleton": "IA_Skeleton_BG2v6aHhje7kIXE-1MUyi",
	"skeletonAnimation": "skeletonAnimation_2DIHOWGam02esb3QBHnPHD"
};