import { LocalizationService } from "./LocalizationService";

export default class DateTimeService {

  public ConvertToDDMMYYYY(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)} ${date.getFullYear()}`;
  }

  public ConvertToDDMMYYYYWithSlashes(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}/${date.getFullYear()}`;
  }

  public ConvertToYYYYMMDD(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}-${this.PadWithZero(date.getMonth() + 1)}-${this.PadWithZero(date.getDate())}`;
  }

  public ConvertToLocalIOSString(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}-${this.PadWithZero(date.getMonth() + 1)}-${this.PadWithZero(date.getDate())}T${this.PadWithZero(date.getHours())}:${this.PadWithZero(date.getMinutes())}`;
  }

  public ConvertToDDMM(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}`;
  }

  public ConvertToHHMM(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}.${this.PadWithZero(date.getMinutes())}`;
  }

  public ConvertToHHMMWithDoubleDots(date: Date | string): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}:${this.PadWithZero(date.getMinutes())}`;
  }


  public ConvertToDDMMYYYYHHMM(date: Date | string, doubleDots: boolean = false): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (doubleDots) {
      return `${this.ConvertToDDMMYYYY(date)} ${this.ConvertToHHMMWithDoubleDots(date)}`;
    }
    return `${this.ConvertToDDMMYYYY(date)} ${this.ConvertToHHMM(date)}`;
  }

  public parseDate(dateString: string): Date {
    return new Date(dateString);
  }

  private PadWithZero(num: number): string {
    if (num < 10) {
      return "0" + num;
    }
    return num.toString();
  }

  public getWeekday(date: Date, forceLanguage?: string): string {
    const localizationService = new LocalizationService(forceLanguage);
    switch (date.getDay()) {
      case 0:
        return localizationService.strings.DateTimeService_Sunday;
      case 1:
        return localizationService.strings.DateTimeService_Monday;
      case 2:
        return localizationService.strings.DateTimeService_Tuesday;
      case 3:
        return localizationService.strings.DateTimeService_Wednesday;
      case 4:
        return localizationService.strings.DateTimeService_Thursday;
      case 5:
        return localizationService.strings.DateTimeService_Friday;
      case 6:
        return localizationService.strings.DateTimeService_Saturday;
    }
  }

  public getDayInMonth(date: Date | string, allDayEvent?: boolean): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (allDayEvent) {
      return `${this.PadWithZero(parseInt(date.toISOString().substring(8, 10), 0))}`;
    }
    return this.PadWithZero(date.getDate());
  }

  public getNameOfMonth(date: Date | string, allDayEvent?: boolean, forceLanguage?: string): string {
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    if (allDayEvent) {
      // fix for date shown as the next day for all-day-events when user is from a +X timezone
      date = this.fixDateWithTimeZone(date);
    }
    const localizationService = new LocalizationService(forceLanguage);
    if (date != undefined && localizationService?.strings?.DateTimeService_January) {
      switch (date?.getMonth()) {
        case 0:
          return localizationService.strings.DateTimeService_January;
        case 1:
          return localizationService.strings.DateTimeService_February;
        case 2:
          return localizationService.strings.DateTimeService_March;
        case 3:
          return localizationService.strings.DateTimeService_April;
        case 4:
          return localizationService.strings.DateTimeService_May;
        case 5:
          return localizationService.strings.DateTimeService_June;
        case 6:
          return localizationService.strings.DateTimeService_July;
        case 7:
          return localizationService.strings.DateTimeService_August;
        case 8:
          return localizationService.strings.DateTimeService_September;
        case 9:
          return localizationService.strings.DateTimeService_October;
        case 10:
          return localizationService.strings.DateTimeService_November;
        case 11:
          return localizationService.strings.DateTimeService_December;
      }
    } else {
      return "";
    }
  }

  public isStartAndEndDateTheSameDay(startDate: Date | string, endDate: Date | string, allDayEvent?: boolean): boolean {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }
    if (typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }

    if (allDayEvent) {
      // fix for date shown as the next day for all-day-events when user is from a +X timezone
      startDate = this.fixDateWithTimeZone(startDate);
      endDate = this.fixDateWithTimeZone(endDate);
    }

    return startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear();
  }

  public getEventStringDateTimeFormat(startDate: Date, endDate?: Date, allDayEvent?: boolean): string {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }

    if (endDate !== undefined && typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }

    if (allDayEvent) {
      // fix for date shown as the next day for all-day-events when user is from a +X timezone
      startDate = this.fixDateWithTimeZone(startDate);
      if (endDate) {
        endDate = this.fixDateWithTimeZone(endDate);
      }
    }

    let showEndDate = false;
    const language = navigator.language;
    const startDateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      hour12: false
    };
    const endDateOptions: Intl.DateTimeFormatOptions = {
      hour12: false
    };

    if (this.isStartAndEndDateTheSameDay(startDate, endDate, allDayEvent)) {
      if (!allDayEvent) {
        startDateOptions.hour = "numeric";
        startDateOptions.minute = "numeric";
        endDateOptions.hour = "numeric";
        endDateOptions.minute = "numeric";
        showEndDate = true;
      }
    } else {
      endDateOptions.month = "short";
      endDateOptions.day = "numeric";
      showEndDate = true;
    }

    let startStringParts = new Intl.DateTimeFormat(language, startDateOptions).formatToParts(startDate);

    // if today change name of month to localized "Today" and remove day and any literals between month and day
    const now = new Date();
    if (startDate.getDate() === now.getDate() && startDate.getMonth() === now.getMonth() && startDate.getFullYear() === now.getFullYear()) {
      startStringParts = this.replaceDateWithTodayString(startStringParts);
    }

    const endString = new Intl.DateTimeFormat(language, endDateOptions).format(endDate);

    return startStringParts.map(s => s.value).join("") + (showEndDate ? " - " + endString : "");
  }

  private replaceDateWithTodayString(parts: Intl.DateTimeFormatPart[]): Intl.DateTimeFormatPart[] {
    const monthIndex = parts.findIndex(s => s.type === "month");
    const dayIndex = parts.findIndex(s => s.type === "day");

    if (parts[monthIndex]) {
      parts[monthIndex].value = new LocalizationService().strings.DateTimeService_Today;
    }
    if (parts[dayIndex]) {
      parts[dayIndex].value = "";
    }

    // remove literal between day and month
    if (monthIndex < dayIndex && parts[monthIndex + 1]?.type === "literal") {
      parts[monthIndex + 1].value = "";
    }
    if (dayIndex < monthIndex && parts[dayIndex + 1]?.type === "literal") {
      parts[dayIndex + 1].value = "";
    }

    return parts;
  }

  public getEventString(startDate: Date, endDate?: Date, allDayEvent?: boolean, isStringOnRollup?: boolean): string {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }
    if (endDate !== undefined && typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }
    if (allDayEvent) {
      // fix for date shown as the next day for all-day-events when user is from a +X timezone
      startDate = this.fixDateWithTimeZone(startDate);
      if (endDate) {
        endDate = this.fixDateWithTimeZone(endDate);
      }
    }
    let endDateString = "";
    let startDateString = "";
    if (isStringOnRollup) {
      let allDayString = allDayEvent ? new LocalizationService().strings.DateTimeService_AllDay : "";
      startDateString = allDayEvent ? allDayString : this.getHoursLocalized(startDate);
      endDateString = allDayEvent ? allDayString : this.getHoursLocalized(endDate);
    }
    else {
      startDateString = allDayEvent ? this.getShortDateLocalized(startDate) : this.getFullDateWithoutYearsLocalized(startDate);
      endDateString = allDayEvent ? this.getShortDateLocalized(endDate) : this.getFullDateWithoutYearsLocalized(endDate);
    }
    const isOneDayEvent = startDateString === endDateString;
    let eventString = startDateString;
    if (isOneDayEvent) {
      if (allDayEvent) {
        endDateString = undefined;
      } else {
        endDateString = isStringOnRollup ? this.getHoursLocalized(endDate) : this.getShortDateLocalized(endDate);
      }
    }
    if (endDateString !== undefined) {
      eventString += ` - ${endDateString}`;
    }
    return eventString;
  }

  public getEventDateString(startDate: Date, endDate?: any, isAllDayEvent?: boolean): string {
    if (typeof (startDate) === "string") {
      startDate = this.parseDate(startDate);
    }
    if (typeof (endDate) === "string") {
      endDate = this.parseDate(endDate);
    }

    if (isAllDayEvent) {
      // fix for date shown as the next day for all-day-events when user is from a +X timezone
      startDate = this.fixDateWithTimeZone(startDate);
      if (endDate) {
        endDate = this.fixDateWithTimeZone(endDate);
      }
    }

    const language = navigator.language;
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      hour12: false
    };

    let startDateParts = new Intl.DateTimeFormat(language, dateOptions).formatToParts(startDate);
    if (startDate.getDate() === (new Date()).getDate()) {
      startDateParts = this.replaceDateWithTodayString(startDateParts);
    }

    const startString = startDateParts.map(s => s.value).join("");
    if (this.isStartAndEndDateTheSameDay(startDate, endDate, isAllDayEvent)) {
      return startString;
    }

    const endString = new Intl.DateTimeFormat(language, dateOptions).format(endDate);
    return startString + " - " + endString;
  }

  public getEventTimeString(startDate: Date, endDate?: Date): string {
    if (endDate) {
      return `${this.getHoursLocalized(startDate)} - ${this.getHoursLocalized(endDate)}`;
    } else {
      return this.getHoursLocalized(startDate);
    }
  }

  public getUserfriendlyDate(date: Date): string {
    const localizationService = new LocalizationService();
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    let startDateString = `${this.PadWithZero(date.getDate())}/${this.PadWithZero(date.getMonth() + 1)}`;
    const today = new Date();
    const tomorrow = new Date(today.getDate() + 1);
    const yesterday = new Date(today.getDate() - 1);
    const todayPlusSevenDays = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
    if (date > today && date < todayPlusSevenDays) {
      startDateString = this.getWeekday(date);
    }
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
      startDateString = localizationService.strings.DateTimeService_Today;
    }
    if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === tomorrow.getFullYear()) {
      startDateString = localizationService.strings.DateTimeService_Tomorrow;
    }
    if (date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear()) {
      startDateString = localizationService.strings.DateTimeService_Yesterday;
    }
    return startDateString;
  }

  public getTodayAtMidnight(): Date {
    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.setHours(0, 0, 0, 0));
    return defaultStartDate;
  }

  public getTomorrowAtMidnight(): Date {
    let defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 1);
    defaultEndDate = new Date(defaultEndDate.setHours(0, 0, 0, 0));
    return defaultEndDate;
  }

  public getDatePlusOneHour(date: Date): Date {
    return new Date(date.setTime(date.getTime() + (1 * 60 * 60 * 1000)));
  }

  public getDatePlusTwelveHours(date: Date): Date {
    return new Date(date.setTime(date.getTime() + (12 * 60 * 60 * 1000)));
  }

  public getDatePlusTwoMinuts(date: Date): Date {
    return new Date(date.setTime(date.getTime() + (2 * 60 * 1000)));
  }

  public getDateMinusTwoMinuts(date: Date): Date {
    return new Date(date.setTime(date.getTime() - (2 * 60 * 1000)));
  }

  public getDateMinusTenMinuts(date: Date): Date {
    return new Date(date.setTime(date.getTime() - (10 * 60 * 1000)));
  }


  public getDateAtMidnight(date: Date): Date {
    let defaultStartDate = date;
    defaultStartDate = new Date(defaultStartDate.setHours(0, 0, 0, 0));
    return defaultStartDate;
  }

  public getDateJustBeforeMidnight(date: Date): Date {
    let defaultStartDate = date;
    defaultStartDate = new Date(defaultStartDate.setHours(23, 59, 59, 0));
    return defaultStartDate;
  }

  private convertToHHMM(date: Date | string, delimeter: ":" | "."): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getHours())}${delimeter}${this.PadWithZero(date.getMinutes())}`;
  }

  private convertToYYYYMMDD(date: Date | string, delimeter: "/" | "." | "-" = "-"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${date.getFullYear()}${delimeter}${this.PadWithZero(date.getMonth() + 1)}${delimeter}${this.PadWithZero(date.getDate())}`;
  }

  private convertToDDMMYYYY(date: Date | string, delimeter: "/" | "." | "-" = "-"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}${delimeter}${this.PadWithZero(date.getMonth() + 1)}${delimeter}${date.getFullYear()}`;
  }

  private convertToDDMM(date: Date | string, delimeter: "." | "/"): string {
    if (!date) {
      return undefined;
    }
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    return `${this.PadWithZero(date.getDate())}${delimeter}${this.PadWithZero(date.getMonth() + 1)}`;
  }

  // private convertToMMDD(date: Date | string, delimeter: "." | "/" | "-"): string {
  //   if (!date) {
  //     return undefined;
  //   }
  //   if (typeof (date) === "string") {
  //     date = this.parseDate(date);
  //   }
  //   return `${this.PadWithZero(date.getMonth() + 1)}${delimeter}${this.PadWithZero(date.getDate())}`;
  // }

  public getDateLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToYYYYMMDD(date, "-");
    } else if (language === "Danish") {
      return this.convertToDDMMYYYY(date, "-");
    } else if (language === "German") {
      return this.convertToDDMMYYYY(date, ".");
    } else {
      return this.convertToDDMMYYYY(date, "-");
    }
  }
  public getHoursLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToHHMM(date, ".");
    } else if (language === "Danish") {
      return this.convertToHHMM(date, ":");
    } else if (language === "German") {
      return this.convertToHHMM(date, ":");
    } else {
      return this.convertToHHMM(date, ".");
    }
  }

  public getShortDateLocalized(date: Date | string): string {
    const language = new LocalizationService().getLanguageFromNavigator();
    if (language === "Swedish") {
      return this.convertToDDMM(date, "/");
    } else if (language === "Danish") {
      return this.convertToDDMM(date, ".");
    } else if (language === "German") {
      return this.convertToDDMM(date, ".");
    } else {
      return this.convertToDDMM(date, "/");
    }
  }

  public getFullDateLocalized(date: Date | string): string {
    return `${this.getDateLocalized(date)} ${this.getHoursLocalized(date)}`;
  }
  public getFullDateWithoutYearsLocalized(date: Date | string): string {
    return `${this.getShortDateLocalized(date)} ${this.getHoursLocalized(date)}`;
  }

  public isOlderThanOneHour(date: Date | string): boolean {
    if (typeof (date) === "string") {
      date = this.parseDate(date);
    }
    const now = new Date();
    const oneHourAgo = new Date(now.setTime(now.getTime() - (1 * 60 * 60 * 1000)));
    return date < oneHourAgo;
  }

  private fixDateWithTimeZone(date: Date) : Date {
    const copy = new Date(date.getTime());
    copy.setMinutes(copy.getMinutes() + copy.getTimezoneOffset());
    return copy;
  }
}