import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './SettingsPanel.css';
import { IAPivot } from '../../../pivot/Pivot';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Groups } from './groups/Groups';
import { Permissions } from './permissions/Permissions';
import { Helper } from '../../../../Helper';
import { IPlayProfile } from '../../../../interfaces/IPlay';
import PlayService from '../../../../services/PlayService';
import { IAPanel } from '../../../panels';
import { Design } from './design/Design';
import { IAButton } from '../../../button/Button';
import { IATextField } from '../../../textField/TextField';
import { IAIcon } from '../../../icon/Icon';
import { MediaFileSelector } from '../mediaFiles/mediaFileSelector/MediaFileSelector';
import { Popup } from '../../reusableComponents/popup/Popup';
import { RssTickers } from './rssTickers/RssTickers';

export interface IProps {
  store?: Store;
}

export interface IState {
  selectedPivot: "design" | "groups" | "permissions" | "rssTickers";
  showProfileImagePopup: boolean;
}

@inject('store')
@observer
export class SettingsPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedPivot: "design",
      showProfileImagePopup: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.store.showSettingsPanel}
        darkMode={this.props.store.darkMode}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        // loading={this.state.isSaving}
        width={600}
        dataAutomationIdPrefix="settings-panel"
        panelId={`settings-panel}`}
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Update?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              this.props.store.workingOnIt = true;
              if (this.state.selectedPivot === "design" || this.state.selectedPivot === "rssTickers") {
                PlayService.updatePlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile).then(() => {
                  this.props.store.workingOnIt = false;
                  this.props.store.profileHasChanged = false;
                  location.reload();
                });
              }
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7
            }}
          />
        }
        close={() => {
          this.props.store.showSettingsPanel = false;
        }}
      >
        {this.props.store.showSettingsPanel &&
          <div className={styles.IA_settings}>
            <div
              className={styles.IA_profileIdentity}
              style={{
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"
              }}
            >
              <div
                className={styles.IA_profileImage}
                style={{
                  backgroundImage: `url("${this.props.store.profile?.logoUrl}")`
                }}
                onClick={() => {
                  this.setState({ showProfileImagePopup: true });
                }}
              >
                <div
                  className={styles.IA_profileImageEditButton}
                  style={{
                    backgroundColor: this.props.store.highlightColor
                  }}
                >
                  <IAIcon
                    url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit/SVG/ic_fluent_edit_48_regular.svg"
                    size={14}
                    color="#ffffff"
                    onClick={() => this.setState({ showProfileImagePopup: true })}
                  />
                </div>
              </div>
              <IATextField
                label={this.localizationService.strings.PlayAdmin_ProfileTitle}
                placeholder={this.localizationService.strings.PlayAdmin_ProfileTitlePlaceholder}
                text={this.props.store.profile?.title}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                style={{
                  width: "calc(100% - 100px)",
                  float: "left",
                  clear: "none",
                  marginLeft: 20
                }}
                onChange={(title: string) => {
                  this.props.store.profile.title = title;
                  this.props.store.profileHasChanged = true;
                  this.forceUpdate();
                }}
              />
            </div>
            <IAPivot
              highlightColor={this.props.store.highlightColor}
              darkmode={this.props.store.darkMode}
              items={[
                {
                  key: "design",
                  text: this.localizationService.strings.PlayAdmin_DesignSettings
                }, {
                  key: "rssTickers",
                  text: this.localizationService.strings.PlayAdmin_RSSTickers
                }, {
                  key: "groups",
                  text: this.localizationService.strings.PlayAdmin_Groups,
                }, {
                  key: "permissions",
                  text: this.localizationService.strings.PlayAdmin_Users,
                }
              ]}
              style={{
                fontSize: "14px",
                color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                textTransform: "uppercase",
                marginTop: 20,
                float: "left"
              }}
              selectedKey={this.state.selectedPivot}
              select={(selectedPivot: "design" | "groups" | "permissions" | "rssTickers") => {
                this.setState({ selectedPivot });
                if (this.props.store.profileHasChanged) {
                  this.props.store.profileHasChanged = false;
                  if (localStorage.getItem("IA_profileId")) {
                    PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, localStorage.getItem("IA_profileId")).then((profile: IPlayProfile) => {
                      if (profile) {
                        this.props.store.profile = profile;
                      }
                    });
                  }
                }
              }}
            />
            <div
              className={styles.IA_pivotContent}
              style={{
                height: this.state.selectedPivot === "design" ? "auto" : 0
              }}
            >
              <Design
                showSkeleton={false}
              />
            </div>
            <div
              className={styles.IA_pivotContent}
              style={{
                height: this.state.selectedPivot === "rssTickers" ? "auto" : 0
              }}
            >
              <RssTickers
                showSkeleton={false}
              />
            </div>
            <div
              className={styles.IA_pivotContent}
              style={{
                height: this.state.selectedPivot === "groups" ? "auto" : 0
              }}
            >
              <Groups
                showSkeleton={false}
              />
            </div>

            <div
              className={styles.IA_pivotContent}
              style={{
                height: this.state.selectedPivot === "permissions" ? "auto" : 0
              }}
            >
              <Permissions
                showSkeleton={false}
              />
            </div>
          </div>
        }
        {this.state.showProfileImagePopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_ProfileIcon}
            close={() => this.setState({ showProfileImagePopup: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <MediaFileSelector
                  url={this.props.store.profile?.logoUrl}
                  label={this.localizationService.strings.PlayAdmin_ProfileIcon?.toUpperCase()}
                  type={"image"}
                  onChange={(logoUrl: string) => {
                    this.props.store.profile.logoUrl = logoUrl;
                    this.props.store.profileHasChanged = true;
                    this.forceUpdate();
                  }}
                />
              </>
            }
          />
        }
      </IAPanel>
    );
  }


}