exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_cPn1bwJyBGr7mCnZWHSeY {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_9wtUZpMNzQsVjzA0Kqauy {\n  float: left;\n}\n\n.IA_teaser_3T0UNJIClFHY7GZF_ix2PJ {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_2k5emSWVBWLFCD9LXY4gYf {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_3E75iAnsPYUojL0UCnvgjQ {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_2iISDO9EUCLnKMgBa0ORnq {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_2-zPVSIXT8T7G5ASqF3bXv {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_9G5YCh5ISOXXB5R92-xYc {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_8pjIRd1uOJKFZ5WDLnBRo {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_9jQejHKj3i7wWfi4_kA-N {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_3UhZl0_g6WCqge5MBDr4M3 {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_cPn1bwJyBGr7mCnZWHSeY",
	"IA_title": "IA_title_9wtUZpMNzQsVjzA0Kqauy",
	"IA_teaser": "IA_teaser_3T0UNJIClFHY7GZF_ix2PJ",
	"IA_startDate": "IA_startDate_2k5emSWVBWLFCD9LXY4gYf",
	"IA_now": "IA_now_3E75iAnsPYUojL0UCnvgjQ",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_2iISDO9EUCLnKMgBa0ORnq",
	"IA_endDate": "IA_endDate_2-zPVSIXT8T7G5ASqF3bXv",
	"IA_coverOverlayToday": "IA_coverOverlayToday_9G5YCh5ISOXXB5R92-xYc",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_8pjIRd1uOJKFZ5WDLnBRo",
	"IA_coverOverlayDay": "IA_coverOverlayDay_9jQejHKj3i7wWfi4_kA-N",
	"IA_byline": "IA_byline_3UhZl0_g6WCqge5MBDr4M3"
};