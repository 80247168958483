exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_icJtzg6cwQQSay-GqKw7 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_1W7i_iZXp9fmgILNGXY6LC {\n  flex: 0.2;\n}\n\n.IA_iconColumn_wyyCBiF4CuqWFvNiXyPXv {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_dateColumn_25FZU8RnvU8yuZB6ipAstf {\n  flex: 0.5;\n}\n\n.IA_column_3OXcHN1KU5MYACxpQuY0ci {\n  flex: 1;\n}\n\n.IA_buttons_lsuWbzqH0duU3OcEoX97n {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_icJtzg6cwQQSay-GqKw7",
	"IA_previewColumn": "IA_previewColumn_1W7i_iZXp9fmgILNGXY6LC",
	"IA_iconColumn": "IA_iconColumn_wyyCBiF4CuqWFvNiXyPXv",
	"IA_dateColumn": "IA_dateColumn_25FZU8RnvU8yuZB6ipAstf",
	"IA_column": "IA_column_3OXcHN1KU5MYACxpQuY0ci",
	"IA_buttons": "IA_buttons_lsuWbzqH0duU3OcEoX97n"
};