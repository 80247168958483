import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Widgets.css';
import { IACard } from '../../../card/Card';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { WidgetEditPanel } from './widgetEditPanel/WidgetEditPanel';
import { IListElement, IListElementColumn, IPlayWidget, IPlayWidgetType } from '../../../../interfaces/IPlay';
import PlayService from '../../../../services/PlayService';
import { IAHeader } from '../../../header/Header';
import { IASpinner } from '../../../spinner/Spinner';
import { PlayHelper } from '../../PlayHelper';
import { IAIconDropDown } from '../../../iconDropDown/IconDropDown';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { Helper } from '../../../../Helper';
import { IAListView } from '../../../listView/ListView';
import { WidgetPopup } from '../widgetPopup/WidgetPopup';
import { IATextField } from '../../../textField/TextField';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  selectedWidgetId?: string;
  filterWidgetType?: IPlayWidgetType;
  filterSearchQuery?: string;
  isLoading: boolean;
  filterByWidgetType?: IIconDropDown;
  selectedUserSorting?: IIconDropDown;
  widgets: IPlayWidget[];
  widgetsCount: number;
  widgetToDelete: string;
  skip: number;
  take: number;
  showWidgetPopup: boolean;
  searchQuery: string;
}

@inject('store')
@observer
export class Widgets extends React.Component<IProps, IState> {

  private cardViewService: IACardViewService = new IACardViewService();
  private readonly localizationService: LocalizationService = new LocalizationService();
  private queryDelay: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      widgets: undefined,
      widgetsCount: undefined,
      widgetToDelete: undefined,
      skip: 0,
      take: PlayHelper.ELEMENTS_PER_PAGE,
      showWidgetPopup: false,
      searchQuery: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.props.store.widgets = undefined;
    this.getWidgets(true);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton && !this.props.store.widgets) {
      this.setState({ isLoading: true });
      this.getWidgets(true);
    }
  }

  private getWidgets(override?: boolean): void {
    if (this.props.store.profile?.id) {
      this.setState({ isLoading: true });
      PlayService.getWidgets(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.state.filterByWidgetType?.value as IPlayWidgetType, { skip: this.state.skip, take: this.state.take, search: this.state.searchQuery }).then(widgets => {
        const appendedWidgets = override ? [] : this.state.widgets ?? [];
        appendedWidgets.push(...widgets.value);
        if (!this.state.filterByWidgetType) {
          this.props.store.widgets = appendedWidgets;
          this.props.store.widgetsCount = widgets.count;
        }
        this.setState({ widgets: appendedWidgets, widgetsCount: widgets.count });
        this.setState({ isLoading: false });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deleteWidget(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.widgetToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedWidgetId: undefined, widgetToDelete: undefined, skip: 0 }, () => this.getWidgets(true));
    });
  }

  private onSave(): void {
    this.props.store.workingOnIt = true;
    const articleIds = this.props.store.widgetToEdit.articleViewer?.articles?.map(article => article.id)
    if (this.props.store.widgetToEdit.articleViewer?.omittedArticles?.length > 0) {
      this.props.store.widgetToEdit.articleViewer?.omittedArticles.forEach((id: string) => {
        articleIds.push(id);
      })
    }
    const widgetModel: IPlayWidget = {
      ...this.props.store.widgetToEdit,
      articleViewer: {
        ...this.props.store.widgetToEdit.articleViewer,
        articles: articleIds
      }
    }

    if (this.props.store.widgetToEdit.type === "relesys") {
      this.props.store.widgetToEdit.relesys.relesysContent = null;
    }

    if (this.props.store.widgetToEdit.id && this.props.store.widgetToEdit.id !== "new") {
      PlayService.updateWidget(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, widgetModel).then(() => {
        console.log(`Updated widget with id: ${this.props.store.widgetToEdit.id}`)
        this.props.store.workingOnIt = false;
        this.props.store.widgetToEdit = undefined;
        this.setState({ selectedWidgetId: undefined, skip: 0 }, () => this.getWidgets(true));
      })
    }
    else {
      PlayService.createWidget(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, widgetModel).then((id) => {
        console.log(`Created widget with id: ${id}`)
        this.props.store.workingOnIt = false;
        this.props.store.widgetToEdit = undefined;
        this.setState({ selectedWidgetId: undefined, skip: 0 }, () => this.getWidgets(true));
      })
    }
  }

  public render(): JSX.Element {
    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    // Resizing cards
    const cardMarginRight = 20;
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, cardMarginRight, 250, 5);
    let currentRowNumber = 1;
    if (this.state.widgets && this.state.widgets.length > 0) {
      this.state.widgets.forEach((widget: IPlayWidget) => {
        const columns: IListElementColumn[] = this.props.store.isMobile ?
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: widget.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
              type: "tag",
              src: `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(widget.type as IPlayWidgetType)}.png`,
              text: PlayHelper.getTitleForWidgetType(widget.type, this.localizationService),
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            }
          ]
          :
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: widget.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
              type: "tag",
              src: `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(widget.type as IPlayWidgetType)}.png`,
              text: PlayHelper.getTitleForWidgetType(widget.type, this.localizationService),
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
            },
            {
              header: this.localizationService.strings.PlayAdmin_Modified?.toUpperCase(),
              type: "date",
              date: widget.modified ?? widget.created,
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
            },
            {
              header: this.localizationService.strings.PlayAdmin_ModifiedBy?.toUpperCase(),
              type: "text",
              text: widget.modifiedBy?.displayName,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ];
        listElements.push(
          {
            id: widget.id,
            columns: columns,
            buttons: [
              // {
              //   action: "follow",
              //   icon: widget.isFavorite ? "Star Filled" : "Star",
              //   color: this.props.store.highlightColor
              // },
              {
                action: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              },
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              },
            ]
          }
        );
        let coverImage = `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(widget.type as IPlayWidgetType)}-placeholder.png`;
        if (widget.gallery?.images?.length > 0) {
          coverImage = PlayHelper.excludeImageFromApi(this.props.store.environment, widget.gallery.images[0])
            ? widget.gallery.images[0]
            : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, widget.gallery.images[0]);
        }
        if (widget.image?.imageUrl) {
          coverImage = PlayHelper.excludeImageFromApi(this.props.store.environment, widget.image.imageUrl)
            ? widget.image.imageUrl
            : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, widget.image.imageUrl);
        }
        const labels = [];
        labels.push(
          {
            text: PlayHelper.getTitleForWidgetType(widget.type, this.localizationService),
            image: `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(widget.type as IPlayWidgetType)}.png`,
            backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
            textColor: this.props.store.darkMode ? "#ffffff" : "#333333",
            borderRadius: 5
          }
        );
        if (widget.type === "gallery" && widget.gallery?.images) {
          labels.push(
            {
              text: `${widget.gallery.images.length} ${widget.gallery?.images.length === 1 ? this.localizationService.strings.PlayAdmin_Image : this.localizationService.strings.PlayAdmin_Images}`,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5
            }
          );
        }
        if (widget.type === "articleViewer" && widget.articleViewer?.articles) {
          labels.push(
            {
              text: `${widget.articleViewer?.articles.length} ${widget.articleViewer?.articles.length === 1 ? this.localizationService.strings.PlayAdmin_Article : this.localizationService.strings.PlayAdmin_Articles?.toLowerCase()}`,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5
            }
          );
        }
        cards.push(
          <IACard
            key={`player_${widget.id}`}
            id={widget.id}
            showSkeleton={false}
            title={widget.title}
            date={new Date(widget.modified)}
            author={widget.modifiedBy?.displayName}
            darkMode={this.props.store.darkMode}
            cover={{
              imageURL: coverImage
            }}
            width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
            isMobile={this.props.store.isMobile}
            styles={{
              marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : cardMarginRight,
              marginBottom: 20,
              width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
            design={{
              highlightColor: this.props.store.highlightColor,
              showDropShadow: true,
              borderRadius: 5,
              backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
              hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
            }}
            labels={labels}
            onClickOpen={() => {
              this.setState({ selectedWidgetId: widget.id });
            }}
            alwaysShowFooter
            footerElements={[
              {
                icon: "Edit",
                color: this.props.store.highlightColor,
                onClick: () => this.setState({ selectedWidgetId: widget.id })
              },
              {
                icon: "Trash",
                color: this.props.store.highlightColor,
                onClick: () => this.setState({ widgetToDelete: widget.id })
              },
              // {
              //   icon: widget.isFavorite ? "Star Filled" : "Star",
              //   color: this.props.store.highlightColor,
              //   onClick: () => alert("TODO")
              // }
            ]}
          />
        );

        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      })
    }
    return (
      <div
        className={styles.IA_widgets}
      >
        {this.state.widgets &&
          <WidgetEditPanel
            widgetId={this.state.selectedWidgetId}
            close={() => {
              this.props.store.widgetToEdit = undefined;
              this.setState({ selectedWidgetId: undefined });
            }}
            onSave={() => this.onSave()}
          />
        }
        <IAHeader
          id="play-widget-header"
          title={this.localizationService.strings.PlayAdmin_YourWidgets}
          description={this.localizationService.strings.PlayAdmin_YourWidgetsDesc}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.setState({ showWidgetPopup: true });
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourWidgets,
              text: this.localizationService.strings.PlayAdmin_YourWidgetsDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-widgets.png"
            }
          ]}
        />
        {!this.props.store.isMobile &&
          <div className={styles.IA_filters}>
            <IAIconDropDown
              options={PlayHelper.getAvailableWidgets(this.localizationService, this.props.store.environment, this.props.store.isConnectedToMessages(), this.props.store.isConnectedToEvents(), this.props.store.isConnectedToSharepointNews(), this.props.store.isConnectedFacebook(), this.props.store.isConnectedToPowerBi())}
              selectedOption={this.state.filterByWidgetType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              // label={this.localizationService.strings.PlayAdmin_Type}
              placeholder={this.localizationService.strings.PlayAdmin_FilterByWidgetType}
              clearable
              style={{
                minWidth: 220,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
              }}
              onChange={(filterByWidgetType: IIconDropDown) => this.setState({ filterByWidgetType, skip: 0 }, () => this.getWidgets(true))}
            />
            <IATextField
              style={{
                width: 330,
                marginRight: 20,
                marginTop: 0
              }}
              inputFieldStyle={{
                marginTop: 0,
                height: 38,
                borderColor: "#cccccc",
                fontSize: 15
              }}
              text={this.state.searchQuery}
              placeholder={this.localizationService.strings.PlayAdmin_SearchWidgets}
              borderRadius={5}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(search: string) => {
                this.setState({
                  skip: 0,
                  searchQuery: search
                })
                clearTimeout(this.queryDelay);
                this.queryDelay = setTimeout(() => {
                  this.setState({ widgets: undefined });
                  this.getWidgets(true);
                }, 500);
              }}
            />
            {/* <IAIconDropDown
              options={PlayHelper.getSortingOptions(this.localizationService)}
              selectedOption={localStorage.getItem("IA_widgetsSortingOption") ? JSON.parse(localStorage.getItem("IA_widgetsSortingOption")) : PlayHelper.getSortingOptions(this.localizationService)[0]}
              // label={this.localizationService.strings.PlayAdmin_Sorting}
              disabled
              darkMode={this.props.store.darkMode}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_widgetsSortingOption", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 320,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            />
            <IAIconDropDown
              options={PlayHelper.getUserSortingOptions(this.localizationService)}
              selectedOption={this.state.selectedUserSorting}
              // label={this.localizationService.strings.PlayAdmin_UserSorting}
              disabled
              darkMode={this.props.store.darkMode}
              placeholder={this.localizationService.strings.PlayAdmin_UserSortingPlaceholder}
              clearable
              onChange={(selectedUserSorting: IIconDropDown) => this.setState({ selectedUserSorting }, () => this.getWidgets(true))}
              style={{
                minWidth: 170,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            /> */}
            <IAIconDropDown
              options={PlayHelper.getViewTypes()}
              selectedOption={localStorage.getItem("IA_widgetsViewType") ? JSON.parse(localStorage.getItem("IA_widgetsViewType")) : PlayHelper.getViewTypes()[0]}
              // label={this.localizationService.strings.PlayAdmin_ViewType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_widgetsViewType", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 70,
                marginBottom: 20,
                marginLeft: "auto"
              }}
            />
          </div>
        }
        <>
          {localStorage.getItem("IA_widgetsViewType") && JSON.parse(localStorage.getItem("IA_widgetsViewType")).value === "card" && !this.props.store.isMobile ?
            cards
            :
            <IAListView
              id="ListViewOfWidgets"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              defaultAction={"edit"}
              darkMode={this.props.store.darkMode}
              selectedAction={(action: string, id: string) => {
                switch (action) {
                  case "edit":
                    this.setState({ selectedWidgetId: id });
                    break;
                  case "delete":
                    this.setState({ widgetToDelete: id })
                    break;
                  case "follow":
                    alert("TODO");
                    break;
                  default:
                    break;
                }
              }}
            />
          }
          {PlayHelper.showNextPageButton(this.state.skip, this.state.take, this.state.filterByWidgetType ? this.state.widgetsCount : this.props.store.widgetsCount) && !this.state.isLoading &&
            <div className={styles.IA_paginationButtonContainer}>
              <IAButton
                onClick={() => this.setState({ skip: this.state.skip + this.state.take }, () => { this.getWidgets() })}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                textColor="#ffffff"
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_LoadMore?.toUpperCase()}
                disbaled={this.state.isLoading}
              />
            </div>
          }
          {this.state.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                margin: "20px 0 0 calc(50% - 20px)"
              }}
            />
          }
        </>
        {this.state.showWidgetPopup &&
          <WidgetPopup
            setSelectedWidgetType={(selectedWidgetType: IPlayWidgetType) => {
              this.props.store.widgetToEdit = PlayHelper.getEmptyWidget(selectedWidgetType, this.localizationService);
              this.setState({ showWidgetPopup: false })
            }}
            close={() => this.setState({ showWidgetPopup: false })}
          />
        }
        {this.state.widgetToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteWidget}
            close={() => this.setState({ widgetToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.widgetToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ widgetToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.showWorkplaceAPIKeyIsMisssingPopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_WorkplaceAPIKeyIsMisssingTitle}
            close={() => this.props.store.showWorkplaceAPIKeyIsMisssingPopup = false}
            darkMode={this.props.store.darkMode}
            content={
              <p style={{ fontSize: 16 }}>{this.props.store.isAdmin ? this.localizationService.strings.PlayAdmin_WorkplaceAPIKeyIsMisssingAdminMessage : this.localizationService.strings.PlayAdmin_WorkplaceAPIKeyIsMisssingEditorMessage}</p>
            }
          />
        }
      </div>
    );
  }


}