exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_licensePopup_12Qb1NWboetWfygvZmUPOH .IA_background_2CUXTQSQ_F0QyvEcoLpDvq {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  z-index: 2000000;\n  color: #ffffff;\n}\n\n.IA_licensePopup_12Qb1NWboetWfygvZmUPOH h1 {\n  clear: both;\n  margin-bottom: 25px;\n  font-weight: lighter;\n  color: #ffffff;\n  text-align: center;\n}\n\n.IA_licensePopup_12Qb1NWboetWfygvZmUPOH .IA_contentBox_3AZ9i6Gwvp37rZ8KJ1C9zI {\n  position: fixed;\n  top: calc(50% - 200px);\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  border-radius: 20px;\n  width: 800px;\n  max-height: 100vh;\n  overflow-y: auto;\n  background-color: rgb(252, 138, 0);\n  background: linear-gradient(90deg, #fd5e1d 0%, #fc8a00 100%);\n  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);\n  padding: 20px;\n  padding-bottom: 20px;\n  box-sizing: border-box;\n  text-align: left;\n  z-index: 2000001;\n  background-position: center;\n  background-size: cover;\n  font-family: \"Segoe UI Web (West European)\", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;\n}\n\n@media only screen and (max-device-width: 430px) and (orientation: portrait) {\n  .IA_licensePopup_12Qb1NWboetWfygvZmUPOH .IA_contentBox_3AZ9i6Gwvp37rZ8KJ1C9zI {\n    width: calc(100vw - 40px);\n    overflow-x: hidden;\n  }\n}\n\n.IA_closeButton_G2-PacdNQUdri2lv6YZLS {\n  position: relative;\n  float: right;\n  height: 30px;\n  width: 30px;\n  background-position: center;\n  background-size: 20px;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  background-repeat: no-repeat;\n  margin-top: -5px;\n  margin-right: -17px;\n  opacity: 0.4;\n}\n\n.IA_text_2p77gVYsOMKj-CRlVTARAG {\n  color: #ffffff;\n  font-size: 16px;\n  text-align: center;\n  margin-left: 40px;\n  width: calc(100% - 80px);\n  clear: both;\n}", ""]);

// Exports
exports.locals = {
	"IA_licensePopup": "IA_licensePopup_12Qb1NWboetWfygvZmUPOH",
	"IA_background": "IA_background_2CUXTQSQ_F0QyvEcoLpDvq",
	"IA_contentBox": "IA_contentBox_3AZ9i6Gwvp37rZ8KJ1C9zI",
	"IA_closeButton": "IA_closeButton_G2-PacdNQUdri2lv6YZLS",
	"IA_text": "IA_text_2p77gVYsOMKj-CRlVTARAG"
};