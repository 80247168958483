import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Articles.css';
import { IACard } from '../../../card/Card';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { IAPlayActivityStatus, IListElement, IListElementColumn, IPlayArticle } from '../../../../interfaces/IPlay';
import PlayService from '../../../../services/PlayService';
import { IAHeader } from '../../../header/Header';
import { IASpinner } from '../../../spinner/Spinner';
import { ArticleEditPanel } from './articleEditPanel/ArticleEditPanel';
import { IAIconDropDown } from '../../../iconDropDown/IconDropDown';
import { PlayHelper } from '../../PlayHelper';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { Helper } from '../../../../Helper';
import { ILabel } from '../../../../interfaces/ILabel';
import { IAListView } from '../../../listView/ListView';
import { IATextField } from '../../../textField/TextField';
import Moment from 'react-moment';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  selectedArticleId?: string;
  selectedUserSorting?: IIconDropDown;
  selectedState?: IIconDropDown;
  isLoading: boolean;
  articleToDelete?: string;
  skip: number;
  take: number;
  searchQuery: string;
}

@inject('store')
@observer
export class Articles extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private cardViewService: IACardViewService = new IACardViewService();
  private queryDelay: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedArticleId: undefined,
      isLoading: false,
      skip: 0,
      take: PlayHelper.ELEMENTS_PER_PAGE,
      searchQuery: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.props.store.articles = undefined;
    this.getArticles(true);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton) {
      this.setState({ isLoading: true });
      this.getArticles(true);
    } else {
      this.setState({ skip: this.props.store.articles?.length ? this.props.store.articles?.length - this.state.take : 0 });
    }
  }

  private getArticles(override?: boolean): void {
    if (this.props.store.profile?.id) {
      this.setState({ isLoading: true });
      // Include this.state.articleState ("all", "active", "scheduled", "expired")
      PlayService.getArticles(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, { skip: this.state.skip, take: this.state.take, search: this.state.searchQuery, showOnly: this.state.selectedState != undefined ? [this.state.selectedState.value as IAPlayActivityStatus] : undefined }).then(articles => {
        const appendedArticles = override ? [] : this.props.store.articles ?? [];
        appendedArticles.push(...articles.value);
        this.props.store.articles = appendedArticles;
        this.props.store.articlesCount = articles.count;
        this.setState({ isLoading: false });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deleteArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.articleToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedArticleId: undefined, articleToDelete: undefined, skip: 0 }, () => this.getArticles(true));
    });
  }

  private onSave(): void {
    this.props.store.workingOnIt = true;
    const articleModel = {
      ...this.props.store.articleToEdit,
      startDate: new Date(this.props.store.articleToEdit.startDate).toJSON(),
      endDate: this.props.store.articleToEdit.endDate ? new Date(this.props.store.articleToEdit.endDate).toJSON() : null
    }
    if (this.props.store.articleToEdit.id && this.props.store.articleToEdit.id !== "new") {
      PlayService.updateArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, articleModel).then(() => {
        console.log(`Updated article with id: ${this.props.store.articleToEdit.id}`)
        this.props.store.workingOnIt = false;
        this.props.store.articleToEdit = undefined;
        this.setState({ selectedArticleId: undefined, skip: 0 }, () => this.getArticles(true));
      });
    }
    else {
      PlayService.createArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, articleModel).then((id) => {
        console.log(`Created article with id: ${id}`)
        if (localStorage.getItem(`IA_articlePreviewSettings_undefined`)) {
          localStorage.setItem(`IA_articlePreviewSettings_${id}`, localStorage.getItem(`IA_articlePreviewSettings_undefined`));
          localStorage.removeItem(`IA_articlePreviewSettings_undefined`);
        }
        this.props.store.workingOnIt = false;
        this.props.store.articleToEdit = undefined;
        this.setState({ selectedArticleId: undefined, skip: 0 }, () => this.getArticles(true));
      });
    }
  }

  private duplicateArticle(article: IPlayArticle): void {
    this.props.store.workingOnIt = true;
    let duplicatArticle: IPlayArticle = JSON.parse(JSON.stringify(article));
    duplicatArticle.id = undefined;
    PlayService.createArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, duplicatArticle).then((id) => {
      console.log(`Created article with id: ${id}`)
      this.props.store.workingOnIt = false;
      this.setState({ selectedArticleId: undefined, skip: 0 }, () => this.getArticles(true));
    });
  }

  public render(): JSX.Element {
    const showCards = localStorage.getItem("IA_articlesViewType") && JSON.parse(localStorage.getItem("IA_articlesViewType")).value === "card" && !this.props.store.isMobile;
    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    // Resizing cards
    const cardMarginRight = 20;
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, cardMarginRight, 250, 5);

    let currentRowNumber = 1;
    if (this.props.store.articles && this.props.store.articles.length > 0) {
      this.props.store.articles.forEach((article: IPlayArticle) => {
        let statusColor;
        let statusIconUrl;
        let statusText;
        let backgroundColor;
        if (article.startDate) {
          switch (Helper.getState(article.startDate, article.endDate)) {
            case "active":
              statusColor = "#6ea63f";
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Checkmark%20Circle/SVG/ic_fluent_checkmark_circle_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Active;
              break;
            case "scheduled":
              statusColor = this.props.store.highlightColor;
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Scheduled;
              break;
            case "expired":
              statusColor = "gray";
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Expired;
              break;
          }
        }
        let timespand;
        if (article.endDate) {
          timespand = (
            <span style={{ paddingLeft: showCards ? 5 : 0 }}>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {article.startDate}
              </Moment>
              <span> - </span>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {article.endDate}
              </Moment>
            </span>
          );
        } else {
          timespand = (
            <span style={{ paddingLeft: showCards ? 5 : 0 }}>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {article.startDate}
              </Moment>
            </span>
          );
        }
        const columns: IListElementColumn[] = this.props.store.isMobile ?
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: article.title?.replace("<br>", " "),
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
              type: "icon",
              iconUrl: statusIconUrl,
              tooltip: statusText,
              color: statusColor,
              iconSize: 18,
              marginTop: 16
            },
          ]
          :
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: article.title?.replace("<br>", " "),
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
              type: "icon",
              iconUrl: statusIconUrl,
              tooltip: statusText,
              color: statusColor,
              iconSize: 18,
              marginTop: 16
            },
            {
              header: this.localizationService.strings.PlayAdmin_Scheduled.toUpperCase(),
              type: "text",
              text: timespand,
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
            },
            {
              header: this.localizationService.strings.PlayAdmin_Modified?.toUpperCase(),
              type: "date",
              date: article.modified,
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
            },
            {
              header: this.localizationService.strings.PlayAdmin_ModifiedBy?.toUpperCase(),
              type: "text",
              text: article.modifiedBy?.displayName,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ];
        if (new Date(article?.created) > PlayHelper.NEW_ARTICLES_LAUNCH_DATE && article?.boxStyle) {
          listElements.push(
            {
              id: article.id,
              columns: columns,
              buttons: [
                // {
                //   action: "follow",
                //   icon: article.isFavorite ? "Star Filled" : "Star",
                //   color: this.props.store.highlightColor
                // },
                {
                  action: "delete",
                  icon: "Trash",
                  color: this.props.store.highlightColor
                },
                {
                  action: "edit",
                  icon: "Edit",
                  color: this.props.store.highlightColor
                },
                {
                  action: "duplicate",
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Save Copy/SVG/ic_fluent_save_copy_24_regular.svg",
                  color: this.props.store.highlightColor
                }
              ]
            }
          );
        }

        let placeHolderImage = this.props.store.darkMode ? "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default-dark.jpg" : "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default.jpg";

        let labels: ILabel[] = [];
        if (article.startDate) {
          labels.push(
            {
              tooltip: statusText,
              iconUrl: statusIconUrl,
              backgroundColor: backgroundColor,
              borderRadius: 5,
              iconColor: statusColor,
              textColor: this.props.store.darkMode ? "#ffffff" : "#777777",
              text: timespand,
              textSize: 11,
            }
          );
        }

        let teaser = "";
        if (article.text) {
          teaser = article.text;
        }

        if (article.textContent?.length > 0) {
          teaser = Helper.stripHtml(article.textContent[0]);
        }
        if (new Date(article?.created) > PlayHelper.NEW_ARTICLES_LAUNCH_DATE && article?.boxStyle) {
          let cardImage = placeHolderImage;
          if (article.imageUrl) {
            cardImage = PlayHelper.excludeImageFromApi(this.props.store.environment, article.imageUrl)
              ? cardImage
              : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, article.imageUrl);
          }
          cards.push(
            <IACard
              key={`article_${article.id} `}
              id={article.id}
              showSkeleton={false}
              title={article.title?.replace("<br>", " ")}
              author={article.modifiedBy?.displayName}
              darkMode={this.props.store.darkMode}
              cover={{ imageURL: cardImage }}
              labels={labels}
              width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
              isMobile={this.props.store.isMobile}
              styles={{
                marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : cardMarginRight,
                marginBottom: 20,
                width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }}
              design={{
                highlightColor: this.props.store.highlightColor,
                showDropShadow: true,
                borderRadius: 5,
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
                hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
              }}
              onClickOpen={() => this.setState({ selectedArticleId: article.id })}
              alwaysShowFooter
              footerElements={[
                {
                  icon: "Edit",
                  color: this.props.store.highlightColor,
                  onClick: () => this.setState({ selectedArticleId: article.id })
                },
                {
                  icon: "Trash",
                  color: this.props.store.highlightColor,
                  onClick: () => this.setState({ articleToDelete: article.id })
                }, {
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Save Copy/SVG/ic_fluent_save_copy_24_regular.svg",
                  color: this.props.store.highlightColor,
                  onClick: () => this.duplicateArticle(article)
                }
              ]}
              teaser={teaser}
            />
          );
          if (currentRowNumber == numberOfCardsPerRow) {
            currentRowNumber = 1;
          } else {
            currentRowNumber++;
          }
        }

      })
    }
    return (
      <div
        className={styles.IA_articles}
      >
        {this.props.store.articles &&
          <ArticleEditPanel
            isOpen={!!this.props.store.articleToEdit}
            articleId={this.state.selectedArticleId}
            showWidgetsSelector
            close={() => {
              this.props.store.articleToEdit = undefined;
              this.setState({ selectedArticleId: undefined });
            }}
            onSave={() => this.onSave()}
          />
        }
        <IAHeader
          id="play-article-header"
          title={this.localizationService.strings.PlayAdmin_YourArticles}
          description={this.localizationService.strings.PlayAdmin_YourArticlesDesc}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.props.store.articleToEdit = PlayHelper.getEmptyArticle();
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourArticles,
              text: this.localizationService.strings.PlayAdmin_YourArticlesDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-articles.png"
            }
          ]}
        />
        {!this.props.store.isMobile &&
          <div className={styles.IA_filters}>
            <IAIconDropDown
              options={PlayHelper.getState(this.localizationService)}
              selectedOption={this.state.selectedState}
              placeholder={this.localizationService.strings.PlayAdmin_FilterState}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              clearable
              onChange={(selectedState: IIconDropDown) => {
                this.setState({
                  selectedState,
                  skip: 0,
                  take: PlayHelper.ELEMENTS_PER_PAGE,
                }, () => {
                  this.props.store.articles = undefined;
                  this.getArticles(true);
                });
              }}
              style={{
                minWidth: 220,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
              }}
            />
            <IATextField
              style={{
                width: 330,
                marginRight: 20,
                marginTop: 0
              }}
              inputFieldStyle={{
                marginTop: 0,
                height: 38,
                borderColor: "#cccccc",
                fontSize: 15
              }}
              text={this.state.searchQuery}
              placeholder={this.localizationService.strings.PlayAdmin_SearchArticles}
              borderRadius={5}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(search: string) => {
                this.setState({
                  skip: 0,
                  searchQuery: search
                })
                clearTimeout(this.queryDelay);
                this.queryDelay = setTimeout(() => {
                  this.props.store.articles = undefined;
                  this.getArticles(true);
                }, 500);
              }}
            />
            {/* <IAIconDropDown
              options={PlayHelper.getSortingOptions(this.localizationService)}
              selectedOption={localStorage.getItem("IA_articlesSortingOption") ? JSON.parse(localStorage.getItem("IA_articlesSortingOption")) : PlayHelper.getSortingOptions(this.localizationService)[0]}
              // label={this.localizationService.strings.PlayAdmin_Sorting}
              disabled
              darkMode={this.props.store.darkMode}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_articlesSortingOption", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 320,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            />
            <IAIconDropDown
              options={PlayHelper.getUserSortingOptions(this.localizationService)}
              selectedOption={this.state.selectedUserSorting}
              // label={this.localizationService.strings.PlayAdmin_UserSorting}
              disabled
              darkMode={this.props.store.darkMode}
              placeholder={this.localizationService.strings.PlayAdmin_UserSortingPlaceholder}
              clearable
              onChange={(selectedUserSorting: IIconDropDown) => this.setState({ selectedUserSorting }, () => this.getArticles())}
              style={{
                minWidth: 170,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            /> */}
            <IAIconDropDown
              options={PlayHelper.getViewTypes()}
              selectedOption={localStorage.getItem("IA_articlesViewType") ? JSON.parse(localStorage.getItem("IA_articlesViewType")) : PlayHelper.getViewTypes()[0]}
              // label={this.localizationService.strings.PlayAdmin_ViewType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_articlesViewType", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 80,
                marginBottom: 20,
                marginLeft: "auto"
              }}
            />
          </div>
        }
        <>
          {showCards ?
            cards
            :
            <IAListView
              id="ListViewOfArticles"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              darkMode={this.props.store.darkMode}
              defaultAction={"edit"}
              selectedAction={(action: string, id: string) => {
                switch (action) {
                  case "edit":
                    this.setState({ selectedArticleId: id });
                    break;
                  case "delete":
                    this.setState({ articleToDelete: id });
                    break;
                  case "follow":
                    alert("TODO");
                    break;
                  case "duplicate":
                    this.props.store.articles.forEach((article: IPlayArticle) => {
                      if (article.id === id) {
                        this.duplicateArticle(article);
                        return;
                      }
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          }
          {PlayHelper.showNextPageButton(this.state.skip, this.state.take, this.props.store.articlesCount) && !this.state.isLoading &&
            <div className={styles.IA_paginationButtonContainer}>
              <IAButton
                onClick={() => this.setState({ skip: this.state.skip + this.state.take }, () => { this.getArticles() })}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                textColor="#ffffff"
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_LoadMore?.toUpperCase()}
              />
            </div>
          }
          {this.state.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                margin: "20px 0 0 calc(50% - 20px)"
              }}
            />
          }
        </>
        {this.state.articleToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteArticle}
            close={() => this.setState({ articleToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.articleToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ articleToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }


}