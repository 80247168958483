exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_group_3MBo_j3tdDnOMmf00cDvyp {\n  width: 100%;\n  clear: both;\n  margin-bottom: 10px;\n  float: left;\n}\n\n.IA_line_3IhjU6gS4lhLmOqp1K1G4Y {\n  width: calc(50% - 150px);\n  height: 1px;\n  background-color: #eeeeee;\n  float: left;\n  margin-top: 8px;\n}\n\n.IA_title_1Megu1VhBjj72T1x8Nb-JB {\n  font-size: 14px;\n  cursor: pointer;\n  font-weight: bold;\n  text-align: center;\n  float: left;\n}\n\n.IA_groupArea_14aEbgXh4rPsr6ojrPkOw3 { \n  height: auto;\n  width: 100%;\n  box-sizing: border-box;\n  margin-top: 10px;\n  position: relative;\n  float: left;\n  margin-bottom: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_group": "IA_group_3MBo_j3tdDnOMmf00cDvyp",
	"IA_line": "IA_line_3IhjU6gS4lhLmOqp1K1G4Y",
	"IA_title": "IA_title_1Megu1VhBjj72T1x8Nb-JB",
	"IA_groupArea": "IA_groupArea_14aEbgXh4rPsr6ojrPkOw3"
};