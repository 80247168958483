import * as React from 'react';
import * as styles from "./NewComment.css";
import { IAPopup } from '../../popup/Popup';
import { IANewComment } from './NewComment';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { IReply } from '../../../interfaces/IReply';
import { IUser } from '../../../interfaces/IUser';
import { LocalizationService } from '../../../services/LocalizationService';

export interface IANewCommentMobilePopupProps {
  tenant: string;
  environment: IEnvironment;
  token: string;
  highlightColor: string;
  reply: IReply
  user: IUser;
  isAddingComment: boolean;
  content?: any;
  closePopup: () => void;
  comment: (comment: string, mentions?: IUser[], reply?: IReply) => Promise<void>;
}

export class IANewCommentMobilePopup extends React.Component<IANewCommentMobilePopupProps> {

  private readonly localizationService = new LocalizationService();

  public componentDidMount(): void {
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return (
      <IAPopup
        headline={""}
        contentOverflow="visible"
        close={() => this.props.closePopup()}
        zIndex={3000001}
        content={<div className={styles.IA_commentReplyMobilePopup}>
          {!this.props.content &&
            <div>{this.localizationService.strings.LikesAndComments_ReplyTo + " "}<b>{this.props.reply?.user?.displayName ?? ""}</b></div>
          }
          <IANewComment
            useNewEditor
            addComment={true}
            cancelReply={() => this.props.closePopup()}
            content={this.props.content}
            comment={(comment, mentions, reply) => this.props.content ? this.props.comment(comment) : this.props.comment(comment, mentions, reply)}
            environment={this.props.environment}
            highlightColor={this.props.highlightColor}
            isMobile
            reply={this.props.reply}
            tenant={this.props.tenant}
            token={this.props.token}
            user={this.props.user}
            isAddingComment={this.props.isAddingComment}
            marginBottom={"0px"}
          // textAreaStyle={{
          //   maxHeight: 125,
          //   overflow: "auto"
          // }}
          />
        </div>
        }
      />
    );
  }
}