import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './WidgetPopup.css';
import { Store } from '../../stores';
import { PlayHelper } from '../../PlayHelper';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { IPlayWidget, IPlayWidgetExtended, IPlayWidgetType } from '../../../../interfaces/IPlay';
import { Helper } from '../../../../Helper';
import { IAIcon } from '../../../icon/Icon';
import PlayService from '../../../../services/PlayService';
import { IASpinner } from '../../../spinner/Spinner';

export interface IProps {
  selectedWidgetIndex?: number;
  availableWidgets?: IPlayWidget[];
  store?: Store;
  close: () => void;
  setSelectedWidgetType: (selectedWidgetType: IPlayWidgetType) => void;
}

export interface IState {
  workplaceAPIKey: string;
  chosenType: IPlayWidgetType;
  isLoadingSelectedWidget: boolean;
}

@inject('store')
@observer
export class WidgetPopup extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      workplaceAPIKey: undefined,
      chosenType: undefined,
      isLoadingSelectedWidget: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount(): void {
    if (this.props.selectedWidgetIndex !== undefined && this.props.store.slideToEdit?.widgets[this.props.selectedWidgetIndex]?.type) {
      this.setState({ chosenType: this.props.store.slideToEdit?.widgets[this.props.selectedWidgetIndex]?.type });
    } else {
      this.setState({ chosenType: undefined });
    }
    PlayService.getWorkplaceAPIKey(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then((apiKey: string) => {
      this.setState({ workplaceAPIKey: apiKey });
    })
    const widget: IPlayWidgetExtended = this.props.store.slideToEdit?.widgets[this.props.selectedWidgetIndex];
    if (widget) {
      this.props.setSelectedWidgetType(widget.type);
    }
  }

  private selectWidgetType(type: IPlayWidgetType) {
    if (this.props.store.slideToEdit) {
      if (!this.props.store.slideToEdit.widgets) {
        this.props.store.slideToEdit.widgets = new Array(4);
      }
      if (this.props.store.slideToEdit.widgets[0] == undefined) {
        this.props.store.slideToEdit.widgets.push({});
      }
      if (this.props.store.slideToEdit.widgets[1] == undefined) {
        this.props.store.slideToEdit.widgets.push({});
      }
      if (this.props.store.slideToEdit.widgets[2] == undefined) {
        this.props.store.slideToEdit.widgets.push({});
      }
      if (this.props.store.slideToEdit.widgets[3] == undefined) {
        this.props.store.slideToEdit.widgets.push({});
      }
      if (type === "workplaceFeedViewer" && !this.state.workplaceAPIKey) {
        this.props.store.showWorkplaceAPIKeyIsMisssingPopup = true;
      } else {
        this.props.setSelectedWidgetType(type);
        this.setState({ chosenType: type });
      }
    } else {
      if (type === "workplaceFeedViewer" && !this.state.workplaceAPIKey) {
        this.props.store.showWorkplaceAPIKeyIsMisssingPopup = true;
      } else {
        this.props.setSelectedWidgetType(type);
        this.setState({ chosenType: type });
      }
    }
  }

  private insertEmptyWidget(type: IPlayWidgetType) {
    this.props.store.widgetToEdit = PlayHelper.getEmptyWidget(type, this.localizationService);
    this.forceUpdate();
  }

  public render(): JSX.Element {
    const widgetTypes = [];
    const widgetTypesList = PlayHelper.getAvailableWidgets(
      this.localizationService,
      this.props.store.environment,
      this.props.store.isConnectedToMessages(),
      this.props.store.isConnectedToEvents(),
      this.props.store.isConnectedToSharepointNews(),
      this.props.store.isConnectedFacebook(),
      this.props.store.isConnectedToPowerBi(),
      this.props.store.isConnectedToRelesys()
    );
    widgetTypesList.forEach((widgetType: IIconDropDown, i: number) => {
      let opacity = 1;
      if (widgetType.disabled) {
        opacity = .5;
      }
      widgetTypes.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: opacity,
            cursor: widgetType.disabled ? "default" : "pointer"
          }}
          onClick={() => {
            if (!widgetType.disabled) {
              this.selectWidgetType(widgetType.value as IPlayWidgetType);
            }
          }}
        >
          <img
            src={`https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(widgetType.value as IPlayWidgetType)}.png`}
            width="65px"
            height="65px"
            style={{
              float: "left",
              borderRadius: 10,
              backgroundColor: "#eeeeee",
              border: widgetType.value === "sharepointNewsViewer" || widgetType.value === "youtube" ? "1px solid #e5e5e5" : "1px solid transparent"
            }}
          />
          <div className={styles.IA_widgetTypeLabel}>
            {widgetType.label}
          </div>
        </div>
      );
    });

    const widgetAlreadyOnSlide = this.props.store.slideToEdit?.widgets[this.props.selectedWidgetIndex];

    return (
      <div
        className={styles.IA_widgetPopup}
      >
        <div
          className={styles.IA_background}
          style={{
            backgroundColor: this.props.store.darkMode ? "#000000b3" : "#ffffffb3"
          }}
          onClick={() => {
            if (this.props.close && !this.state.isLoadingSelectedWidget) {
              this.props.close();
            }
          }}
        >
        <div
          className={styles.IA_contentBox}
          style={{
            backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#ffffff",
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }}
          onClick={(event) => event.stopPropagation()}
        >
          {this.state.isLoadingSelectedWidget &&
            <div
              className={styles.IA_loadingSelectedWidgetOverlay}
              style={{
                backgroundColor: (this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#ffffff") + "7f"
              }}
            >
              <IASpinner color={this.props.store.highlightColor}/>
            </div>
          }
          {this.props.close != undefined &&
            <IAIcon
              title={"Close"}
              size={18}
              color={this.props.store.darkMode ? "#ffffff" : "#333333"}
              style={{
                float: "right",
                borderRadius: 5,
                width: 24,
                height: 24,
                lineHeight: "18px",
                padding: 2,
                boxSizing: "border-box"
              }}
              onClick={() => this.props.close()}
            />
          }
          {!this.state.chosenType
          ?
            <>
              <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : "#333333", fontSize: 22 }}>{this.localizationService.strings.PlayAdmin_SetupWidgetForSelectedArea}</h1>
              <div className={styles.IA_widgetWrapper}>
                {widgetTypes}
              </div>
            </>
          :
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center"
                }}
              >
                <img
                  src={`https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(this.state.chosenType)}-placeholder.png`}
                  className={styles.IA_widgetSelectorHeaderImage}
                />
              </div>
              <div style={{position: "absolute", top: 20, left: 20}}>
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow Left/SVG/ic_fluent_arrow_left_48_regular.svg"
                  size={18}
                  color={this.props.store.darkMode ? "#ffffff" : "#333333"}
                  onClick={() => {
                    this.setState({chosenType: undefined});
                    this.props.setSelectedWidgetType(undefined);
                  }}
                />
              </div>
              <p>{this.localizationService.strings.PlayAdmin_SelectWidget.replace("[type]", PlayHelper.getTitleForWidgetType(this.state.chosenType, this.localizationService).toLocaleLowerCase())}</p>
              <div className={styles.IA_widgetSelector}>
                <div
                  className={styles.IA_widgetSelectorContainer}
                  onClick={() => {
                    this.props.store.widgetToEdit = {
                      type: this.state.chosenType
                    };
                    this.insertEmptyWidget(this.state.chosenType);
                  }}
                >
                  <div
                    className={styles.IA_widgetSelectorImageContainer}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    <IAIcon
                      url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Add Circle/SVG/ic_fluent_add_circle_32_regular.svg"
                      size={40}
                      color={this.props.store.darkMode ? "#ffffff" : "#333333"}
                    />
                  </div>
                  <p className={styles.IA_widgetSelectorText}>{this.localizationService.strings.PlayAdmin_NewWidget}</p>
                </div>
                {!this.props.availableWidgets &&
                  <>
                    <div className={[styles.IA_widgetSelectorSkeleton, this.props.store.darkMode ? styles.IA_widgetSelectorSkeletonDark : styles.IA_widgetSelectorSkeletonLight].join(" ")}/>
                    <div className={[styles.IA_widgetSelectorSkeleton, this.props.store.darkMode ? styles.IA_widgetSelectorSkeletonDark : styles.IA_widgetSelectorSkeletonLight].join(" ")}/>
                    <div className={[styles.IA_widgetSelectorSkeleton, this.props.store.darkMode ? styles.IA_widgetSelectorSkeletonDark : styles.IA_widgetSelectorSkeletonLight].join(" ")}/>
                  </>
                }
                {this.props.availableWidgets?.map(widget => {
                  let image = `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(this.state.chosenType)}.png`;
                  if (widget.type === "image" && widget.image?.imageUrl) {
                    image = !PlayHelper.excludeImageFromApi(this.props.store.environment, widget.image.imageUrl)
                            ? PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, widget.image.imageUrl)
                            : widget.image.imageUrl;
                  }
                  if (widget.type === "gallery" && widget.gallery?.images?.length > 0) {
                    image = !PlayHelper.excludeImageFromApi(this.props.store.environment, widget.gallery.images[0])
                            ? PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, widget.gallery.images[0])
                            : widget.gallery.images[0];
                  }
                  return <div
                    key={widget.id}
                    className={styles.IA_widgetSelectorContainer}
                    style={{
                      opacity: widgetAlreadyOnSlide?.id !== undefined && widget.type === widgetAlreadyOnSlide.type ? (widgetAlreadyOnSlide?.id === widget.id ? 1 : .75) : 1
                    }}
                    onClick={() => {
                      this.setState({ isLoadingSelectedWidget: true });
                      PlayService.getWidgetById(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, widget.id).then((widget: IPlayWidgetExtended) => {
                        this.props.store.slideToEdit.widgets[this.props.selectedWidgetIndex] = widget;
                        this.setState({ chosenType: undefined });
                        this.props.close();
                      }).finally(() => this.setState({ isLoadingSelectedWidget: false }));
                    }}
                  >
                    <div
                      className={styles.IA_widgetSelectorImageContainer}
                      style={{
                        borderWidth: this.props.store.darkMode ? 0 : 1
                      }}
                    >
                      <img
                        src={image}
                        className={styles.IA_widgetSelectorImage}
                      /> 
                    </div>
                    <p className={styles.IA_widgetSelectorText}>{widget.title}</p>
                  </div>
                })}
              </div>
            </>
          }
          </div>
        </div>
      </div>
    );
  }

  
}