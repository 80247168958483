import * as React from 'react';
import { Helper } from '../../../../../Helper';
import DateTimeService from '../../../../../services/DateTimeService';
import * as styles from './CanteenMenuItem.css';
import { IAReader } from '../../../../reader/Reader';

export interface IProps {
  sizeUnit: number;
  textColor: string;
  title: string;
  content: string;
  startDate: string;
  endDate: string;
  location: string;
  dayInMonth: string;
  dayOfTheWeek: string;
  isVertical: boolean;
}

export interface IState {
}

export class CanteenMenuItem extends React.Component<IProps, IState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private itemId: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
    this.itemId = Helper.getRandomStringKey();
  }

  private formatText(): void {
    try {
      const contentBox = document.getElementById(`IA_text_${this.itemId}`);
      if (contentBox) {
        const links = contentBox.getElementsByTagName("a");
        if (links?.length > 0) {
          for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
            links[linkIndex].style.textDecoration = "none";
          }
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    const canteenMenuItemDivs = document.getElementById(`IA_canteenMenuItem_${this.itemId}`);
    let height = 0;
    try {
      if (canteenMenuItemDivs) {
        let canteenMenuItemContentElements: any = document.getElementsByClassName(`IA_canteenMenuItemContentElement_${this.itemId}`);
        if (canteenMenuItemContentElements?.length > 0) {
          height = canteenMenuItemDivs.clientHeight;
          const headlineDateDiv = document.getElementById(`IA_Headline`)
          if (headlineDateDiv) {
            height -= headlineDateDiv.clientHeight;
            height -= (this.props.sizeUnit * 2.5)
          }
          const headerDateDiv = document.getElementById(`IA_headerDate_${this.itemId}`)
          if (headerDateDiv) {
            height -= headerDateDiv.clientHeight;
            height -= (this.props.sizeUnit * 2)
          }
          const titleDiv = document.getElementById(`IA_title_${this.itemId}`)
          if (titleDiv) {
            height -= titleDiv.clientHeight;
            height -= this.props.sizeUnit
          }
          const timeDiv = document.getElementById(`IA_time_${this.itemId}`)
          if (timeDiv) {
            height -= timeDiv.clientHeight;
          }
          const locationDiv = document.getElementById(`IA_location_${this.itemId}`)
          if (locationDiv) {
            height -= locationDiv.clientHeight;
          }
          let contentHeight = 0;
          for (let canteenMenuItemContentElement of canteenMenuItemContentElements) {
            contentHeight += canteenMenuItemContentElement.clientHeight;
            if (canteenMenuItemContentElement.nodeName === "UL") {
              contentHeight += (this.props.sizeUnit * 2);
            }
            if (height > contentHeight) {
              canteenMenuItemContentElement.style.opacity = 1;
            } else {
              canteenMenuItemContentElement.style.opacity = 0;
            }
          }
        }
      }
      // Text
      this.formatText();
      const contentBox = document.getElementById(`IA_canteenMenuItem_${this.itemId}`);
      if (contentBox) {
        const paragraphs: any = contentBox.getElementsByTagName('p');
        if (paragraphs) {
          for (let paragraph of paragraphs as HTMLParagraphElement[]) {
            if (paragraph.parentElement.classList.contains("ql-editor")) continue;
            paragraph.style.fontSize = `${this.props.sizeUnit * (this.props.isVertical ? 1.5 : 2.9)}px`;
            paragraph.style.lineHeight = `${this.props.sizeUnit * (this.props.isVertical ? 1.8 : 3.5)}px`;
          }
        }
      } else {
        setTimeout(() => {
          this.forceUpdate();
        }, 10);
      }
    } catch (error) {
      console.log(`% c${error} `, 'background: red; color: #ffffff')
    }
    return (
      <div
        id={`IA_canteenMenuItem_${this.itemId}`}
        className={styles.IA_canteenMenuItem}
        style={{
          color: this.props.textColor
        }}
      >
        <div
          id={`IA_headerDate_${this.itemId} `}
          style={{
            opacity: 0.7
          }}
        >
          <div
            style={{
              fontSize: this.props.sizeUnit * (this.props.isVertical ? 2 : 4),
            }}
          >
            {this.props.dayInMonth}
          </div>
          <div
            style={{
              fontSize: this.props.sizeUnit * (this.props.isVertical ? 0.75 : 1.5),
              marginBottom: this.props.sizeUnit * 2
            }}
          >
            {this.props.dayOfTheWeek?.toUpperCase()}
          </div>
        </div>
        {this.props.title &&
          <div
            id={`IA_title_${this.itemId} `}
            className={styles.IA_title}
            style={{
              fontSize: this.props.sizeUnit * (this.props.isVertical ? 1.5 : 3),
              marginBottom: this.props.sizeUnit
            }}
          >
            {this.props.title}
          </div>
        }
        {this.props.startDate &&
          <div
            id={`IA_time_${this.itemId} `}
            className={styles.IA_byline}
            style={{
              fontSize: this.props.sizeUnit * (this.props.isVertical ? 1 : 2),
              marginBottom: this.props.sizeUnit * 0.5,
              opacity: 0.8
            }}
          >
            {/* <IAIcon
              title="Calendar"
              size={this.props.sizeUnit * 3}
              color={this.props.textColor}
              style={{
                float: "left",
              }}
            /> */}
            <div style={{
              float: "left",
              // marginLeft: this.props.sizeUnit,
            }}>
              {`${this.dateTimeService.ConvertToHHMM(this.props.startDate)} - ${this.dateTimeService.ConvertToHHMM(this.props.endDate)} `}
            </div>
          </div>
        }
        {this.props.location &&
          <div
            id={`IA_location_${this.itemId} `}
            className={styles.IA_byline}
            style={{
              fontSize: this.props.sizeUnit * (this.props.isVertical ? 1 : 2),
              marginBottom: this.props.sizeUnit * 0.5,
              opacity: 0.8
            }}
          >
            {/* <IAIcon
              title="Address"
              size={this.props.sizeUnit * 3}
              color={this.props.textColor}
              style={{
                float: "left",
              }}
            /> */}
            <div style={{
              float: "left",
              //  marginLeft: this.props.sizeUnit
            }}>
              {this.props.location}
            </div>
          </div>
        }
        {this.props.content?.length > 0 &&
          <div
            id={`IA_text_${this.itemId} `}
            className={styles.IA_text}
            style={{
              height: height
            }}
          >
            <IAReader
              useNewEditor={true}
              content={this.props.content}
              style={{
                position: "relative",
                borderWidth: 1,
                wordBreak: "break-word"
              }}
            />
          </div>
        }
      </div>
    )
  }


}