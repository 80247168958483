exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_pivot_3-h5eXSUH2ncLsWh9NShHQ {\n  width: 100%;\n  margin-bottom: 20px;\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.IA_pivot_3-h5eXSUH2ncLsWh9NShHQ .IA_pivotItem_3gvayRkHKP0xJ0ne3m0k9e {\n  height: 25px;\n  line-height: 25px;\n  width: auto;\n  margin-right: 20px;\n  cursor: pointer;\n}\n\n.IA_tabItem_1f3P59BqajXapSK9b0EYOJ {\n  float: left;\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  text-transform: uppercase;\n  cursor: pointer;\n  border-right: 1px solid #ffffff;\n  box-sizing: border-box;\n  padding: 0px 10px;\n}\n\n.IA_tabItemNumber_E5tIDKpzOVr7JUoVXPBkI {\n  float: left;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  margin-top: 10px;\n  line-height: 20px;\n  margin-left: 20px;\n}\n\n.IA_tabItemIcon_2Hzil88CSsnZQ0YM_hBpX0 {\n  float: left;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  margin-top: 12px;\n  line-height: 20px;\n  margin-left: 20px;\n}\n\n.IA_tabItemText_1JG98QKKRrIrwMKqbWSqf- {\n  float: left;\n  height: 20px;\n  border-radius: 20px;\n  margin-left: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_pivot": "IA_pivot_3-h5eXSUH2ncLsWh9NShHQ",
	"IA_pivotItem": "IA_pivotItem_3gvayRkHKP0xJ0ne3m0k9e",
	"IA_tabItem": "IA_tabItem_1f3P59BqajXapSK9b0EYOJ",
	"IA_tabItemNumber": "IA_tabItemNumber_E5tIDKpzOVr7JUoVXPBkI",
	"IA_tabItemIcon": "IA_tabItemIcon_2Hzil88CSsnZQ0YM_hBpX0",
	"IA_tabItemText": "IA_tabItemText_1JG98QKKRrIrwMKqbWSqf-"
};