exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_13YdIQS2cwGoH6JDMUK1e6 {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_1CLSUYFir7d8sJZF0YAkJO {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_2kIA9n45DYYnorVhTkg8PY {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_2UjM5sldmkCsvkWJiX7Fxn {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_3RttegJ3VlNoXGKWp4hqSQ {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_TobhsX39SghM0ghK_zQkj {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_13YdIQS2cwGoH6JDMUK1e6",
	"IA_image": "IA_image_1CLSUYFir7d8sJZF0YAkJO",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_2kIA9n45DYYnorVhTkg8PY",
	"IA_thumbnails": "IA_thumbnails_2UjM5sldmkCsvkWJiX7Fxn",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_3RttegJ3VlNoXGKWp4hqSQ",
	"IA_thumbnail": "IA_thumbnail_TobhsX39SghM0ghK_zQkj"
};