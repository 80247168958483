exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_widgets_2pwvvuVN_uHSaLCICqKc_7 {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2peGKVo6w-RFv_MwKt1MYs {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2yguGnxoAolFEmXQ1forPj {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1JnmB1m-cqjeK51v3h9V5S {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_widgets": "IA_widgets_2pwvvuVN_uHSaLCICqKc_7",
	"IA_filters": "IA_filters_2peGKVo6w-RFv_MwKt1MYs",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2yguGnxoAolFEmXQ1forPj",
	"IA_paginationPageCount": "IA_paginationPageCount_1JnmB1m-cqjeK51v3h9V5S"
};