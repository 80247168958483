exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardCover_UASFbNyazth8netWlObTU {\n  background-color: #eeeeee;\n  width: 100%;\n  height: 144px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.IA_cardCover_UASFbNyazth8netWlObTU iframe {\n  border: none;\n}\n\n/* Overlay image */\n\n.IA_coverOverlay_3XnxQo3y2DktBb67R98i-a {\n  width: 74px;\n  height: 74px;\n  background: rgba(255, 255, 255, 0.8);\n  position: absolute;\n  color: #333333;\n}\n\n.IA_startDate_1SHC8JbWN1VMMVgW6MP2xC {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_startDateendDateDivider_3EUViE2twTcObW_joDkTzd {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n  margin-top: 10px;\n}\n\n.IA_endDate_2CMgC9wpLmfXx6shqc22cL {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 6px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_coverOverlayToday_3U4m3thXYwcoLJZgG5BiW1 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n  font-size: 14px;\n}\n\n.IA_coverOverlayMonth_1QIVweEXlrMOj6WenwRBUB {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n  font-size: 14px;\n}\n\n.IA_coverOverlayDay_3thIcW79F_1Xa92wpvGblf {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-size: 22px;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardCover": "IA_cardCover_UASFbNyazth8netWlObTU",
	"IA_coverOverlay": "IA_coverOverlay_3XnxQo3y2DktBb67R98i-a",
	"IA_startDate": "IA_startDate_1SHC8JbWN1VMMVgW6MP2xC",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_3EUViE2twTcObW_joDkTzd",
	"IA_endDate": "IA_endDate_2CMgC9wpLmfXx6shqc22cL",
	"IA_coverOverlayToday": "IA_coverOverlayToday_3U4m3thXYwcoLJZgG5BiW1",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_1QIVweEXlrMOj6WenwRBUB",
	"IA_coverOverlayDay": "IA_coverOverlayDay_3thIcW79F_1Xa92wpvGblf"
};