exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gettingStartedOverlay_3xOVcDP9a_2lWxrUsAa7hH {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 999999;\n  box-sizing: border-box;\n}\n\n.IA_headline_3omsNKGyAdah_fIymFOpzG {\n  position: relative;\n  text-align: center; \n  font-size: 24px;\n  width: 100%;\n}\n\n.IA_text_daI2iNUaYN9ifFSvBm7Qz {\n  position: relative;\n  text-align: center;\n  margin-top: 20px;\n  font-size: 16px;\n  width: 100%;\n}\n\n.IA_video_3Ik10NUAWklkBstVYFkaPH {\n  position: relative;\n  margin-top: 30px;\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\n  width: 500px;\n  height: 281px;\n}\n\nvideo::-webkit-media-controls-panel {\n  background-image: linear-gradient(transparent, transparent) !important;\n}\n\n.IA_button_3kvTiLJOQy9PT4AP2kzlI2 {\n  position: relative;\n}\n\n.IA_hide_1_YTekEXpj20akAsECSg9c {\n  position: relative;\n  width: 100%;\n  margin-top: 30px;\n  cursor: pointer;\n  font-size: 16px;\n  text-align: center;\n}\n\n.IA_close_3V9PPdNUy8njuVZ_MygkQw {\n  width: 20px;\n  height: 20px;\n  position: absolute;\n  right: 10px;\n  padding-top: 1px;\n  top: 10px;\n  box-sizing: border-box;\n  border-radius: 5px;\n  z-index: 999999;\n}\n\n.IA_title_15qWZrvJ5nJLbvHSDqH7L6 {\n  width: 100%;\n  text-align: center;\n  margin-top: 10px;\n  color: gray;\n}", ""]);

// Exports
exports.locals = {
	"IA_gettingStartedOverlay": "IA_gettingStartedOverlay_3xOVcDP9a_2lWxrUsAa7hH",
	"IA_headline": "IA_headline_3omsNKGyAdah_fIymFOpzG",
	"IA_text": "IA_text_daI2iNUaYN9ifFSvBm7Qz",
	"IA_video": "IA_video_3Ik10NUAWklkBstVYFkaPH",
	"IA_button": "IA_button_3kvTiLJOQy9PT4AP2kzlI2",
	"IA_hide": "IA_hide_1_YTekEXpj20akAsECSg9c",
	"IA_close": "IA_close_3V9PPdNUy8njuVZ_MygkQw",
	"IA_title": "IA_title_15qWZrvJ5nJLbvHSDqH7L6"
};