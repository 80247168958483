exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu {\n  margin-top: 15px;\n  float: left;\n  clear: both;\n  width: 350px;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 14px;\n}\n\n.IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu label {\n  position: relative;\n  float: left;\n  clear: both;\n  font-size: 14px;\n}\n\n.IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu .picker_3khABA2GxIh7Id6rTEbTXi {\n  clear: both;\n  float: left;\n  margin-top: 10px;\n}\n\n.IA_dateAndTimePickerClearButton_8Wd3ssw8qa1xHMMl_kzTg {\n  position: relative;\n  float: left;\n  background-position: center;\n  background-size: 20px;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  background-repeat: no-repeat;\n  margin-top: 22px;\n  margin-left: 5px;\n}\n\n.IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu input {\n  padding: 10px !important;\n  border: 1px solid #dddddd !important;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif !important;\n  width: 200px;\n  -webkit-appearance: none;\n  line-height: 25px;\n  height: 45px;\n  padding-left: 10px;\n  font-size: 14px;\n  border-radius: 0;\n  position: relative;\n  box-sizing: border-box;\n  border: 1px solid #dddddd;\n  border-radius: 5px;\n}\n\n.IA_darkPicker_2aFaCunucxOVfgJWwhEQf- input {\n  border: 1px solid #414141 !important;\n}\n\n.IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu input:focus-visible {\n  outline: 2px solid var(--highlight-color, #0098f7);\n  outline-offset: -2px;\n}", ""]);

// Exports
exports.locals = {
	"IA_dateAndTimePicker": "IA_dateAndTimePicker_3CemuQif0xO0hDgDDXVMCu",
	"picker": "picker_3khABA2GxIh7Id6rTEbTXi",
	"IA_dateAndTimePickerClearButton": "IA_dateAndTimePickerClearButton_8Wd3ssw8qa1xHMMl_kzTg",
	"IA_darkPicker": "IA_darkPicker_2aFaCunucxOVfgJWwhEQf-"
};