exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_58w23DTfdaIwykyUoDSso{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_3nh7tju4p9xKnajM85oqv4 {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_3nh7tju4p9xKnajM85oqv4 .IA_personaProfileImageWrapper_3u8FvuWAbgTgvyeOy9LZ_r {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_3LvtYE1iV4l6zBP4jUuS9E .IA_personaProfileImageText_2zqZqaBWNyE8Cv_XpbJYLs {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_2JkWBgdeR4BO873VsZEHrm {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_58w23DTfdaIwykyUoDSso",
	"IA_personaWrapper": "IA_personaWrapper_3nh7tju4p9xKnajM85oqv4",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_3u8FvuWAbgTgvyeOy9LZ_r",
	"IA_personaSmall": "IA_personaSmall_3LvtYE1iV4l6zBP4jUuS9E",
	"IA_personaProfileImageText": "IA_personaProfileImageText_2zqZqaBWNyE8Cv_XpbJYLs",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_2JkWBgdeR4BO873VsZEHrm"
};