exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_menuItem_2l5TZjF9_HyaQZYlGExImI, .IA_menuItemDark_2dkBGQ_npFYx-0spcB_XD1 {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 100%;\n  cursor: pointer;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.IA_menuItem_2l5TZjF9_HyaQZYlGExImI:hover {\n  background-color: #eeeeee;\n}\n\n.IA_menuItemDark_2dkBGQ_npFYx-0spcB_XD1:hover {\n  background-color: #414141;\n}\n\n.IA_menuItemIcon_2fSgU0AcOel0OuYwbAd-G9 {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 50px;\n  margin-left: -3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.IA_menuItemText_2wYnOCeoJVBHetke_NpPOR {\n  position: relative;\n  height: 50px;\n  line-height: 50px;\n  width: calc(100% - 50px);\n  box-sizing: border-box;\n}", ""]);

// Exports
exports.locals = {
	"IA_menuItem": "IA_menuItem_2l5TZjF9_HyaQZYlGExImI",
	"IA_menuItemDark": "IA_menuItemDark_2dkBGQ_npFYx-0spcB_XD1",
	"IA_menuItemIcon": "IA_menuItemIcon_2fSgU0AcOel0OuYwbAd-G9",
	"IA_menuItemText": "IA_menuItemText_2wYnOCeoJVBHetke_NpPOR"
};