exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_1Ebm6GLVIkiBxMB9HwjPe1 {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_2DN2P3DGO02M45w58dGHBr {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_21Pm_Dlwre0iEXgVMs8E0n {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_1sNZG0zvV3DrF6NPJfQrZd {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_1e8l8QaWxhg1B_vkc6857B {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_yQrTctaPqPh772_jqoIIL {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_2sQXOE3g8lEClVmMTlGw8J {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_1QDxLYGEtQDBIXSIXMif3j {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_oOQB8hTCTPAI739KdT1UN {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_216en31Voo2hEHcE2ruGth {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_1Jw53K4-u6E4gUdDLsXrVh {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_1Ebm6GLVIkiBxMB9HwjPe1",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_2DN2P3DGO02M45w58dGHBr",
	"IA_logo": "IA_logo_21Pm_Dlwre0iEXgVMs8E0n",
	"IA_clock": "IA_clock_1sNZG0zvV3DrF6NPJfQrZd",
	"IA_time": "IA_time_1e8l8QaWxhg1B_vkc6857B",
	"IA_timeDivider": "IA_timeDivider_yQrTctaPqPh772_jqoIIL",
	"IA_date": "IA_date_2sQXOE3g8lEClVmMTlGw8J",
	"IA_weather": "IA_weather_1QDxLYGEtQDBIXSIXMif3j",
	"IA_weatherIcon": "IA_weatherIcon_oOQB8hTCTPAI739KdT1UN",
	"IA_weatherTemperature": "IA_weatherTemperature_216en31Voo2hEHcE2ruGth",
	"IA_weatherArea": "IA_weatherArea_1Jw53K4-u6E4gUdDLsXrVh"
};