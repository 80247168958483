import * as React from 'react';
import * as styles from './Button.css';
import { Helper } from '../../Helper';
import { IAIcon } from '../icon/Icon';
import "../../styles/BasicIA.css";
import { IASpinner } from '../spinner/Spinner';

export interface IIAButtonProps {
  label?: string;
  arialable?: string;
  buttonColor?: string;
  borderColor?: string;
  mouseOverButtonColor?: string;
  textColor?: string;
  icon?: string;
  iconUrl?: string;
  iconColor?: string;
  iconMarginTop?: number;
  iconRotationDegree?: number;
  height?: number | string;
  minHeight?: number;
  showSpinner?: boolean;
  disbaled?: boolean;
  fixedSize?: string;
  hasFixedSize?: boolean;
  fontSize?: number;
  style?: React.CSSProperties;
  styleLabel?: React.CSSProperties;
  borderRadius?: number;
  selected?: string;
  darkMode?: boolean;
  onClick?: () => void;
  dataAutomationId?: string,
  iconSize?: number;
  onChange?: (option: string) => void;
}

export interface IIAButtonState {
  mouseOver: boolean;
  onMountWidth: number;
}

export class IAButton extends React.Component<IIAButtonProps, IIAButtonState> {
  private buttonRef: HTMLButtonElement;

  constructor(props: IIAButtonProps) {
    super(props);
    this.state = {
      mouseOver: false,
      onMountWidth: undefined
    }
  }

  componentDidMount(): void {
    if (!this.props.showSpinner && this.buttonRef) {
      const showIcon = this.props.icon != undefined || this.props.iconUrl != undefined;
      const iconWidth = showIcon ? (this.props.iconSize ?? 18) : 0;
      // save the width of the button after mounting to be able to keep the width fixed when showSpinner is enabled
      this.setState({onMountWidth: this.buttonRef.getBoundingClientRect().width + iconWidth});
    }
  }

  public render(): JSX.Element {
    let backgroundColor = this.props.buttonColor != undefined ? this.props.buttonColor : "transparent";
    if (this.props.showSpinner && this.props.buttonColor) {
      backgroundColor = this.props.darkMode ? "#414141" : "#eeeeee";
    }
    return (
      <button
        ref={(ref) => this.buttonRef = ref}
        disabled={this.props.showSpinner}
        onClick={() => {
          if (!this.props.disbaled) {
            this.props.onClick();
          }
        }}
        onMouseOver={() => this.setState({ mouseOver: true })}
        onMouseLeave={() => this.setState({ mouseOver: false })}
        className={[styles.IA_button, "IA_OnFocus"].join(" ")}
        style={{
          backgroundColor: this.state.mouseOver && this.props.mouseOverButtonColor ? this.props.mouseOverButtonColor : backgroundColor,
          color: this.props.textColor != undefined ? this.props.textColor : Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.buttonColor != undefined ? this.props.buttonColor : "transparent", "#ffffff", "#333333"),
          height: this.props.height != undefined ? this.props.height : 30,
          minHeight: this.props.minHeight,
          padding: this.props.buttonColor == undefined || this.props.label == undefined ? 0 : (this.props.hasFixedSize ? this.props.fixedSize : (this.props.icon != undefined ? "0px 20px 0px 20px" : "0px 20px")),
          opacity: this.props.disbaled != undefined && this.props.disbaled ? 0.5 : 1,
          fontSize: this.props.fontSize != undefined ? this.props.fontSize : 12,
          lineHeight: this.props.height != undefined ? `${this.props.height}px` : "30px",
          cursor: this.props.disbaled ? "default" : "pointer",
          borderRadius: this.props.borderRadius,
          border: this.props.borderColor ? `1px solid ${this.props.borderColor}` : "none",
          width: this.props.showSpinner && this.state.onMountWidth ? this.state.onMountWidth : undefined,
          ...this.props.style
        }}
        data-automation-id={this.props.dataAutomationId}
        aria-label={this.props.arialable != undefined ? this.props.arialable : "Button"}
      >
        {this.props.showSpinner ?
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <IASpinner color={this.props.buttonColor ?? this.props.textColor} style={{ height: 16, width: 16, margin: 0}}/>
          </div>
          :
          <>
            {(this.props.icon != undefined || this.props.iconUrl != undefined) &&
              <div
                className={styles.icon}
                style={{
                  marginTop: this.props.iconMarginTop ?? (this.props.buttonColor && this.props.buttonColor !== "transparent" ? 6 : 2),
                  paddingLeft: this.props.label == undefined || this.props.label == null ? 5 : "0px",
                  paddingRight: this.props.label == undefined || this.props.label == null ? 0 : "2px",
                  cursor: this.props.disbaled ? "default" : "pointer"
                }}
              >
                <IAIcon
                  svgStyle={{
                    rotate: this.props.iconRotationDegree ? `${this.props.iconRotationDegree}deg` : ""
                  }}
                  title={this.props.icon}
                  url={this.props.iconUrl}
                  size={this.props.iconSize ?? 18}
                  color={this.props.iconColor != undefined ? this.props.iconColor : Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.buttonColor != undefined || this.props.buttonColor == null ? this.props.buttonColor : "#333333", "#ffffff", "#333333")}
                />
              </div>
            }
            <div
              className={!this.props.styleLabel ? styles.label : undefined}
              style={{
                float: (this.props.icon != undefined || this.props.iconUrl != undefined) ? "left" : "none",
                ...this.props.styleLabel
              }}>
              {this.props.label}
            </div>
          </>
        }
      </button>
    );
  }
}