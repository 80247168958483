exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wordImagePasteErrorMessageContainer_1S0EbwaOlZ98zCQMmhSob6 {\n  display: flex;\n  justify-content: center;\n}\n\n.wordImagePasteErrorMessage_1peTZ5_rY8xKv0qpy2CGZb {\n  display: flex;\n  justify-content: center;\n  background-color: orange;\n  border-radius: 5px;\n  transition: all .5s, height .75s;\n  width: 100%;\n  position: relative;\n  text-align: center;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 30px;\n}\n\n.editorHasFocus_1QiJ-ko5GCWKjLk3rKwci_ [class*=\"fr-toolbar\"] {\nz-index: 3000000 !important;\n}\n\n.editorHasBlur_2MxsEXnfQo_RFzF5klgik [class*=\"fr-toolbar\"] {\nz-index: 1 !important;\n}", ""]);

// Exports
exports.locals = {
	"wordImagePasteErrorMessageContainer": "wordImagePasteErrorMessageContainer_1S0EbwaOlZ98zCQMmhSob6",
	"wordImagePasteErrorMessage": "wordImagePasteErrorMessage_1peTZ5_rY8xKv0qpy2CGZb",
	"editorHasFocus": "editorHasFocus_1QiJ-ko5GCWKjLk3rKwci_",
	"editorHasBlur": "editorHasBlur_2MxsEXnfQo_RFzF5klgik"
};