import { Helper } from "../../Helper";
import { IEnvironment } from "../../interfaces/IEnvironment";
import { IIconDropDown } from "../../interfaces/IIconDropDown";
import { IAPlayRequestFilters, IPlayArticle, IPlayer, IPlayerExtended, IPlayRssTickerWidget, IPlaySize, IPlaySlideExtended, IPlaySlideLayout, IPlayWidgetExtended, IPlayWidgetType } from "../../interfaces/IPlay";
import { Environment } from "../../services/Environment";
import { LocalizationService } from "../../services/LocalizationService";

export class PlayHelper {
  public static readonly DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
  public static readonly BYTES_PER_KILOBYTES = 1000;
  public static readonly DEFAULT_DURATION_SLIDES = 30;
  public static readonly MINIMUM_DURATION_SLIDES = 5;
  public static readonly ELEMENTS_PER_PAGE = 50;
  public static readonly NEW_ARTICLES_LAUNCH_DATE = new Date(2022, 7, 29);
  public static readonly BLURHASH_QUERY_PARAM = "blurHash";

  public static getReadingDurationOfText(text: string, minimunTime: number): number {
    if (text) {
      const words = text.match(/\b(\w+)\b/g);
      const readingTimeInSeconds = Math.floor((words.length / 150) * 60);
      return readingTimeInSeconds < minimunTime ? minimunTime : readingTimeInSeconds;
    } else {
      return minimunTime;
    }
  }

  public static getFontSize(size: IPlaySize, height: number): number {
    switch (size) {
      case "extraExtraSmall":
        return height / 80;
      case "extraSmall":
        return height / 70;
      case "small":
        return height / 60;
      case "standard":
        return height / 50;
      case "large":
        return height / 40;
      case "extraLarge":
        return height / 30;
      default:
        return height / 50;
    }
  }

  public static getLineHeight(size: IPlaySize, height: number): number {
    switch (size) {
      case "extraExtraSmall":
        return height / 70;
      case "extraSmall":
        return height / 60;
      case "small":
        return height / 50;
      case "standard":
        return height / 40;
      case "large":
        return height / 30;
      case "extraLarge":
        return height / 25;
      default:
        return height / 40;
    }
  }

  public static getIconForWidgetType(type: IPlayWidgetType): string {
    switch (type) {
      case "articleViewer":
        return "widget-articles";
      case "embed":
        return "widget-embed";
      case "eventViewer":
        return "widget-intraactive";
      case "gallery":
        return "widget-gallery";
      case "image":
        return "widget-image";
      case "messageViewer":
        return "widget-intraactive";
      case "rss":
        return "widget-rssfeed";
      case "rssTicker":
        return "widget-rssfeed";
      case "youtube":
        return "widget-youtube";
      case "video23":
        return "widget-video23";
      case "video":
        return "widget-video";
      case "vimeo":
        return "widget-vimeo";
      case "sharepointNewsViewer":
        return "widget-sharepoint-news";
      case "workplaceFeedViewer":
        return "widget-workplace-news";
      case "weather":
        return "widget-weather";
      case "instagram":
        return "widget-instagram";
      case "x":
        return "widget-x";
      case "facebookPageViewer":
        return "widget-facebook-news";
      case "poll":
        return "widget-poll";
      case "countdown":
        return "widget-countdown";
      case "counter":
        return "widget-counter";
      case "powerbi":
        return "widget-powerbi";
      case "relesys":
        return "widget-relesys";
    }
  }

  public static getTitleForWidgetType(type: IPlayWidgetType, localizationService: LocalizationService): string {
    switch (type) {
      case "video23":
        return localizationService.strings.PlayAdmin_WidgetTypevideo23;
      case "articleViewer":
        return localizationService.strings.PlayAdmin_WidgetTypeArticleViewer;
      case "drRss":
        return localizationService.strings.PlayAdmin_WidgetTypeDrRss;
      case "embed":
        return localizationService.strings.PlayAdmin_WidgetTypeEmbed;
      case "eventViewer":
        return localizationService.strings.PlayAdmin_WidgetTypeEventViewer;
      case "gallery":
        return localizationService.strings.PlayAdmin_WidgetTypeGallery;
      case "image":
        return localizationService.strings.PlayAdmin_WidgetTypeImage;
      case "messageViewer":
        return localizationService.strings.PlayAdmin_WidgetTypeMessageViewer;
      case "pdf":
        return localizationService.strings.PlayAdmin_WidgetTypePdf;
      case "powerpoint":
        return localizationService.strings.PlayAdmin_WidgetTypePowerpoint;
      case "rss":
        return localizationService.strings.PlayAdmin_WidgetTypeRss;
      case "rssTicker":
        return localizationService.strings.PlayAdmin_WidgetTypeRssTicker;
      case "youtube":
        return localizationService.strings.PlayAdmin_WidgetTypeYouTube;
      case "video":
        return localizationService.strings.PlayAdmin_WidgetTypeVideo;
      case "vimeo":
        return localizationService.strings.PlayAdmin_WidgetTypeVimeo;
      case "weather":
        return localizationService.strings.PlayAdmin_WidgetTypeWeather;
      case "poll":
        return localizationService.strings.PlayAdmin_WidgetTypePoll;
      case "x":
        return "X";
      case "instagram":
        return "Instagram";
      case "facebookPageViewer":
        return "Facebook";
      case "sharepointNewsViewer":
        return localizationService.strings.PlayAdmin_SharepointNewsWidget;
      case "workplaceFeedViewer":
        return localizationService.strings.PlayAdmin_Workplace;
      case "counter":
        return localizationService.strings.PlayAdmin_WidgetTypeCountdown;
      case "countdown":
        return localizationService.strings.PlayAdmin_WidgetTypeCountdownChristmas;
      case "powerbi":
        return "Power BI";
      case "relesys":
        return "Relesys";
    }
  }

  public static getAvailableWidgets(localizationService: LocalizationService, environment?: string, isConnectedToMessages?: boolean, isConnectedToEvents?: boolean, isConnectedToSharepointNews?: boolean, isConnectedToFacebook?: boolean, isConnectedToPowerbi?: boolean, isConnectedToRelesys?: boolean): IIconDropDown[] {
    environment === "Development" && console.log(environment);
    const list: IIconDropDown[] = [
      { value: "articleViewer", label: localizationService.strings.PlayAdmin_WidgetTypeArticleViewer, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg" },
      { value: "embed", label: localizationService.strings.PlayAdmin_WidgetTypeEmbed, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Code/SVG/ic_fluent_code_24_regular.svg" },
      { value: "gallery", label: localizationService.strings.PlayAdmin_WidgetTypeGallery, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Image%20Multiple/SVG/ic_fluent_image_multiple_48_regular.svg" },
      { value: "image", label: localizationService.strings.PlayAdmin_WidgetTypeImage, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Image/SVG/ic_fluent_image_48_regular.svg" },
      { value: "video", label: localizationService.strings.PlayAdmin_WidgetTypeVideo, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Video%20Clip/SVG/ic_fluent_video_clip_24_regular.svg" },
      // { value: "pdf", label: localizationService.strings.PlayAdmin_WidgetTypePdf, icon: "Widgets PDF" },
      // { value: "powerpoint", label: localizationService.strings.PlayAdmin_WidgetTypePowerpoint, icon: "Widgets Powerpoint" },
      { value: "rss", label: localizationService.strings.PlayAdmin_WidgetTypeRss, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/RSS/SVG/ic_fluent_rss_24_regular.svg" },
      { value: "youtube", label: localizationService.strings.PlayAdmin_WidgetTypeYouTube, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Video%20Clip/SVG/ic_fluent_video_clip_24_regular.svg" },
      { value: "vimeo", label: localizationService.strings.PlayAdmin_WidgetTypeVimeo, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Video%20Clip/SVG/ic_fluent_video_clip_24_regular.svg" },
      // { value: "poll", label: localizationService.strings.PlayAdmin_WidgetTypePoll, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Poll/SVG/ic_fluent_poll_24_regular.svg" },
      // { value: "video23", label: localizationService.strings.PlayAdmin_WidgetTypevideo23, icon: "Widgets Video23" },
      { value: "weather", label: localizationService.strings.PlayAdmin_WidgetTypeWeather, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Weather Partly Cloudy Day/SVG/ic_fluent_weather_partly_cloudy_day_48_regular.svg" },
      { value: "messageViewer", label: localizationService.strings.PlayAdmin_WidgetTypeMessageViewer, disabled: !isConnectedToMessages, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg" },
      { value: "eventViewer", label: localizationService.strings.PlayAdmin_WidgetTypeEventViewer, disabled: !isConnectedToEvents, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Calendar%20LTR/SVG/ic_fluent_calendar_ltr_48_regular.svg" },
      { value: "sharepointNewsViewer", label: "Sharepoint News", disabled: !isConnectedToSharepointNews, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg" },
      { value: "workplaceFeedViewer", label: "Workplace from Meta", iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg" },
      // { value: "instagram", label: "Instagram", iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_instagram.svg" },
      // { value: "x", label: "X", iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Dismiss/SVG/ic_fluent_dismiss_48_regular.svg" }
      { value: "countdown", label: localizationService.strings.PlayAdmin_WidgetTypeCountdownChristmas, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Shifts%20Day/SVG/ic_fluent_shifts_day_24_regular.svg" },
      { value: "counter", label: localizationService.strings.PlayAdmin_WidgetTypeCountdown, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Shifts%20Day/SVG/ic_fluent_shifts_day_24_regular.svg" },
      { value: "powerbi", label: "Power BI", disabled: !isConnectedToPowerbi, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/widget-powerbi.png" },
      { value: "relesys", label: "Relesys", disabled: !isConnectedToRelesys, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/widget-relesys.png" },
      { value: "facebookPageViewer", label: "Facebook", disabled: !isConnectedToFacebook, iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_facebook.svg" }
    ];

    list.sort((a, b) => {
      if (a.disabled) {
        return 1;
      }
      if (b.disabled) {
        return -1;
      }
      return 0;
    });
    return list;
  }

  public static getIntegrationTypes(localizationService: LocalizationService,): IIconDropDown[] {
    const integrations = [
      { value: "events", label: localizationService.strings.PlayAdmin_IntraActiveEvents, icon: "widget-intraactive", backgroundColor: "#abd96d" },
      { value: "messages", label: localizationService.strings.PlayAdmin_IntraActiveMessages, icon: "widget-intraactive", backgroundColor: "#045459" },
      { value: "sharepoint-news", label: "SharePoint News", icon: "widget-sharepoint-news", backgroundColor: "#e8e8e8" },
      { value: "workplace-feed", label: localizationService.strings.PlayAdmin_Workplace, icon: "widget-workplace-news", backgroundColor: "#e8e8e8" },
      { value: "powerbi", label: "PowerBI", icon: "widget-powerbi", backgroundColor: "#e8e8e8" },
      { value: "relesys", label: "Relesys", icon: "widget-relesys", backgroundColor: "#000000" },
      { value: "facebook-feed", label: "Facebook", icon: "widget-facebook", backgroundColor: "#e8e8e8" }
    ];
    return integrations;
  }

  public static getSortingOptions(localizationService: LocalizationService): IIconDropDown[] {
    return [
      {
        value: "modifiedDescending",
        icon: "Filter",
        label: localizationService.strings.PlayAdmin_FilterModifiedDescending
      },
      {
        value: "modifiedAscending",
        icon: "Filter",
        label: localizationService.strings.PlayAdmin_FilterModifiedAscending
      },
      {
        value: "titleAlphabeticalAscending",
        icon: "Filter",
        label: localizationService.strings.PlayAdmin_FilterTitleAlphabeticalAscending
      },
      {
        value: "titleAlphabeticalDescending",
        icon: "Filter",
        label: localizationService.strings.PlayAdmin_FilterTitleAlphabeticalDescending
      }
    ]
  }

  public static getState(localizationService: LocalizationService): IIconDropDown[] {
    return [
      {
        value: "active",
        iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Checkmark%20Circle/SVG/ic_fluent_checkmark_circle_48_filled.svg",
        label: localizationService.strings.ContentCreator_Active,
        iconColor: "#6ea63f"
      }, {
        value: "scheduled",
        iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg",
        label: localizationService.strings.ContentCreator_Scheduled,
        iconColor: "#fc8a00"
      }, {
        value: "expired",
        iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg",
        label: localizationService.strings.ContentCreator_Expired,
        iconColor: "gray"
      }
    ]
  }

  public static getViewTypes(area?: string): IIconDropDown[] {
    const viewTypes = [];
    viewTypes.push(
      {
        value: "list",
        icon: "List",
      }
    );
    viewTypes.push(
      {
        value: "card",
        icon: "Checkbox Unchecked",
      }
    );
    if (area === "players") {
      // viewTypes.push(
      //   {
      //     value: "map",
      //     icon: "Map",
      //   }
      // );
    }
    return viewTypes;
  }

  public static getUserSortingOptions(localizationService: LocalizationService): IIconDropDown[] {
    return [
      {
        value: "list",
        icon: "Contact",
        label: localizationService.strings.PlayAdmin_Mine
      },
      {
        value: "card",
        icon: "Star",
        label: localizationService.strings.PlayAdmin_MyFavorites
      }
    ]
  }

  public static getIconForSlideLayout(layout: string): string {
    switch (layout) {
      case "oneColumn":
        return "Layout Main One Column";
      case "twoColumns":
        return "Layout Main Split Vertical";
      case "rightSideTwoRows":
        return "Layout Main Three Column";
      case "leftAndRightSideTwoRows":
        return "Layout Main Four Column";
      case "portraitOneRow":
        return "Layout Portrait One Row";
      case "portraitTwoRows":
        return "Layout Portrait Two Rows";

    }
  }

  public static getPlayerState(player: IPlayer, localizationService: LocalizationService): any {
    let state;
    let running = player.lastSuccessfulRequest && new Date(player.lastSuccessfulRequest) > new Date(Date.now() - 1000 * 300);
    if (running) {
      state = {
        tooltip: running ? localizationService.strings.PlayAdmin_PlayerStateRunning : (player.lastSuccessfulRequest ? localizationService.strings.PlayAdmin_PlayerStateFailing : localizationService.strings.PlayAdmin_PlayerStateNew),
        iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Checkmark%20Circle/SVG/ic_fluent_checkmark_circle_48_filled.svg",
        backgroundColor: "transparent",
        textColor: "#6ea63f",
        borderRadius: 5
      }
    } else {
      if (player.lastSuccessfulRequest && player.playlistId) {
        state = {
          tooltip: localizationService.strings.PlayAdmin_PlayerStateFailing,
          iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Error%20Circle/SVG/ic_fluent_error_circle_24_filled.svg",
          backgroundColor: "transparent",
          textColor: "#ee4545",
          borderRadius: 5
        }
      } else {
        state = {
          tooltip: localizationService.strings.PlayAdmin_PlayerStateNew,
          iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Star/SVG/ic_fluent_star_48_filled.svg",
          backgroundColor: "transparent",
          textColor: "#fd5e1d",
          borderRadius: 5
        }
      }
    }
    return state;
  }

  public static getEmptySlide(): IPlaySlideExtended {
    return {
      title: "",
      duration: this.DEFAULT_DURATION_SLIDES,
      startDate: (new Date(Date.now())).toString(),
      endDate: null,
      layout: "oneColumn",
      showFooter: true,
      rssTicker: undefined,
      widgets: [],
      showRssTicker: false,
      playlistsToAddTo: [],
      playlistsToRemoveFrom: []
    };
  }

  public static getEmptyWidget(widgetType: IPlayWidgetType, localizationService: LocalizationService): IPlayWidgetExtended {
    let widget: IPlayWidgetExtended = {};
    switch (widgetType) {
      case "image":
        widget.type = "image";
        widget.image = {
          imageUrl: undefined,
          keepOriginal: undefined,
        };
        break;
      case "gallery":
        widget.type = "gallery";
        widget.gallery = {
          showTitle: false,
          showThumbnails: true,
          images: [],
          duration: 10,
          transition: "slide"
        };
        break;
      case "rssTicker":
        widget.type = "rssTicker";
        widget.rssTicker = {
          backgroundColor: "#ffffff",
          color: "#000000",
          labelBackgroundColor: "#000000",
          labelColor: "#ffffff",
          labelText: undefined
        };
        break;
      case "rss":
        widget.type = "rss";
        widget.rss = {
          url: "",
          duration: 30,
          maxItems: 50,
          transition: "slide",
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ],
          showDate: true,
          showImage: true,
          showQRLink: false,
          showText: true,
          showTime: true,
          showTitle: true,
          contentPosition: "left"
        };
        break;
      case "articleViewer":
        widget.id = "new";
        widget.type = "articleViewer";
        widget.articleViewer = {
          articles: [],
          duration: 30,
          transition: "slide",
          noContentImageUrl: localizationService.strings.Player_NoContentUrl
        };
        break;
      case "embed":
        widget.type = "embed";
        widget.embed = { url: undefined };
        break;
      case "pdf":
        widget.type = "pdf";
        widget.pdf = { url: undefined };
        break;
      case "video":
        widget.type = "video";
        widget.video = {
          videoUrl: undefined,
          duration: undefined,
          fitToView: false,
          showTitle: false
        };
        break;
      case "youtube":
        widget.type = "youtube";
        widget.video = {
          videoUrl: undefined,
          duration: undefined,
          fitToView: false,
          showTitle: false
        };
        break;
      case "video23":
        widget.type = "video23";
        widget.video = {
          videoUrl: undefined,
          duration: undefined,
          fitToView: false,
          showTitle: false
        };
        break;
      case "vimeo":
        widget.type = "vimeo";
        widget.video = {
          videoUrl: undefined,
          duration: undefined,
          fitToView: false,
          showTitle: false
        };
        break;
      case "messageViewer":
        widget.type = "messageViewer";
        widget.messageViewer = {
          messages: [],
          contentPosition: "left",
          amountToShow: 10,
          duration: 30,
          transition: "slide",
          showQRLink: true,
          showAuthor: true,
          showGallery: true,
          showDate: true,
          showImage: true,
          showText: true,
          showTitle: true,
          showVideo: true,
          showTime: true,
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ]
        };
        break;
      case "eventViewer":
        widget.type = "eventViewer";
        widget.eventViewer = {
          events: [],
          amountToShow: 10,
          timespand: "week",
          excludeToday: false,
          layoutType: "slide",
          textLines: 1,
          duration: 30,
          transition: "slide",
          showHeadline: true,
          showAuthor: true,
          showImage: true,
          showText: true,
          showTitle: true,
          showTime: true,
          showLocation: true,
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ],
          headlineColor: "#333333",
          backgroundColor: "#eeeeee",
          boxColor: "#ffffff",
          textColor: "#333333",
          borderColor: "transparent",
          timeBatchBackgroundColor: "#eeeeee",
          timeBatchTextColor: "#333333",
          timeBatchBorderColor: "transparent"
        };
        break;
      case "sharepointNewsViewer":
        widget.type = "sharepointNewsViewer";
        widget.sharepointNewsViewer = {
          news: [],
          amountToShow: 10,
          duration: 30,
          transition: "slide",
          showQRLink: false,
          showTime: false,
          showDate: true,
          showImage: true,
          showText: true,
          showTitle: true,
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ]
        }
        break;
      case "workplaceFeedViewer":
        widget.type = "workplaceFeedViewer";
        widget.workplaceFeedViewer = {
          feed: [],
          amountToShow: 10,
          duration: 30,
          transition: "slide",
          showQRLink: false,
          showTime: true,
          showDate: true,
          showImage: true,
          showText: true,
          showTitle: true,
          showAuthor: true,
          showProgressBar: true,
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ]
        }
        break;
      case "facebookPageViewer":
        widget.type = "facebookPageViewer";
        widget.facebookPageViewer = {
          feed: [],
          amountToShow: 10,
          duration: 30,
          transition: "slide",
          showQRLink: false,
          showTime: true,
          showDate: true,
          showImage: true,
          showText: true,
          showTitle: true,
          showAuthor: true,
          showProgressBar: true,
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ]
        }
        break;
      case "weather":
        widget.type = "weather";
        widget.weather = {
          colorTheme: {
            backgroundColor: "#f0f2f4",
            textColor: "#333333"
          },
          weatherType: "week"
        }
        break;
      case "countdown":
        widget.type = "countdown";
        widget.countdown = {
          title: new LocalizationService().strings.PlayAdmin_ChristmasCountdownDefaultTitle,
          imageUrl: undefined,
          colorTheme: {
            backgroundColor: "#e9363c",
            textColor: "#ffffff",
          },
          christmasSettings: {
            candleNumberColor: "#e9363c",
            candleColor: "white",
            showQRCode: true
          }
        }
        break;
      case "counter":
        widget.type = "counter";
        widget.counter = {
          title: undefined,
          imageUrl: undefined,
          backgroundColor: "#333333",
          color: "#ffffff",
          layout: "flip",
          colorTheme: {
            backgroundColor: "#ffffff",
            textColor: "#333333"
          },
          overlayColor: "#000000",
          overlayOpacity: 0.5,
          date: new Date(),
          countDirection: "up",
          showDays: true,
          showHours: true,
          showMinuts: true,
          showSeconds: true
        }
        break;
      case "instagram":
        widget.type = "instagram";
        widget.instagram = {
          duration: 30,
          maxItems: 50,
          transition: "slide",
          boxStyle: "solid",
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ],
          showDate: true,
          showImage: true,
          showQRLink: false,
          showText: true,
          showTime: true,
          showTitle: true,
          contentPosition: "left",
          layoutType: "slide"
        };
        break;
      case "facebookPageViewer":
        widget.type = "facebookPageViewer";
        break;
      case "x":
        widget.type = "x";
        break;
      case "poll":
        widget.type = "poll";
        break;
      case "countdown":
        widget.type = "countdown";
        break;
      case "counter":
        widget.type = "counter";
        break;
      case "powerbi":
        widget.type = "powerbi";
        widget.powerbi = {
          reportId: undefined,
          reportEmbedUrl: undefined,
          reportName: undefined,
          workspaceName: undefined,
          datasetId: undefined,
          accessToken: undefined,
          pageName: undefined
        }
        break;
      case "relesys":
        widget.type = "relesys";
        widget.relesys = {
          amountToShow: 5,
          contentModuleId: "",
          duration: 10,
          showTitle: true,
          showText: true,
          showTime: true,
          transition: "slide",
          relesysContent: [],
          boxStyle: "solid",
          enableScroll: true,
          boxStyleRoundCorner: true,
          contentWidth: 30,
          colorThemes: [
            {
              textColor: "#333333",
              backgroundColor: "#ffffff"
            }
          ],
        }
        break;
      default:
        break;
    }
    return widget;
  }

  public static getEmptyRssTicker(): IPlayRssTickerWidget {
    return {
      title: "New RSS ticker",
      backgroundColor: "#ffffff",
      color: "#000000",
      labelBackgroundColor: "#000000",
      labelColor: "#ffffff",
      labelText: undefined,
      url: "",
      position: "bottom"
    };
  }

  public static getEmptyArticle(): IPlayArticle {
    return {
      id: "new",
      margin: 4,
      contentWidth: 50,
      titleSize: "large",
      textSize: "small",
      startDate: (new Date(Date.now())).toString(),
      endDate: null,
      hideTitle: false,
      widgetsToAddTo: [],
      widgetsToRemoveFrom: [],
      boxStyle: "solid",
      contentPosition: "left",
      contentVerticalPosition: "top",
      colorTheme: {
        backgroundColor: "#ffffff",
        textColor: "#333333",
      },
      galleryImages: [],
      showDate: false,
      showTitle: true,
      imageUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp",
      title: "",
      textContent: [""],
      boxStyleRoundCorner: true,
      fontSize: "small"
    };
  }

  public static convertFiltersToURLParams(filters: IAPlayRequestFilters): URLSearchParams {
    return new URLSearchParams({
      skip: filters?.skip?.toString() ?? "",
      take: filters?.take?.toString() ?? "",
      search: filters?.search ?? "",
      excludeIds: filters?.excludeIds?.join(",") ?? "",
      showOnly: filters?.showOnly?.join(",") ?? "",
      countOnly: filters?.countOnly?.toString() ?? "",
      tags: filters?.tags?.join(",") ?? "",
      sortBy: filters?.sortBy ?? ""
    });
  }

  public static showNextPageButton(skip: number, take: number, totalCount: number): boolean {
    return (skip + take) - totalCount < 0;
  }

  public static showPreviousPageButton(skip: number): boolean {
    return skip > 0;
  }

  public static getCurrentPage(skip: number, take: number): number {
    return skip && take ? skip / take + 1 : 1;
  }

  public static getPageCount(take: number, totalCount: number): number {
    return take && totalCount ? Math.ceil(totalCount / take) : 1;
  }

  public static useCDN(url: string): string {
    if (url && url.includes("https://intraactiveplay.blob.core.windows.net")) {
      return url.replace("https://intraactiveplay.blob.core.windows.net", "https://files.intraactiveplay.net");
    }
    return url;
  }

  public static getNumberOfWidgetsForLayout(layout: IPlaySlideLayout): number {
    switch (layout) {
      case "oneColumn":
      case "portraitOneRow":
        return 1;
      case "twoColumns":
      case "portraitTwoRows":
        return 2;
      case "rightSideTwoRows":
        return 3;
      case "leftAndRightSideTwoRows":
        return 4;
      default:
        break;
    }
  }

  public static getSlidesWithActiveContent(slides: IPlaySlideExtended[]): IPlaySlideExtended[] {
    let slidesWithActiveContent = [];
    if (slides?.length > 0) {
      slides.forEach((slide: IPlaySlideExtended) => {
        if (this.hasSlideActiveContent(slide)) {
          slidesWithActiveContent.push(slide);
        }
      });
      return (slidesWithActiveContent);
    } else {
      return [];
    }
  }

  public static hasSlideActiveContent(slide: IPlaySlideExtended): boolean {
    let numberOfWidgetsWithInactiveContent = 0;
    if (slide?.widgets?.length > 0) {
      slide.widgets.forEach((widget: IPlayWidgetExtended) => {
        switch (widget.type) {
          case "articleViewer":
            if (widget.articleViewer?.articles?.length === 0) {
              numberOfWidgetsWithInactiveContent = numberOfWidgetsWithInactiveContent + 1;
            } else if (widget.articleViewer?.articles?.length > 0) {
              let numberOfArticlesWithScheduledContent = 0;
              widget.articleViewer?.articles.forEach((article: IPlayArticle) => {
                if (article.startDate && new Date(article.startDate) > new Date()) {
                  numberOfArticlesWithScheduledContent = numberOfArticlesWithScheduledContent + 1;
                }
              });
              if (numberOfArticlesWithScheduledContent === widget.articleViewer?.articles?.length) {
                numberOfWidgetsWithInactiveContent = numberOfWidgetsWithInactiveContent + 1;
              }
            }
            break;
          default:
            break;
        }
      });
    }
    return numberOfWidgetsWithInactiveContent !== PlayHelper.getNumberOfWidgetsForLayout(slide.layout) && (slide.startDate && new Date(slide.startDate) < new Date());
  }

  public static getStandardImageFormat(width: number, height: number): string {
    if (width > height && width > 2000) {
      return `2000x${Math.floor(height / (width / 2000))}`;
    } else if (height > width && height > 2000) {
      return `2000x${Math.floor(width / (height / 2000))}`;
    } else {
      return `${Math.floor(width)}x${Math.floor(height)}`;
    }
  }

  public static excludeImageFromApi(environment: IEnvironment, imageUrl: string): boolean {
    return imageUrl?.indexOf(Environment.getEnvironmentForImageAPI(environment)) !== -1 || imageUrl?.endsWith(".gif") || imageUrl?.startsWith("blob:");
  }

  public static getVideoFromIntranetApiIfVideoIsFromSharepoint(environment: IEnvironment, videoUrl: string): string {
    const spUrl = ".sharepoint.com";
    if (!!!videoUrl || !videoUrl.includes(spUrl)) {
      return videoUrl;
    }
    const tenantName = videoUrl.substring(0, videoUrl.indexOf(spUrl)).replace("https://", "");
    return `${Environment.getEnvironmentForImageAPI(environment)}/${tenantName}/video?token=00000000-0000-0000-0000-000000000000&url=${videoUrl}`;
  }

  public static getImageFromApi(environment: IEnvironment, tenantId: string, profileId: string, format: string, keepAspectRatio: boolean, imageUrl: string): string {
    const playApi = Environment.getEnvironmentForPlayApi(environment);
    const imageUrlEncoded = encodeURIComponent(imageUrl);
    return `${playApi}/${tenantId}/profiles/${profileId}/image/${format}?keepAspectRatio=${!!keepAspectRatio}&path=${imageUrlEncoded}`;
  }

  public static getPredefinedRssTickers(): IPlayRssTickerWidget[] {
    return [
      {
        id: "dr_seneste_nyt",
        title: "DR seneste nyt",
        backgroundColor: "#ffffff",
        color: "#000000",
        labelBackgroundColor: "#000000",
        labelColor: "#ffffff",
        labelLogo: "https://mydefence.dk/wp-content/uploads/2018/05/DR.png",
        labelText: null,
        maxItems: null,
        position: "bottom",
        showTime: true,
        url: "https://www.dr.dk/nyheder/service/feeds/senestenyt"
      },
      {
        id: "cnn_news",
        title: "CNN News",
        backgroundColor: "#ffffff",
        color: "#000000",
        labelBackgroundColor: "#ca0002",
        labelColor: "#ffffff",
        labelLogo: "https://sociable.co/wp-content/uploads/2010/10/cnn.png",
        labelText: null,
        maxItems: null,
        position: "bottom",
        showTime: true,
        url: "http://rss.cnn.com/rss/edition.rss"
      },
    ];
  }

  public static getTickerbyId(tickers: IPlayRssTickerWidget[], tickerId: string) {
    const allTickers = [...tickers, ...PlayHelper.getPredefinedRssTickers()];
    let rssTicker = allTickers.find(ticker => ticker.id === tickerId);
    return rssTicker;
  }

  public static getPlayerLanguageOptions(localizationService: LocalizationService): { key: string, value: string, disabled?: boolean }[] {
    // language keys are the web browser language codes: https://www.biswajeetsamal.com/blog/web-browser-language-identification-codes/
    return [
      {
        key: "da",
        value: localizationService.strings.PlayAdmin_PlayerLanguagesDanish
      },
      {
        key: "en",
        value: localizationService.strings.PlayAdmin_PlayerLanguagesEnglish
      },
      {
        key: "de",
        value: localizationService.strings.PlayAdmin_PlayerLanguagesGerman
      },
      {
        key: "no",
        value: localizationService.strings.PlayAdmin_PlayerLanguagesNorwegian
      },
      {
        key: "sv",
        value: localizationService.strings.PlayAdmin_PlayerLanguagesSwedish
      }
    ];
  }

  public static getLocalizerLanguageFromPlayerLanguage(language: string) {
    switch (language) {
      case "en":
        return "en-US";
      case "da":
        return "da-DK";
      case "sv":
        return "sv-SE";
      case "de":
        return "de-DE";
      case "no":
        return "nb-NO";
      default:
        return "en-US";
    }
  }

  public static getMomentJSLanguageCode(language: string): string {
    if (!language) return "en"
    else if (language === "no") return "nb"
    else return language;
  }

  public static isReplayKiosk(): boolean {
    // @ts-ignore
    return window.isReplayKiosk;
  }

  // pass message using the query parameter scheme:
  // key?argKey=argValue&argKey=argValue...
  public static sendMessageToWebView(key: string, args: { argKey: string, argValue: string }[] = []) {
    // @ts-ignore
    if (this.isReplayKiosk() && window.chrome?.webview) {
      let values = "";
      args.forEach((a, i) => {
        values += `${a.argKey}=${a.argValue}`;
        if (i < args.length - 1) {
          values += "&";
        }
      });
      // @ts-ignore
      window.chrome.webview.postMessage(`${key}?${values}`);
    }
  }

  public static getRandomPosition(): "left" | "right" {
    return Math.random() < 0.5 ? "left" : "right";
  }

  public static playerHasChanges(currentPlayer: IPlayerExtended, newPlayer: IPlayerExtended): boolean {
    // don't check for relesys base64 images when looking for changes
    const currentPlayerCopy = this.removeRelesysBase64ImagesAndPowerBiToken(currentPlayer);
    const newPlayerCopy = this.removeRelesysBase64ImagesAndPowerBiToken(newPlayer);

    if (newPlayerCopy?.playlist && (
      (JSON.stringify(newPlayerCopy?.playlist) !== JSON.stringify(currentPlayerCopy?.playlist))
      || newPlayerCopy?.playProfile && JSON.stringify(newPlayerCopy?.playProfile) !== JSON.stringify(currentPlayerCopy?.playProfile)
      || (JSON.stringify(newPlayerCopy?.language) !== JSON.stringify(currentPlayerCopy?.language))
      || (JSON.stringify(newPlayerCopy?.latitude) !== JSON.stringify(currentPlayerCopy?.latitude))
      || (JSON.stringify(newPlayerCopy?.longitude) !== JSON.stringify(currentPlayerCopy?.longitude))
      || (JSON.stringify(newPlayerCopy?.city) !== JSON.stringify(currentPlayerCopy?.city))
    )) {
      return true;
    }
    return false;
  }

  public static savePlayerInLocalStorage(player: IPlayerExtended): void {
    const playerCopy = this.removeRelesysBase64ImagesAndPowerBiToken(player);
    Helper.setLocalStorage("IA_player", JSON.stringify(playerCopy));
  }

  private static removeRelesysBase64ImagesAndPowerBiToken(player: IPlayerExtended): IPlayerExtended {
    const playerCopy: IPlayerExtended = JSON.parse(JSON.stringify(player));
    playerCopy?.playlist?.slides?.forEach(slide => {
      slide?.widgets?.forEach(widget => {
        if (widget.type === "relesys" && widget.relesys?.relesysContent?.length > 0) {
          widget.relesys.relesysContent?.forEach(content => {
            if (content?.medias[0].base64Image) {
              content.medias[0].base64Image = undefined;
            }
          });
        }

        if (widget.type === "powerbi" && widget.powerbi.accessToken) {
          widget.powerbi.accessToken = undefined;
        }
      });
    });

    return playerCopy;
  }
}