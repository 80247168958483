import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Slides.css';
import { LocalizationService } from '../../../../services/LocalizationService';
import { SlideEditPanel } from './slideEditPanel/SlideEditPanel';
import PlayService from '../../../../services/PlayService';
import { IAHeader } from '../../../header/Header';
import { PlayHelper } from '../../PlayHelper';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { SlidesContent } from './SlidesContent';
import { IPlaySlide } from '../../../../interfaces/IPlay';
import { GettingStartedOverlay } from '../gettingStartedOverlay/GettingStartedOverlay';
import { Helper } from '../../../../Helper';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  isLoading: boolean;
  selectedSlideId?: string;
  slideToDelete?: string;
  skip: number;
  take: number;
  searchQuery: string;
}

@inject('store')
@observer
export class Slides extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      selectedSlideId: undefined,
      skip: 0,
      take: PlayHelper.ELEMENTS_PER_PAGE,
      searchQuery: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.props.store.slides = undefined;
    this.getSlides(true);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton) {
      if (!this.props.store.slides) {
        this.getSlides(true);
      }
    }
  }

  private getSlides(override?: boolean): void {
    if (this.props.store.profile?.id) {
      this.setState({ isLoading: true });
      PlayService.getSlides(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, { skip: this.state.skip, take: this.state.take, search: this.state.searchQuery }).then(slides => {
        const appendedSlides = override ? [] : this.props.store.slides ?? [];
        appendedSlides.push(...slides.value);
        this.props.store.slides = appendedSlides;
        this.props.store.slidesCount = slides.count;
        this.setState({ isLoading: false });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deleteSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.slideToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedSlideId: undefined, slideToDelete: undefined, skip: 0 }, () => this.getSlides(true));
    });
  }

  private onSave(): void {
    this.props.store.workingOnIt = true;
    const slideModel: IPlaySlide = {
      ...this.props.store.slideToEdit,
      startDate: new Date(this.props.store.slideToEdit.startDate).toJSON(),
      endDate: this.props.store.slideToEdit.endDate ? new Date(this.props.store.slideToEdit.endDate).toJSON() : null,
      widgets: this.props.store.slideToEdit.widgets?.map(widget => widget.id),
      rssTicker: this.props.store.slideToEdit.rssTicker?.id,
    }
    if (this.props.store.slideToEdit.id) {
      PlayService.updateSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, slideModel).then(() => {
        console.log(`Updated slide with id: ${this.props.store.slideToEdit.id}`)
        this.props.store.slideToEdit = undefined;
        this.props.store.workingOnIt = false;
        this.setState({ selectedSlideId: undefined, skip: 0 }, () => this.getSlides(true));
      })
    }
    else {
      PlayService.createSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, slideModel).then((id) => {
        console.log(`Created slide with id: ${id}`)
        this.props.store.slideToEdit = undefined;
        this.props.store.workingOnIt = false;
        this.setState({ selectedSlideId: undefined, skip: 0 }, () => this.getSlides(true));
      })
    }
  }

  private removeGettingStarted(): void {
    Helper.setLocalStorage("IA_GettingStarted_Slides", "hide");
    this.forceUpdate();
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_slides}
      >
        {this.props.store.slides &&
          <SlideEditPanel
            isOpen={!!this.props.store.slideToEdit}
            slideId={this.state.selectedSlideId}
            showPlaylistSelector
            close={() => {
              this.props.store.slideToEdit = undefined;
              this.setState({ selectedSlideId: undefined });
            }}
            onSave={() => this.onSave()}
          />
        }
        <IAHeader
          id="play-slides-header"
          title={this.localizationService.strings.PlayAdmin_YourSlides}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#6b9ebb"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.removeGettingStarted();
              this.props.store.slideToEdit = PlayHelper.getEmptySlide();
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourSlides,
              text: this.localizationService.strings.PlayAdmin_SlidesDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-slides.png"
            }
          ]}
          onCollapseOrExpand={() => this.forceUpdate()}
        />
        <SlidesContent
          getSlides={(searchQuery) => {
            this.setState({ searchQuery }, () => {
              this.props.store.slides = undefined;
              this.getSlides(true);
            });
          }}
          nextPage={PlayHelper.showNextPageButton(this.state.skip, this.state.take, this.props.store.slidesCount)
            ? () => this.setState({ skip: this.state.skip + this.state.take }, () => {
              this.getSlides();
            })
            : undefined
          }
          isLoading={this.state.isLoading}
          elementActions={
            [
              // {
              //   action: () => alert("TODO"),
              //   actionName: "follow",
              //   icon: "Star",
              //   color: this.props.store.highlightColor
              // },
              {
                action: (id) => this.setState({ slideToDelete: id }),
                actionName: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              },
              {
                action: (id) => this.setState({ selectedSlideId: id }),
                actionName: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              },
            ]}
        />
        {!localStorage.getItem("IA_GettingStarted_Slides") &&
          <GettingStartedOverlay
            onHide={() => this.removeGettingStarted()}
            height={localStorage.getItem("play-slides-header") ? window.innerHeight - 210 : window.innerHeight - 390}
            top={localStorage.getItem("play-slides-header") ? 126 : 300}
            videoUrl={this.localizationService.strings.PlayAdmin_GettingStartedSlidesVideoUrl}
            posterUrl={this.localizationService.strings.PlayAdmin_GettingStartedSlidesPosterUrl}
            title={this.localizationService.strings.PlayAdmin_GettingStartedSlidesTitle?.toUpperCase()}
          />
        }
        {this.state.slideToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteSlide}
            close={() => this.setState({ slideToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.slideToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ slideToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }


}