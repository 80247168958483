exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_30WdjtRaFIuTJDYUnqKqOO {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_30WdjtRaFIuTJDYUnqKqOO .likes_1MwWh9dR8tCQ8RIoYMPFZJ {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_30WdjtRaFIuTJDYUnqKqOO .spinner_2YdKJv-FZMwNiAdoKt2d9U {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_11TflwiQwdsxdu-zDD5_rK 700ms linear infinite;\n  -moz-animation: spin_11TflwiQwdsxdu-zDD5_rK 700ms linear infinite;\n  animation: spin_11TflwiQwdsxdu-zDD5_rK 700ms linear infinite;\n}\n\n.likesAndComments_30WdjtRaFIuTJDYUnqKqOO .likesText_jS4BbgEiijypCtlbI5med {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_30WdjtRaFIuTJDYUnqKqOO .likesTextVertical_1Pp-zddaHE2QWQnHkVtdgF {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_30WdjtRaFIuTJDYUnqKqOO",
	"likes": "likes_1MwWh9dR8tCQ8RIoYMPFZJ",
	"spinner": "spinner_2YdKJv-FZMwNiAdoKt2d9U",
	"spin": "spin_11TflwiQwdsxdu-zDD5_rK",
	"likesText": "likesText_jS4BbgEiijypCtlbI5med",
	"likesTextVertical": "likesTextVertical_1Pp-zddaHE2QWQnHkVtdgF"
};