import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './PlayerEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IPlayer, IPlayerGroup, IPlaylist } from '../../../../../interfaces/IPlay';
import PlayService from '../../../../../services/PlayService';
import { Helper } from '../../../../../Helper';
import { PlaylistEditPanel } from '../../playlists/playlistEditPanel/PlaylistEditPanel';
import { PlayHelper } from '../../../PlayHelper';
import { IAButton } from '../../../../button/Button';
import { IADropDown } from '../../../../dropDown/DropDown';

export interface IProps {
  id: string;
  store?: Store;
  onClose: () => void;
}

export interface IState {
  isSaving: boolean;
  selectedArea: string;
  playlistOptions: any[];
  groupToAdd: string;
  language: string;
}

@inject('store')
@observer
export class PlayerEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSaving: false,
      selectedArea: "settings",
      playlistOptions: undefined,
      groupToAdd: undefined,
      language: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (this.props.id !== props.id && props.id != undefined) {
      if (this.props.store.players?.length > 0) {
        this.props.store.players.forEach((player: IPlayer) => {
          if (player.id === props.id) {
            this.setState({ language: player.language?.length !== 0 ? player.language : "en" });
            this.props.store.playerToEdit = player;
          }
        })
      }
      if (this.props.store.playlists) {
        this.loadPlaylistOptions();
      } else {
        this.getPlaylists();
      }
    }
  }

  private loadPlaylistOptions(): void {
    let playlistOptions = [
      {
        key: undefined,
        value: this.localizationService.strings.PlayAdmin_NoPlaylistSelected,
      }
    ];
    this.props.store.playlists.forEach((playlist: IPlaylist) => {
      playlistOptions.push(
        {
          key: playlist.id,
          value: playlist.title
        }
      );
    });
    this.setState({ playlistOptions });
    setTimeout(() => {
      this.forceUpdate();
    }, 100);
  }

  private getPlaylists() {
    PlayService.getPlaylists(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then(playlistResult => {
      let playerScreenFormat = "landscape";
      if (this.props.store.playerToEdit.height > this.props.store.playerToEdit.width) {
        playerScreenFormat = "portrait";
      }
      this.props.store.playlists = [];
      if (playlistResult?.value?.length > 0) {
        playlistResult.value.forEach((playlist: IPlaylist) => {
          console.log(playlist.screenFormat);
          if ((playerScreenFormat === playlist.screenFormat) || (playerScreenFormat === "landscape" && playlist.screenFormat == undefined)) {
            this.props.store.playlists.push(playlist);
          }
        });
      }
      this.props.store.playlistsCount = playlistResult.count;
      this.loadPlaylistOptions();
    })
  }

  private onPlaylistSave() {
    this.props.store.workingOnIt = true;
    const playlistModel: IPlaylist = {
      ...this.props.store.playlistToEdit,
      slides: this.props.store.playlistToEdit.slides?.map(slide => slide.id)
    }
    PlayService.createPlaylist(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, playlistModel).then(playlistId => {
      this.props.store.workingOnIt = false;
      this.props.store.playlistToEdit = undefined;
      this.props.store.playerToEdit = { ...this.props.store.playerToEdit, playlistId };
      this.getPlaylists();
      this.forceUpdate();
    })
  }

  public render(): JSX.Element {
    const groupOptions = [];
    const selectedGroups = [];
    if (this.props.store.groups?.length > 0) {
      this.props.store.groups.forEach((group: IPlayerGroup) => {
        let added = false;
        if (this.props.store.playerToEdit?.groups?.length > 0) {
          this.props.store.playerToEdit.groups.forEach((groupId: string) => {
            if (groupId === group.id) {
              added = true;
            }
          });
        }
        if (added) {
          selectedGroups.push(
            <div
              key={group.id}
              className={styles.IA_group}
              style={{
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              }}
              onClick={() => {
                this.props.store.playerToEdit.groups.splice(this.props.store.playerToEdit.groups.indexOf(group.id), 1);
                this.forceUpdate();
              }}
            >
              {group.title}
            </div>
          );
        } else {
          groupOptions.push(
            <div
              key={group.id}
              className={styles.IA_group}
              style={{
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
                opacity: 0.5
              }}
              onClick={() => {
                if (!this.props.store.playerToEdit?.groups) {
                  this.props.store.playerToEdit.groups = [];
                }
                this.props.store.playerToEdit.groups.push(group.id);
                this.forceUpdate();
              }}
            >
              {group.title}
            </div>
          );
        }
      });
    }
    let screenOrientation: "vertical" | "horizontal" = undefined;
    if (this.props.store.playerToEdit?.width && this.props.store.playerToEdit?.height) {
      screenOrientation = this.props.store.playerToEdit.width / this.props.store.playerToEdit.height > 1 ? "horizontal" : "vertical";
    }
    return (
      <IAPanel
        open={this.props.store.playerToEdit != undefined && !this.props.store.playlistToEdit}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={440}
        dataAutomationIdPrefix="players-edit-panel"
        panelId="players-edit-panel"
        navigationsBarContent={
          <IAButton
            label={this.props.store.playerToEdit?.id ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            disbaled={(!this.props.store.playerToEdit?.title || !this.props.store.playerToEdit?.playlistId)}
            onClick={() => {
              this.props.store.workingOnIt = true;
              if(!this.props.store.playerToEdit.language) this.props.store.playerToEdit.language = this.state.language;
              PlayService.updatePlayer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.playerToEdit).then(() => {
                this.props.store.workingOnIt = false;
                this.props.store.players.forEach((player: IPlayer) => {
                  if (player.id === this.props.store.playerToEdit.id) {
                    player = this.props.store.playerToEdit;
                  }
                })
                this.props.store.playerToEdit = undefined;
                this.props.onClose();
              });
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        darkMode={this.props.store.darkMode}
        close={() => {
          this.props.store.playerToEdit = undefined;
          this.props.onClose();
        }}
      >
        {
          <div>
            <PlaylistEditPanel
              playlistId={this.props.store.playlistToEdit?.id}
              onSave={() => this.onPlaylistSave()}
              onClose={() => {
                this.props.store.playlistToEdit = undefined;
              }}
            />
          </div>
        }
        {this.props.store.playerToEdit &&
          <div
            className={styles.IA_playerEditPanel}
            style={{
              height: window.innerHeight - 44,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content}>
              <h1>{this.localizationService.strings.PlayAdmin_PlayerSettings}</h1>
              {/* Orientation and screen size */}
              {screenOrientation && <div
                className={styles.IA_orientation_container}
                style={{
                  color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                }}
              >
                <div
                  className={styles.IA_orientation_screen}
                  style={{
                    height: screenOrientation === "horizontal" ? 85 : 150,
                    width: screenOrientation === "horizontal" ? 150 : 85,
                    borderColor: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333"
                  }}
                >
                  {this.props.store.playerToEdit.width} x {this.props.store.playerToEdit.height}
                </div>
                <div
                  className={styles.IA_orientation_screen_foot_vertical}
                  style={{
                    backgroundColor: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                    marginLeft: screenOrientation === "horizontal" ? 71 : 39
                  }}
                />
                <div
                  className={styles.IA_orientation_screen_foot_horizontal}
                  style={{
                    backgroundColor: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                    marginLeft: screenOrientation === "horizontal" ? 50 : 18
                  }}
                />
              </div>}
              {/* Title */}
              <IATextField
                label={this.localizationService.strings.PlayAdmin_PlayerTitle}
                required
                placeholder={this.localizationService.strings.PlayAdmin_PlayerTitlePlaceholder}
                text={this.props.store.playerToEdit.title}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                onChange={(title: string) => {
                  this.props.store.playerToEdit.title = title;
                  this.forceUpdate();
                }}
              />
              {/* Position */}
              <div
                style={{
                  float: "left",
                  clear: "both",
                  width: "100%",
                  marginTop: 20,
                  color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                  textTransform: "uppercase",
                  fontSize: 12
                }}
              >
                <label>{this.localizationService.strings.PlayAdmin_PlayerPosition}</label>
                <div
                  style={{
                    float: "left",
                    clear: "both",
                    width: "100%",
                  }}
                >
                  <IATextField
                    text={this.props.store.playerToEdit.latitude}
                    darkMode={this.props.store.darkMode}
                    highlightColor={this.props.store.highlightColor}
                    borderRadius={5}
                    labelStyle={{
                      textTransform: "uppercase",
                      fontSize: 12
                    }}
                    onChange={(latitude: string) => {
                      this.props.store.playerToEdit.latitude = latitude;
                    }}
                    style={{
                      position: "relative",
                      float: "left",
                      width: "calc(50% - 20px)",
                      marginTop: 5,
                    }}
                  />
                  <div style={{
                    position: "relative",
                    float: "left",
                    width: 40,
                    marginTop: 5,
                    textAlign: "center",
                    lineHeight: "45px"
                  }}>
                    /
                  </div>
                  <IATextField
                    text={this.props.store.playerToEdit.longitude}
                    darkMode={this.props.store.darkMode}
                    highlightColor={this.props.store.highlightColor}
                    borderRadius={5}
                    labelStyle={{
                      textTransform: "uppercase",
                      fontSize: 12
                    }}
                    onChange={(longitude: string) => {
                      this.props.store.playerToEdit.longitude = longitude;
                    }}
                    style={{
                      position: "relative",
                      float: "left",
                      width: "calc(50% - 20px)",
                      clear: "none",
                      marginTop: 5,
                    }}
                  />
                </div>
              </div>
              <IATextField
                label={this.localizationService.strings.PlayAdmin_PlayerCity}
                text={this.props.store.playerToEdit.city}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                onChange={(city: string) => {
                  this.props.store.playerToEdit.city = city;
                }}
              />
              <IADropDown
                label={this.localizationService.strings.PlayAdmin_PlayerLanguage}
                selectedOption={this.state.language}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                options={PlayHelper.getPlayerLanguageOptions(this.localizationService)}
                onChange={(language: string) => {
                  this.props.store.playerToEdit.language = language;
                  this.setState({ language });
                }}
                style={{
                  float: "left",
                  width: "100%"
                }}
              />
              <IADropDown
                label={this.localizationService.strings.PlayAdmin_SelectPlaylist}
                required
                selectedOption={this.props.store.playerToEdit.playlistId}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                options={this.state.playlistOptions}
                onChange={(playlistId: string) => {
                  this.props.store.playerToEdit.playlistId = playlistId;
                  this.forceUpdate();
                }}
                style={{
                  float: "left",
                  width: "100%"
                }}
              />
              <IAButton
                label={this.localizationService.strings.PlayAdmin_CreateNewPlaylist?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  this.props.store.playlistToEdit = {
                    id: "new",
                    screenFormat: "landscape",
                    slides: []
                  }
                }}
                showSpinner={this.props.store.workingOnIt}
                borderRadius={5}
                style={{
                  marginTop: 22,
                  float: "left",
                  position: "relative",
                }}
              />
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <div
                className={this.props.store.darkMode ? styles.IA_labelDarkMode : styles.IA_label}
              >
                {this.localizationService.strings.PlayAdmin_GroupsOptinal}
              </div>
              <div
                className={styles.IA_groups}
                style={{
                  color: this.props.store.darkMode ? "#6e6e6e" : "#333333"
                }}
              >
                {selectedGroups && selectedGroups.length > 0 ? selectedGroups : this.localizationService.strings.PlayAdmin_NoGroupsAdded}
              </div>
              <IATextField
                placeholder={`${this.localizationService.strings.PlayAdmin_AddGroup}...`}
                text={this.state.groupToAdd}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                style={{
                  marginTop: 0,
                  width: "calc(100% - 110px)"
                }}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                onChange={(groupToAdd: string) => {
                  this.setState({ groupToAdd: groupToAdd });
                }}
              />
              <IAButton
                label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  if (this.state.groupToAdd) {
                    this.props.store.workingOnIt = true;
                    let groupToAdd: IPlayerGroup = {
                      title: this.state.groupToAdd
                    }
                    PlayService.createGroup(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, groupToAdd).then((id: string) => {
                      groupToAdd.id = id;
                      if (!this.props.store.groups) {
                        this.props.store.groups = [];
                      }
                      this.props.store.groups.push(groupToAdd);
                      if (!this.props.store.playerToEdit?.groups) {
                        this.props.store.playerToEdit.groups = [];
                      }
                      this.props.store.playerToEdit.groups.push(id);
                      this.props.store.workingOnIt = false;
                      this.setState({ groupToAdd: undefined });
                      this.forceUpdate();
                    });
                  }
                }}
                borderRadius={5}
                showSpinner={this.props.store.workingOnIt}
                style={{
                  float: "left",
                  position: "relative",
                  marginLeft: 10,
                  width: 100,
                  marginTop: 10,
                  opacity: this.state.groupToAdd ? 1 : 0.5
                }}
              />
              <div className={styles.IA_groups}>
                {groupOptions}
              </div>
            </div>
            <div className={styles.IA_preview}>

            </div>
          </div>
        }
      </IAPanel>
    );
  }

  
}