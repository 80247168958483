import * as React from 'react';
import Moment from 'react-moment';
import { Helper } from '../../../../../Helper';
import DateTimeService from '../../../../../services/DateTimeService';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAIcon } from '../../../../icon/Icon';
import { PlayHelper } from '../../../../playAdmin/PlayHelper';
import * as styles from './RotatorItem.css';
import { Image } from '../../image/Image';
import { IEnvironment } from '../../../../../interfaces/IEnvironment';
import { IAReader } from '../../../../reader/Reader';
import { QRCode } from './QRCode/QRCode';
import { Video } from '../../video/Video';
import { IPlayBoxStyle } from '../../../../../interfaces/IPlay';

export interface IProps {
  title: string;
  showTitle: boolean;
  text: string;
  date?: string;
  author?: string;
  image: string;
  fontSize: "small" | "medium" | "large";
  video?: string; // Messages, Article
  gallery?: string[]; // Messages, Article
  link?: string; // Messages, Articles
  linkText?: string; // Articles
  startDate?: string; // Event
  endDate?: string; // Event
  isAllDayEvent?: boolean; // Event
  location?: string; // Event
  tag?: string;
  enableScroll?: boolean;
  showTableBorder?: boolean;

  sizeUnit: number;
  playerWidth: number;
  playerHeight: number;
  active: boolean;
  standalone: boolean;
  width: number;
  height: number;
  duration: number;
  headlineFont: string;
  contentFont: string;

  boxStyle: IPlayBoxStyle;
  boxStyleWidth?: number;
  boxStyleRoundCorner?: boolean;
  colorTheme: { textColor: string, backgroundColor: string };
  contentVerticalPosition?: "top" | "bottom";
  contentPosition?: "left" | "right" | "random";
  isVertical: boolean;
  isVerticalScreen: boolean;
  showTimeBox: boolean;
  showTime: boolean;
  showProgressBar?: boolean;
  keepOriginalImage?: boolean;
  excludeImageFromImageAPI?: boolean;

  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  language?: string;
  articleEditMode?: boolean;
  widgetTitleIsShowing?: boolean;
  headerImage?: string;
  headerTitle?: string;
  headerSubtitle?: string;
  imagesIsBase64?: boolean;

  nextRotatorItem: () => void;
  willRestart: () => void;
}

export interface IState {
  progressBar: number;
  progressBarDuration: number;
  scrollTop: number;
  scrollTopToBeSet: number;
  scrollDuration: number;
  messageTextHeight?: number;
  padding: number;
  duration?: number;
  ready: boolean;
  imageHasFailed?: boolean;
  contentPosition?: "left" | "right";
}

export class RotatorItem extends React.Component<IProps, IState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private localizationService: LocalizationService;
  private textAmountHasBeenAdjusted = false;

  private _isMounted: boolean;
  private _hasStartedScroll: boolean;
  private bylineMarginTop: number;
  private timeMarginTop: number;
  private locationMarginTop: number;
  private index: string;
  private HEADER_IMAGE_HEIGHT = 12;

  constructor(props: IProps) {
    super(props);
    this.state = {
      progressBar: 0,
      progressBarDuration: undefined,
      scrollTop: 0,
      scrollTopToBeSet: undefined,
      scrollDuration: undefined,
      padding: props.sizeUnit * 5,
      ready: false
    };
    this.index = Helper.getRandomStringKey();
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public componentDidMount(): void {
    this._isMounted = true;
    this._hasStartedScroll = false;
  }

  public componentWillReceiveProps(props: IProps): void {
    this.bylineMarginTop = this.props.sizeUnit * 1.4;
    this.timeMarginTop = this.props.sizeUnit * 2.5;
    this.locationMarginTop = this.props.startDate && this.props.showTime ? this.props.sizeUnit : this.props.sizeUnit * 2;
    if (props !== this.props) {
      if (props.sizeUnit !== this.props.sizeUnit) {
        this.setState({
          padding: props.sizeUnit * 5,
        }, () => this.startIfReady());
      }
      if (this.state.contentPosition == undefined || props.contentPosition !== "random") {
        this.setState({ contentPosition: props.contentPosition && (this.props.image != undefined || this.props.video != undefined || this.props.gallery != undefined) ? (props.contentPosition === "random" ? PlayHelper.getRandomPosition() : props.contentPosition) : "left" });
      }
    }
    if (props.active) {
      setTimeout(() => {
        this.startIfReady();
      }, 0);
    } else {
      this.resetMessage();
    }
  }

  private nextRotatorItem() {
    try {
      if (this.props.active) {
        if (this.props.standalone) {
          this.resetMessage()
          this.props.willRestart();
        } else {
          this.props.nextRotatorItem();
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private startIfReady() {
    try {
      const messageDiv = document.getElementById(`IA_ContentWrapper_${this.index}`);
      const headerImageDiv = document.getElementById(`IA_HeaderImage_${this.index}`);
      const tagDiv = document.getElementById(`IA_Tag_${this.index}`);
      const messageTitleDiv = document.getElementById(`IA_Title_${this.index}`);
      const bylineDiv = document.getElementById(`IA_Byline_${this.index}`);
      const progressBarDiv = document.getElementById(`IA_ProgressBar_${this.index}`);
      const messageTextDiv = document.getElementById(`IA_MessageTextContent_${this.index}`);
      const qrCodeDiv = document.getElementById(`IA_qrCodeDiv_${this.index}`);
      const qrCodeBlocksText = (this.props.isVertical || this.props.boxStyle === "fullSolid" || this.props.boxStyle === "fullTransparent" || (this.props.boxStyle === "solid" && (!this.props.image && !this.props.video && !this.props.gallery)));
      let height = this.props.isVertical && (this.props.image || this.props.video || this.props.gallery) && (this.props.boxStyle !== "fullSolid" && this.props.boxStyle !== "fullTransparent") ? (this.props.isVerticalScreen ? this.props.height * 0.6 : this.props.height * 0.5) : this.props.height;
      height = height - (this.state.padding * 2)
      if (headerImageDiv?.clientHeight) {
        height = height - headerImageDiv.clientHeight - (this.props.sizeUnit * 3); // Image height + marginButtom
      }
      if (tagDiv?.clientHeight) {
        height = height - tagDiv.clientHeight - (this.props.sizeUnit * 1.5); // Tag height + marginButtom
      }
      if (messageTitleDiv?.clientHeight) {
        height = height - messageTitleDiv.clientHeight; // Title height
      }
      if (bylineDiv?.clientHeight && this.bylineMarginTop) {
        height = height - bylineDiv.clientHeight - this.bylineMarginTop; // Byline height + marginTop
      }
      if (progressBarDiv?.clientHeight) {
        height = height - progressBarDiv.clientHeight - (this.props.sizeUnit * 5); // Progressbar height + margins
      }
      if (qrCodeDiv?.clientHeight && qrCodeBlocksText) {
        height = height - qrCodeDiv.clientHeight - this.props.sizeUnit; // QR code height;
      }
      if (messageDiv) {
        this.setState({ messageTextHeight: height });
      }
      if (!this.state.duration && !this.props.video) {
        const duration = this.props.boxStyle === "onlyMedia" ? 0 : this.getTextDuration();
        if (!this.props.video && this.props.active) {
          this.setState({ duration: Math.max(duration, this.props.duration) }, () => {
            console.log(`nextRotatorItem in ${this.state.duration} seconds`);
            setTimeout(() => {
              this.nextRotatorItem();
            }, this.state.duration * 1000);
          });
        }
      }
      if (messageDiv && messageTextDiv && this.state.messageTextHeight) {
        let availableHeight = messageDiv.clientHeight;
        if (qrCodeBlocksText) {
          availableHeight = availableHeight - (this.props.sizeUnit * 12);
        }
        let contentHeight = messageTextDiv.scrollHeight;
        if (this.state.duration) {
          if (!this.state.scrollTopToBeSet && !this._hasStartedScroll) {
            this.setState({
              scrollTopToBeSet: -(contentHeight - availableHeight + this.state.padding),
              scrollDuration: this.state.duration
            }, () => {
              this.startAnimations();
            });
          } else {
            this.startAnimations();
          }
        } else {
          this.setState({ ready: true });
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getTextDuration(): number {
    try {
      let duration = 0;
      if (this.props.title) {
        duration += PlayHelper.getReadingDurationOfText(this.props.title, 0);
      }
      if (this.props.text) {
        duration += PlayHelper.getReadingDurationOfText(Helper.stripHtml(this.props.text), 0);
      }
      return this.props.enableScroll ? duration : this.props.duration;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private startAnimations(): void {
    try {
      if (!this._isMounted) {
        return;
      }
      this.setState({ progressBar: 0, ready: true });
      setTimeout(() => {
        this.setState({ progressBarDuration: this.state.duration - 1, progressBar: (this.props.width / 4) });
      }, 0);
      if (this.props.enableScroll && this.state.scrollTopToBeSet < 0 && !this.props.articleEditMode) {
        this.setState({ scrollTop: this.state.scrollTopToBeSet, scrollDuration: this.getTextDuration() });
      }
      this._hasStartedScroll = true;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private resetMessage(): void {
    try {
      if (!this._isMounted) {
        return;
      }
      this.setState({ scrollDuration: 0.5, scrollTop: 0, progressBarDuration: 0, progressBar: 0, duration: undefined }, () => {
        if (this.props.standalone) {
          setTimeout(() => {
            this.startIfReady();
          }, 500);
        }
        this._hasStartedScroll = false;
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getGalleryStyleForIndex(index: number): { width: string, height: string } {
    try {
      const length = this.props.gallery.length >= 10 ? 9 : this.props.gallery.length;
      let style;
      switch (length) {
        case 1:
          style = {
            width: "100%",
            height: "100%",
          };
          break;
        case 2:
          style = {
            width: "100%",
            height: "50%",
          };
          break;
        case 3:
          if (index < 2) {
            style = {
              width: "50%",
              height: "50%",
            };
          } else {
            style = {
              width: "100%",
              height: "50%",
            };
          }
          break;
        case 4:
          style = {
            width: "50%",
            height: "50%",
          };
          break;
        case 5:
          if (index === 2) {
            style = {
              width: "100%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "50%",
              height: "33.33%",
            };
          }
          break
        case 6:
          style = {
            width: "50%",
            height: "33.33%",
          };
          break;
        case 7:
          if (index > 1 && index < 5) {
            style = {
              width: "33.33%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "50%",
              height: "33.33%",
            };
          }
          break;
        case 8:
          if (index > 2 && index < 5) {
            style = {
              width: "50%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "33.33%",
              height: "33.33%",
            };
          }
          break;
        case 9:
          style = {
            width: "33.33%",
            height: "33.33%",
          };
          break;
        default:
          break;
      }
      return style;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private formatText(): void {
    try {
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      if (contentBox) {
        const links = contentBox.getElementsByTagName("a");
        if (links?.length > 0) {
          for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
            links[linkIndex].style.color = this.props.colorTheme?.textColor;
            links[linkIndex].style.textDecoration = "none";
          }
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getMarginTop(): number {
    let textTopPosition;
    const qrCodeBlocksText = (this.props.isVertical || this.props.boxStyle === "fullSolid" || this.props.boxStyle === "fullTransparent" || (this.props.boxStyle === "solid" && (!this.props.image && !this.props.video && !this.props.gallery)));
    if ((this.props.image || this.props.video || this.props.gallery) && this.props.isVertical) {
      textTopPosition = 0;
    } else {
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      const messageDiv = document.getElementById(`IA_ContentWrapper_${this.index}`);
      const messageTextDiv = document.getElementById(`IA_MessageTextContent_${this.index}`);
      const messageTitleDiv = document.getElementById(`IA_Title_${this.index}`);
      const bylineDiv = document.getElementById(`IA_Byline_${this.index}`);
      const timeDiv = document.getElementById(`IA_Time_${this.index}`);
      const locationDiv = document.getElementById(`IA_Location_${this.index}`);
      const byLineHeight = bylineDiv && bylineDiv.clientHeight && this.bylineMarginTop ? bylineDiv.clientHeight + this.bylineMarginTop : 0;
      const timeHeight = timeDiv && this.timeMarginTop ? timeDiv.clientHeight + this.timeMarginTop : 0;
      const locationHeight = locationDiv && this.props.startDate && this.locationMarginTop && this.timeMarginTop ? locationDiv.clientHeight + (this.props.startDate ? this.locationMarginTop : this.timeMarginTop) : 0;
      const progressBarHeight = (this.props.sizeUnit * 2.5) + (this.props.sizeUnit * 2.5) + (this.props.sizeUnit * 0.5);
      const tagHeight = this.props.tag && this.props.tag !== "" ? this.props.sizeUnit * 5 : 0;
      const titleHeight = messageTitleDiv ? messageTitleDiv.clientHeight : 0;
      const headerImageHeight = this.props.headerImage ? this.props.sizeUnit * this.HEADER_IMAGE_HEIGHT : 0;
      const QRCodeHeight = this.props.link && qrCodeBlocksText ? this.props.sizeUnit * 12 : 0;
      if (messageDiv && messageTextDiv) {
        let combinedContentHeight = messageTextDiv.clientHeight + titleHeight + progressBarHeight + byLineHeight + timeHeight + locationHeight + tagHeight + headerImageHeight + QRCodeHeight;
        if (this.props.isVertical && this.props.link) {
          combinedContentHeight = combinedContentHeight + (this.props.sizeUnit * 3.3) + (this.props.sizeUnit * 8.3);
        }
        if (contentBox && combinedContentHeight < (contentBox.clientHeight - (this.state.padding * 2))) {
          textTopPosition = (contentBox.clientHeight - combinedContentHeight - (this.state.padding * 2)) / 2;
        }
      }
    }
    return textTopPosition;
  }

  private getFontSize(relativeSize: number): string {
    return `${this.props.sizeUnit * relativeSize * this.getFontSizeFactor()}px`;
  }

  private getFontSizeFactor(): number {
    let factor = 1;
    switch (this.props.fontSize) {
      case "small":
        factor = 1;
        break;
      case "medium":
        factor = 1.25;
        break;
      case "large":
        factor = 1.5;
        break;
      default:
        factor = 1;
        break;
    }
    return factor;
  }

  public render(): JSX.Element {

    // Properties
    let contentBoxWidth = (this.props.image || this.props.video || this.props.gallery) ? "50%" : "100%";
    let left: string | number = 0;
    let right: string | number = 0;
    let contentBoxLeftPadding: any = this.state.padding;
    let contentBoxRightPadding: any = this.state.padding;
    let background;
    let top: string | number = 0;
    let bottom: string | number = "auto";
    let imageWidth;
    let imageLeft;
    let imageHeight = "100%";
    let borderRadius: string | number = 0;
    let boxStyle = this.props.boxStyle;
    let contentBoxHeight;
    let isBoxStyle;
    let title = [];
    const galleryImages = [];
    let text: string;
    let contentWrapperHeight: string | number;
    let marginLeft = 0;
    let marginTop = 0;
    let areaHeight = this.props.height;
    let areaWidth = this.props.width;
    const imageContainer = document.getElementById(`IA_image_${this.index}`);

    try {

      // Layout
      switch (boxStyle) {
        case "solid":
          background = this.props.colorTheme?.backgroundColor;
          left = this.state.contentPosition === "left" || this.props.isVertical ? 0 : "50%";
          break;
        case "transparent":
          background = this.props.colorTheme?.backgroundColor + "db";
          left = this.state.contentPosition === "left" || this.props.isVertical ? 0 : "50%";
          break;
        case "hidden":
          background = "transparent";
          left = this.state.contentPosition === "left" || this.props.isVertical ? 0 : "50%";
          break;
        case "gradient":
          contentBoxLeftPadding = this.state.padding;
          contentBoxRightPadding = (this.props.width / 10);
          left = this.state.contentPosition === "left" || this.props.isVertical ? 0 : "50%";
          contentBoxWidth = "60%";
          background = `linear-gradient(${this.props.isVertical ? "180deg" : "-90deg"}, ${this.props.colorTheme?.backgroundColor}00 0%, ${this.props.colorTheme?.backgroundColor}ba 20%,${this.props.colorTheme?.backgroundColor} 100%)`;
          if (this.state.contentPosition === "right" && !this.props.isVertical) {
            background = `linear-gradient( 90deg, ${this.props.colorTheme?.backgroundColor}00 0%, ${this.props.colorTheme?.backgroundColor}ba 18%,${this.props.colorTheme?.backgroundColor} 100%)`;
            contentBoxLeftPadding = `calc(10% + ${this.state.padding}px)`;
            contentBoxRightPadding = this.state.padding;
            left = "40%";
          }
          break;
        case "fullTransparent":
          contentBoxWidth = "100%";
          left = 0;
          if (this.props.gallery?.length > 0) {
            contentBoxLeftPadding = this.state.padding;
            contentBoxRightPadding = `calc(50% + ${this.state.padding}px)`;
          } else {
            // make text full when there is only text
            contentBoxLeftPadding = this.state.padding;
          }
          background = this.props.colorTheme?.backgroundColor + "db";
          break;
        case "fullSolid":
          contentBoxWidth = "100%";
          left = 0;
          background = this.props.colorTheme?.backgroundColor;
          contentBoxLeftPadding = this.state.padding;
          break;
        case "box":
          contentBoxWidth = `calc(${this.props.boxStyleWidth ?? 40}% - ${this.props.sizeUnit * 8}px)`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "auto";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "auto";
          contentBoxLeftPadding = this.state.padding;
          background = this.props.colorTheme?.backgroundColor;
          break;
        case "boxTransparent":
          contentBoxWidth = `calc(${this.props.boxStyleWidth ?? 40}% - ${this.props.sizeUnit * 8}px)`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "unset";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "unset";
          contentBoxLeftPadding = this.state.padding;
          background = this.props.colorTheme?.backgroundColor + "db";
          break;
        case "boxHidden":
          contentBoxWidth = `calc(${this.props.boxStyleWidth ?? 40}% - ${this.props.sizeUnit * 8}px)`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "unset";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "unset";
          contentBoxLeftPadding = this.state.padding;
          background = "transparent";
          break;
      }
      isBoxStyle = boxStyle === "box" || boxStyle === "boxTransparent" || boxStyle === "boxHidden";
      if (isBoxStyle) {
        if (this.props.contentVerticalPosition === "top") {
          top = this.props.sizeUnit * 4;
          bottom = "unset";
        } else {
          top = "unset";
          bottom = this.props.sizeUnit * 4;
        }
      }
      contentBoxHeight = isBoxStyle ? "auto" : "100%";

      // Gallery
      if (this.props.gallery?.length > 0) {
        this.props.gallery.forEach((image: string, index: number) => {
          const style = this.getGalleryStyleForIndex(index);
          if (style?.height && style?.width) {
            galleryImages.push(
              <div
                style={{
                  float: "left",
                  ...style
                }}
              >
                <Image
                  imageUrl={image}
                  height={style.height}
                  width={style.width}
                  environment={this.props.environment}
                  profileId={this.props.profileId}
                  tenantId={this.props.tenantId}
                  isActive={this.props.active}
                  backgroundColor={this.props.colorTheme?.backgroundColor}
                />
              </div>
            );
          }
        });
      }

      // Title
      const titleText: string = this.props.title == undefined ? undefined : this.props.title;
      if (titleText?.includes("<br>")) {
        const titleLines = titleText.split("<br>");
        titleLines.forEach((titleLine: string) => {
          title.push(<div>{titleLine}</div>);
        })
      } else {
        title.push(<div>{titleText}</div>);
      }

      // Text
      this.formatText();
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      if (contentBox) {
        const paragraphs: any = contentBox.getElementsByTagName('p');
        if (paragraphs) {
          for (let paragraph of paragraphs as HTMLParagraphElement[]) {
            if (paragraph.parentElement.classList.contains("ql-editor")) continue;
            paragraph.style.fontSize = this.getFontSize(3.3);
            paragraph.style.lineHeight = this.getFontSize(5);
            paragraph.style.color = this.props.colorTheme?.textColor;
          }
        }
        const h1s: any = contentBox.getElementsByTagName('h1');
        if (h1s) {
          for (let h1 of h1s as any[]) {
            h1.style.fontSize = this.getFontSize(3.3);
            h1.style.lineHeight = this.getFontSize(5);
            h1.style.color = this.props.colorTheme?.textColor;
          }
        }
        const h2s: any = contentBox.getElementsByTagName('h2');
        if (h1s) {
          for (let h2 of h2s as any[]) {
            h2.style.fontSize = this.getFontSize(3.3);
            h2.style.lineHeight = this.getFontSize(5);
            h2.style.color = this.props.colorTheme?.textColor;
          }
        }
        const h3s: any = contentBox.getElementsByTagName('h3');
        if (h3s) {
          for (let h3 of h3s as any[]) {
            h3.style.fontSize = this.getFontSize(3.3);
            h3.style.lineHeight = this.getFontSize(5);
            h3.style.color = this.props.colorTheme?.textColor;
          }
        }
        const uls: any = contentBox.getElementsByTagName('ul');
        if (uls) {
          for (let ul of uls as any[]) {
            ul.style.fontSize = this.getFontSize(3.3);
            ul.style.lineHeight = this.getFontSize(5);
            ul.style.marginTop = `0px`;
            ul.style.marginBottom = `10px`;
            ul.style.color = this.props.colorTheme?.textColor;
          }
        }
        const ols: any = contentBox.getElementsByTagName('ol');
        if (ols) {
          for (let ol of ols as any[]) {
            ol.style.fontSze = this.getFontSize(3.3);
            ol.style.lineHeight = this.getFontSize(5);
            ol.style.marginTop = `0px`;
            ol.style.marginBottom = `10px`;
            ol.style.color = this.props.colorTheme?.textColor;
          }
        }
      }

      // Scroll disabled
      const contentWrapper = document.getElementById(`IA_ContentWrapper_${this.index}`);
      const messageTextDiv = document.getElementById(`IA_MessageTextContent_${this.index}`);
      let contentHeight = 0;
      let firstOverflowingElement;
      let totalHeightOfVisibleElements = 0;
      let maxHeight;
      if (!this.textAmountHasBeenAdjusted && contentWrapper?.style?.height && this.props.sizeUnit && !this.props.enableScroll && messageTextDiv && messageTextDiv.children?.length > 0 && messageTextDiv.children[0].children?.length > 0 && contentWrapper && this.props.active) {
        for (let element = 0; element < messageTextDiv.children[0].children.length; element++) {
          contentHeight += (messageTextDiv.children[0].children[element].clientHeight + 10);
          maxHeight = contentWrapper.clientHeight;
          if (contentHeight > maxHeight) {
            let overflowingElement: any = messageTextDiv.children[0].children[element];
            if (firstOverflowingElement == undefined) {
              firstOverflowingElement = overflowingElement;
              firstOverflowingElement.textContent = Helper.stripHtml(firstOverflowingElement?.textContent);
              const heightAvailable = maxHeight - totalHeightOfVisibleElements;
              console.log("totalHeightOfVisibleElements: " + totalHeightOfVisibleElements);
              const linesAvailable = Math.floor(heightAvailable / (this.props.sizeUnit * 5 * this.getFontSizeFactor())); // lineheight
              this.textAmountHasBeenAdjusted = true;
              if (linesAvailable > 0) {
                let styleElement: React.CSSProperties = firstOverflowingElement.style;
                styleElement.WebkitLineClamp = linesAvailable;
                styleElement.overflow = "hidden";
                styleElement.textOverflow = "ellipsis";
                styleElement.display = "-webkit-box";
                styleElement.WebkitBoxOrient = "vertical";
              } else {
                firstOverflowingElement.style.opacity = 0;
                if (element > 0) {
                  let lastVisibleElement: any = messageTextDiv.children[0].children[element - 1];
                  lastVisibleElement.textContent = Helper.stripHtml(lastVisibleElement?.textContent);
                  lastVisibleElement.textContent = lastVisibleElement.textContent + " (...)";
                }
              }
            } else {
              overflowingElement.style.opacity = 0;
            }
          } else {
            let visibleElement: any = messageTextDiv.children[0].children[element];
            if (visibleElement) {
              totalHeightOfVisibleElements += (visibleElement.clientHeight + 10);
            }
          }
        }
      }

      // Scroll enabled
      text = this.props.text ? this.props.text : "";
      contentWrapperHeight = isBoxStyle ? "auto" : this.state.messageTextHeight;
      const qrCodeBlocksText = this.props.link && (this.props.isVertical || this.props.boxStyle === "fullSolid" || this.props.boxStyle === "fullTransparent" || (this.props.boxStyle === "solid" && (!this.props.image && !this.props.video && !this.props.gallery)));
      if (this.props.enableScroll && contentWrapperHeight && typeof contentWrapperHeight === "number") {
        contentWrapperHeight += this.state.padding;
        if (qrCodeBlocksText) {
          if (contentWrapperHeight < this.props.sizeUnit * 16) {
            text = "";
          }
          contentWrapperHeight += (this.props.sizeUnit * 17);
        }
      }

      // Image
      if (this.props.isVertical) {
        imageLeft = 0;
      } else {
        imageLeft = boxStyle === "solid" && this.state.contentPosition === "left" ? "50%" : 0;
      }
      if (isBoxStyle && this.props.boxStyleRoundCorner && document.getElementById(`IA_ContentBox_${this.index}`)) {
        const boxView = document.getElementById(`IA_ContentBox_${this.index}`);
        const width = boxView.clientWidth;
        const height = boxView.clientHeight;
        borderRadius = Math.max(width, height) * 0.015
      }
      imageWidth = boxStyle === "solid" ? "50%" : "100%";
      // Vertical view
      if (this.props.isVertical && (this.props.image || this.props.video || this.props.gallery) && !isBoxStyle && this.props.boxStyle !== "fullTransparent" && this.props.boxStyle !== "fullSolid") {
        imageWidth = "100%";
        contentBoxWidth = "100%";
        contentBoxHeight = this.props.isVerticalScreen ? "60%" : "50%";
        top = this.props.isVerticalScreen ? "40%" : "50%";;
        imageHeight = boxStyle === "solid" ? (this.props.isVerticalScreen ? "40%" : "50%") : "100%";
      }
      if (this.props.boxStyle !== "postcard") {
        if (imageContainer) {
          areaHeight = imageContainer.clientHeight;
          areaWidth = imageContainer.clientWidth;
          if (this.props.active) {
            if (!this.props.keepOriginalImage) {
              const newAreaWidth = (areaHeight / 9) * 16;
              if (newAreaWidth < areaWidth) {
                const newAreaHeight = areaWidth / 16 * 9
                marginTop = (areaHeight - newAreaHeight) / 2;
                areaHeight = newAreaHeight;
              } else {
                marginLeft = (areaWidth - newAreaWidth) / 2;
                areaWidth = newAreaWidth;
              }
            }
          }
        } else {
          setTimeout(() => {
            this.forceUpdate();
          }, 500);
        }
      }

    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }

    return this.state.contentPosition ? (
      <div
        className={styles.IA_message}
        style={{
          backgroundColor: this.props.colorTheme?.backgroundColor,
          fontFamily: this.props.contentFont
        }}
      >
        <div
          className={styles.IA_image}
          id={`IA_image_${this.index}`}
          style={{
            width: this.props.keepOriginalImage ? (boxStyle === "onlyMedia" ? "100%" : (boxStyle === "solid" && this.props.isVertical ? `calc(${imageWidth} - ${this.state.padding * 2}px` : `calc(${imageWidth} - ${this.state.padding}px`)) : imageWidth,
            height: this.props.keepOriginalImage ? (boxStyle === "onlyMedia" ? "100%" : `calc(${imageHeight} - ${this.state.padding * 2}px`) : imageHeight,
            left: boxStyle === "onlyMedia" ? 0 : (imageLeft === 0 && this.props.keepOriginalImage ? this.state.padding : imageLeft),
            top: boxStyle === "onlyMedia" ? 0 : (this.props.keepOriginalImage ? this.state.padding : 0)
          }}
        >
          {this.props.video == undefined && this.props.gallery == undefined && this.props.image &&
            <Image
              imageUrl={this.props.image}
              height="100%"
              width="100%"
              environment={this.props.environment}
              profileId={this.props.profileId}
              tenantId={this.props.tenantId}
              boxStyle={boxStyle}
              keepOriginal={this.props.keepOriginalImage}
              isActive={this.props.active}
              excludeFromImageAPI={this.props.excludeImageFromImageAPI}
              backgroundColor={this.props.colorTheme?.backgroundColor}
              imagesIsBase64={this.props.imagesIsBase64}
              onError={() => {
                console.log(`${this.props.image} failed loading`);
                this.setState({ imageHasFailed: true });
              }}
              onLoad={() => {
                this.setState({ imageHasFailed: false });
              }}
            />
          }
          {this.props.gallery && this.props.video == undefined &&
            galleryImages
          }
          {this.props.video && this.props.active && imageContainer &&
            <Video
              type={"video"}
              videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.environment, this.props.video)}
              width={areaWidth}
              height={areaHeight}
              marginLeft={marginLeft}
              marginTop={marginTop}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              showTitle={false}
              title={undefined}
              environment={this.props.environment}
              profileId={this.props.profileId}
              tenantId={this.props.tenantId}
              willRestart={() => { }}
              onDuration={(duration: number) => {
                const textDuration = this.getTextDuration();
                if (duration > textDuration) {
                  this.setState({ duration });
                } else {
                  this.setState({ duration: textDuration });
                }
                this.startIfReady();
                console.log(`nextRotatorItem in ${this.state.duration} seconds when video ends`);
                setTimeout(() => {
                  this.nextRotatorItem();
                }, this.state.duration * 1000);
              }}
            />
          }
        </div>
        <div
          className={styles.IA_contentBox}
          id={`IA_ContentBox_${this.index}`}
          style={{
            color: this.props.colorTheme?.textColor,
            background: background,
            width: contentBoxWidth,
            height: contentBoxHeight,
            top: top,
            bottom: bottom,
            left: left,
            right: right,
            padding: this.state.padding,
            paddingLeft: contentBoxLeftPadding,
            paddingRight: contentBoxRightPadding,
            zIndex: boxStyle === 'solid' ? 0 : 1,
            maxHeight: isBoxStyle ? `calc(100% - ${this.props.sizeUnit * 8}px)` : "100%",
            overflow: "hidden",
            borderRadius: borderRadius,
            opacity: this.props.boxStyle === "onlyMedia" ? 0 : 1, // remove text when onlyMedia is set
          }}
        >
          {this.props.headerImage &&
            <div
              id={`IA_HeaderImage_${this.index}`}
              style={{
                position: "relative",
                float: "left",
                height: this.props.sizeUnit * (this.HEADER_IMAGE_HEIGHT - 3),
                width: "100%",
                marginBottom: this.props.sizeUnit * 3,
                marginTop: this.getMarginTop()
              }}>
              <Image
                imageUrl={this.props.headerImage}
                height="100%"
                width="100%"
                environment={this.props.environment}
                profileId={this.props.profileId}
                tenantId={this.props.tenantId}
                boxStyle={boxStyle}
                keepOriginal
                imageStyle={{
                  objectPosition: "left"
                }}
                isActive={this.props.active}
                excludeFromImageAPI={this.props.excludeImageFromImageAPI}
                backgroundColor={"transparent"}
                onError={() => {
                  console.log(`${this.props.image} failed loading`);
                  this.setState({ imageHasFailed: true });
                }}
                onLoad={() => {
                  this.setState({ imageHasFailed: false });
                }}
              />
            </div>
          }
          <div>
            {this.props.tag && this.props.tag !== "" &&
              <div
                className={styles.IA_tag}
                id={`IA_Tag_${this.index}`}
                style={{
                  background: `${this.props.colorTheme?.textColor}1c`,
                  color: this.props.colorTheme?.textColor,
                  fontSize: this.getFontSize(1.7),
                  padding: `${this.props.sizeUnit * 0.8}px ${this.props.sizeUnit * 1.2}px`,
                  borderRadius: this.props.sizeUnit,
                  marginTop: isBoxStyle || this.props.headerImage ? 0 : this.getMarginTop(),
                  marginBottom: this.props.sizeUnit * 1.5
                }}
              >
                {this.props.tag}
              </div>
            }
            <div
              id={`IA_Title_${this.index}`}
              className={styles.IA_title}
              style={{
                fontSize: this.getFontSize(4.6),
                marginTop: isBoxStyle || this.props.headerImage || (this.props.tag != undefined && this.props.tag !== "") ? 0 : this.getMarginTop(),
                opacity: !this.props.title && this.props.articleEditMode ? 0.3 : 1,
                height: this.props.showTitle ? "auto" : 0,
                fontFamily: this.props.headlineFont
              }}
            >
              {this.props.showTitle ? (this.props.articleEditMode ? (this.props.title === "" ? this.localizationService.strings.PlayAdmin_MissionTitle : title) : title) : ""}
            </div>
            {(this.props.author || this.props.date) &&
              <div
                id={`IA_Byline_${this.index}`}
                className={styles.IA_byline}
                style={{
                  fontSize: this.getFontSize(1.7),
                  marginTop: this.bylineMarginTop
                }}
              >
                {this.props.author &&
                  <span style={{ paddingRight: this.props.sizeUnit * 1.7 }}>{this.props.author}</span>
                }
                {this.props.date &&
                  <>
                    {this.props.author &&
                      <span style={{ paddingRight: this.props.sizeUnit * 1.7, opacity: 0.5 }}>|</span>
                    }
                    <Moment style={{ opacity: 0.5 }} fromNow locale={PlayHelper.getMomentJSLanguageCode(this.props.language)}>{this.props.date}</Moment>
                  </>
                }
              </div>
            }
            {this.props.startDate && this.props.showTime &&
              <div
                id={`IA_Time_${this.index}`}
                className={styles.IA_byline}
                style={{
                  fontSize: this.getFontSize(2.2),
                  marginTop: this.timeMarginTop
                }}
              >
                <IAIcon
                  title="Calendar"
                  size={this.props.sizeUnit * 3.3}
                  color={this.props.colorTheme?.textColor}
                  style={{
                    float: "left"
                  }}
                />
                <div style={{
                  float: "left",
                  marginLeft: this.props.sizeUnit
                }}>
                  {this.props.isAllDayEvent ? this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) : `${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.startDate)} - ${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.endDate)}`}
                </div>
              </div>
            }
            {this.props.location &&
              <div
                id={`IA_Location_${this.index}`}
                className={styles.IA_byline}
                style={{
                  fontSize: this.getFontSize(2.2),
                  marginTop: this.props.startDate ? this.locationMarginTop : this.timeMarginTop
                }}
              >
                <IAIcon
                  title="Address"
                  size={this.props.sizeUnit * 3.3}
                  color={this.props.colorTheme?.textColor}
                  style={{
                    float: "left"
                  }}
                />
                <div style={{
                  float: "left",
                  marginLeft: this.props.sizeUnit
                }}>
                  {this.props.location}
                </div>
              </div>
            }
            {this.props.boxStyle !== "onlyMedia" && this.props.showProgressBar &&
              <div
                className={styles.IA_progressBarBackground}
                style={{
                  backgroundColor: this.props.colorTheme?.textColor + "5e",
                  width: this.props.width / 4,
                  height: this.props.sizeUnit * 0.5,
                  marginTop: this.props.sizeUnit * 2.5,
                  marginBottom: this.props.sizeUnit * 2.5,
                }}
              >
                {this.state.progressBar !== undefined && !this.props.articleEditMode &&
                  <div
                    id={`IA_ProgressBar_${this.index}`}
                    className={styles.IA_progressBar}
                    style={{
                      width: this.state.progressBar,
                      transition: `width ${this.state.progressBarDuration}s linear`,
                      backgroundColor: this.props.colorTheme?.textColor,
                    }}
                  />
                }
              </div>
            }
            <div
              className={styles.IA_contentWrapper}
              id={`IA_ContentWrapper_${this.index}`}
              style={{
                marginTop: !this.props.showProgressBar ? this.props.sizeUnit * 2 : undefined,
                height: contentWrapperHeight,
                overflow: this.props.enableScroll ? "hidden" : "unset"
              }}
            >
              <IAReader
                useNewEditor
                id={`IA_MessageTextContent_${this.index}`}
                content={text}
                color={this.props.colorTheme?.textColor}
                fontSize={this.getFontSize(3.3)}
                fontSlots={{
                  title: { fontFamily: this.props.headlineFont },
                  heading: { fontFamily: this.props.headlineFont },
                  body: { fontFamily: this.props.contentFont },
                  label: { fontFamily: this.props.contentFont }
                }}
                lineHeight={this.getFontSize(5)}
                showTableBorder={this.props.showTableBorder}
                style={{
                  position: "relative",
                  transition: `margin-top ${this.state.scrollDuration}s ease-in-out`,
                  marginTop: isBoxStyle ? 0 : this.state.scrollTop,
                  willChange: "marginTop",
                  fontSize: this.getFontSize(3.3),
                  lineHeight: this.getFontSize(5),
                  borderWidth: 1
                }}
              />
              {/* {showQRCodeInline &&
                  <QRCode
                    link={this.props.link}
                    linkText={this.props.linkText}
                    sizeUnit={this.props.sizeUnit}
                    language={this.props.language}
                    style={{
                      marginTop: this.props.sizeUnit,
                      position: "relative",
                      float: "right"
                    }}
                  />
                } */}
            </div>
          </div>
        </div>
        {this.props.startDate && !this.props.isVertical && this.props.showTimeBox && contentBoxWidth !== "100%" && this.props.boxStyle !== "onlyMedia" &&
          <div
            style={{
              position: "absolute",
              width: this.props.width / 6,
              height: this.props.width / 6,
              right: this.props.contentPosition === "left" ? (this.props.width / 4) - this.props.width / 12 : undefined,
              left: this.props.contentPosition === "right" ? (this.props.width / 4) - this.props.width / 12 : undefined,
              top: (this.props.height / 2) - (this.props.width / 12),
              zIndex: 999,
              backgroundColor: `${this.props.colorTheme?.backgroundColor}cc`,
              boxSizing: "border-box",
              border: this.props.image ? "1px solid transparent" : `1px solid ${this.props.colorTheme?.textColor}`
            }}
          >
            {/* One day event */}
            {this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date(this.props.endDate)) ?
              /* All day event */
              this.props.isAllDayEvent ?
                <div
                  className={styles.IA_now}
                  style={{
                    fontSize: this.getFontSize(5),
                    color: this.props.colorTheme?.textColor,
                    lineHeight: this.getFontSize(6)
                  }}
                >
                  {this.localizationService.strings.Player_Now?.toUpperCase()}
                </div>
                :
                this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date()) ?
                  <>
                    <div
                      className={styles.IA_startDate}
                      style={{
                        fontSize: this.getFontSize(5),
                        marginTop: this.props.sizeUnit * 7.1,
                        color: this.props.colorTheme?.textColor
                      }}
                    >
                      {this.dateTimeService.ConvertToHHMM(this.props.startDate)}
                    </div>
                    <div
                      className={styles.IA_startDateendDateDivider}
                      style={{
                        backgroundColor: this.props.colorTheme?.textColor
                      }}
                    />
                    <div
                      className={styles.IA_endDate}
                      style={{
                        fontSize: this.getFontSize(5),
                        color: this.props.colorTheme?.textColor
                      }}
                    >
                      {this.dateTimeService.ConvertToHHMM(this.props.endDate)}
                    </div>
                  </>
                  :
                  <div
                    className={styles.IA_now}
                    style={{
                      fontSize: this.getFontSize(5),
                      color: this.props.colorTheme?.textColor,
                      lineHeight: this.getFontSize(6)
                    }}
                  >
                    {`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`}
                  </div>
              :
              /* More than one day event */
              <>
                <div
                  className={styles.IA_startDate}
                  style={{
                    fontSize: this.getFontSize(5),
                    marginTop: this.props.sizeUnit * 7.1,
                    color: this.props.colorTheme?.textColor
                  }}
                >
                  {!this.props.isAllDayEvent ?
                    `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService
                      .getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`
                    :
                    `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`
                  }
                </div>
                <div
                  className={styles.IA_startDateendDateDivider}
                  style={{
                    backgroundColor: this.props.colorTheme?.textColor
                  }}
                />
                <div
                  className={styles.IA_endDate}
                  style={{
                    fontSize: this.getFontSize(5),
                    color: this.props.colorTheme?.textColor
                  }}
                >
                  {`${this.dateTimeService.getDayInMonth(this.props.endDate)}. ${this.dateTimeService.getNameOfMonth(this.props.endDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`}

                </div>
              </>
            }
          </div>
        }
        {this.props.link &&
          <QRCode
            id={`IA_qrCodeDiv_${this.index}`}
            link={this.props.link}
            linkText={this.props.linkText}
            sizeUnit={this.props.sizeUnit}
            language={this.props.language}
            style={{
              left: this.props.isVertical ? "" : (this.state.contentPosition === "left" ? "" : contentBoxLeftPadding / 2),
              right: this.props.isVertical ? contentBoxLeftPadding : (this.state.contentPosition === "right" ? "" : contentBoxLeftPadding / 2),
              bottom: contentBoxLeftPadding / 2,
            }}
          />
        }
      </div >
    ) : (<></>);
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

}