exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_1IL9LiotT_okl4CH-L-mup {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_2zumraTX53zpftwbj7j-HJ {\n  float: left;\n  clear: both;\n  position: relative;\n  font-weight: bold;\n}\n\n.IA_contentWrapper_3KUcxtHcEefh-VkLwwsIgV {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_21jsCk9AlPaCuAteYah2Yx {\n  float: left;\n  clear: both;\n}\n\n.IA_text_21jsCk9AlPaCuAteYah2Yx p,\n.IA_text_21jsCk9AlPaCuAteYah2Yx ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_j95jkNbIJiI6iVzNjK8-C {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_3OxPFzMQIwWEMS3ZTcoAxk {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_2dG3IWFtwvAnlWM8nNpmhk {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_1IL9LiotT_okl4CH-L-mup",
	"IA_title": "IA_title_2zumraTX53zpftwbj7j-HJ",
	"IA_contentWrapper": "IA_contentWrapper_3KUcxtHcEefh-VkLwwsIgV",
	"IA_text": "IA_text_21jsCk9AlPaCuAteYah2Yx",
	"IA_progressBarBackground": "IA_progressBarBackground_j95jkNbIJiI6iVzNjK8-C",
	"IA_startDate": "IA_startDate_3OxPFzMQIwWEMS3ZTcoAxk",
	"IA_byline": "IA_byline_2dG3IWFtwvAnlWM8nNpmhk"
};