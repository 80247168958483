exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardFooter_20fnZgUH-qyLPK33XkBRjP {\n  height: 40px;\n  width: calc(100% - 20px);\n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  bottom: 0;\n  margin: 0 10px;\n  border-top: 1px solid #eeeeee;\n}\n\n.IA_cardContainer_A3wqPHh9ZPE5sFl3PD9wd {\n  float: none;\n}\n\n.IA_cardBarBtn_bz2RQ5c_W9MdlPdveW-VP {\n  float: right;\n  width: 20px;\n  height: 39px;\n  text-align: center;\n  cursor: pointer;\n  line-height: 40px;\n  border-radius: 5px;\n}\n\n.IA_cardBarBtn_bz2RQ5c_W9MdlPdveW-VP:hover {\n  background-color: rgba(0, 0, 0, 0.10);\n}\n\nul.IA_cardBarItems_3rI7A4rJUN_4WD5rlFvDfH {\n  background-color: #FFF;\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n  margin: 0;\n  padding: 0;\n  position: absolute;\n  z-index: 10000;\n  min-width: 180px;\n  right: 0;\n  bottom: 40px;\n  border-radius: 5px;\n}\n\n.IA_commandBarItem_3JzoulSDXrimBXoJxs94tl {\n  width: 100%;\n  list-style: none;\n  font-size: 12px;\n  padding: 8px;\n  cursor: pointer;\n  clear: both;\n  height: 30px;\n  box-sizing: border-box;\n}\n\n.IA_commandBarItem_3JzoulSDXrimBXoJxs94tl:hover {\n  background-color: #eeeeee;\n}\n\nul.IA_cardBarItems_3rI7A4rJUN_4WD5rlFvDfH li div {\n  display: inline-block;\n}\n\nul.IA_cardBarItems_3rI7A4rJUN_4WD5rlFvDfH li:hover {\n  background-color: rgba(0, 0, 0, 0.05);\n}\n\nul.IA_cardBarItems_3rI7A4rJUN_4WD5rlFvDfH li svg {\n  margin-right: 8px;\n  position: relative;\n  top: 2px;\n}\n\n.IA_cardFooterLeftText_iwP-u00GA68VwEs5p_AmV {\n  float: left;\n  margin-top: 8px;\n  padding: 2px 5px;\n  border-radius: 5px;\n  background-color: rgba(0,0,0,0.1);\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardFooter": "IA_cardFooter_20fnZgUH-qyLPK33XkBRjP",
	"IA_cardContainer": "IA_cardContainer_A3wqPHh9ZPE5sFl3PD9wd",
	"IA_cardBarBtn": "IA_cardBarBtn_bz2RQ5c_W9MdlPdveW-VP",
	"IA_cardBarItems": "IA_cardBarItems_3rI7A4rJUN_4WD5rlFvDfH",
	"IA_commandBarItem": "IA_commandBarItem_3JzoulSDXrimBXoJxs94tl",
	"IA_cardFooterLeftText": "IA_cardFooterLeftText_iwP-u00GA68VwEs5p_AmV"
};