import * as React from 'react';
import * as styles from "./Panel.css";
import { IUser } from '../../interfaces/IUser';
import NavigatorBar from './navigationBar/NavigationBar'
import { Helper } from '../../Helper';
import { IALikesAndComments } from '../likesAndComments/LikesAndComments';
import { ILike } from '../../interfaces/ILike';
import IAAuthors from './authors/IAAuthors';
import { LocalizationService } from '../../services/LocalizationService';
import { IInstance } from '../../interfaces/IInstance';
import { Environment } from '../../services/Environment';
import { IApprover } from "./../../interfaces/IApprover";
import { IAAttachments } from '../attachments/Attachments';
import { IAIcon } from '../icon/Icon';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

export interface IFullscreenPanelProps {
  title?: string;
  navigatorTitle?: string;
  open: boolean;
  width: number;
  transition: "slide" | "fade";
  headerImage?: string;
  headerVideo?: string;
  iaConfiguration?: any;
  highlightColor?: string;
  marginTop?: number;
  // Persona
  date?: string;
  authors?: IUser[];
  // LIkes and Comments
  user?: IUser;
  itemId?: string;
  isDraft?: boolean;
  isMobile?: boolean;
  tenant?: string;
  instance?: IInstance;
  token?: string;
  component?: string;
  environment?: "Development" | "Test" | "Production";
  source?: "Home" | "Mobile" | "SharePoint";
  commentsEnabled?: boolean;
  likesEnabled?: boolean;
  likesOnCommentsEnabled?: boolean;
  enableNext?: boolean;
  enablePrevious?: boolean;
  likes?: ILike[];
  commentCount?: number;
  disableSaving?: boolean;
  isUpdating?: boolean;
  // Callbacks
  close: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
  onSaveAndClose?: () => void;
  onSaveAsDraft?: () => void;
  onReviewReject?: () => void;
  onReviewApprove?: () => void;
  onReviewSave?: () => void;
  previousMessage?: () => void;
  nextMessage?: () => void;
  onCopyLink?: () => void;
  onEmailLink?: () => void;
  likesUpdated?: (likes: any[]) => void;
  commentsUpdated?: (comments: number) => void;
  onTranslate?: (language: string) => void;
  commentClicked?: () => void;
  commentCanceled?: () => void;
  enableTranslation?: boolean;
  isTranslating?: boolean;
  dataAutomationIdPrefix?: string;
  addComment?: boolean;
  isFirstResponder?: boolean;
  showUniqueViews?: boolean;
  uniqueViewsCount?: number;
  approvers?: IApprover[];
  
  enablePrint?: boolean;
  panelId?: string;
  printing?: boolean;
  printMessage?: () => void;
}

export interface IFullscreenPanelState {
  opacity: number;
  likes?: ILike[];
  commentCount?: number;
  padding: number;
  imageHeight: number;
  addComment: boolean;
  headerImageHasCORS?: boolean;
  spAppBarWidth: number;
}

export class IAFullscreenPanel extends React.Component<IFullscreenPanelProps, IFullscreenPanelState> {
  private imgRef = React.createRef<any>();

  constructor(props: IFullscreenPanelProps) {
    super(props);
    this.state = {
      addComment: props.addComment ? props.addComment : false,
      opacity: 1,
      likes: props.likes,
      commentCount: props.commentCount,
      padding: 0,
      imageHeight: 0,
      spAppBarWidth: 0,
    }
  }

  public componentDidMount(): void {
    window.addEventListener('resize', () => this.resize());
    this.resize();
    document.addEventListener("keydown", (event) => this.escFunction(event), false);
  }

  public resize(): void {
    let imageHeight = 0;
    let spAppBarWidth = 0;

    if (window.innerWidth > 800) {
      imageHeight = 800 / 16 * 9;
    } else {
      imageHeight = window.innerWidth / 16 * 9;
    }

    if (window.hasOwnProperty('spAppBar') && window.innerWidth >= 1024) {
      spAppBarWidth = document.getElementById('sp-appBar')?.clientWidth;
    }

    this.setState({ imageHeight, padding: (window.innerWidth - 800) / 2, spAppBarWidth });

  }

  public componentWillReceiveProps(props: IFullscreenPanelProps): void {
    if (props.likes != this.props.likes) {
      this.setState({ likes: props.likes });
    }
    if (props.commentCount != this.props.commentCount) {
      this.setState({ commentCount: props.commentCount });
    }
    if (props.addComment !== this.props.addComment) {
      this.setState({ addComment: props.addComment })
    }
    if (props.open) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    }
    if (props.printing !== this.props.printing) {
      if (props.printing) {
        this.setState({ padding: 0 });
      } else {
        this.resize();
      }
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
    document.removeEventListener("keydown", (event) => this.escFunction(event), false);
  }

  private escFunction(event: any): void {
    if (event.keyCode === 27 && (this.props.isFirstResponder || this.props.isFirstResponder === undefined)) {
      this.props.onCancel != undefined ? this.props.onCancel() : {};
      this.props.close != undefined ? this.props.close() : {};
      this.hidePanel()
    }
  }

  private hidePanel(): void {
    document.body.style.overflowY = "auto";
    document.body.style.height = "inherit";
  }

  private onPinchZoom(pinch: {scale: number, x: number, y: number}): void {
    const { current: img } = this.imgRef;
    if (img) {
      const x = pinch.x;
      const y = pinch.y;
      const scale = pinch.scale;
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }

  public render(): JSX.Element {
    const keepHeaderImageWhenPrinting = this.props.printing && this.state.headerImageHasCORS;
    const removeHeaderImageWhenPrinting = this.props.printing && !this.state.headerImageHasCORS; // used to show error message when printing images that has no CORS support
    const headerHeight = removeHeaderImageWhenPrinting ? 50 : this.state.imageHeight;

    let image: string;
    if (!this.props.iaConfiguration?.cropSettings) {
      image = this.props.headerImage;
    } else {
      image = Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, this.props.headerImage, undefined, "StandardLarge", this.props.iaConfiguration?.cropSettings);;
    }
    let width = this.props.width;
    if (document.getElementById("sp-appBar") && document.getElementById("spPageChromeAppDiv") && document.getElementById("spPageChromeAppDiv").offsetWidth < window.innerWidth) {
      width = width - 47;
    }
    const contenAreaPadding = this.props.printing ? 0 : this.state.padding - (this.state.spAppBarWidth > 0 ? this.state.spAppBarWidth / 2 : 0);
    return (
      <div
        id={`panelId_${this.props.panelId}`}
      >
        <div
          className={styles.panelWrapper}
          style={{
            visibility: this.props.open ? "visible" : "hidden",
          }}
          onClick={() => {
            this.props.close();
            this.hidePanel();
          }}
        />
        <div
          className={styles.fullscreenPanel}
          style={{
            right: this.props.open ? 0 : -(this.props.width *2),
            transition: this.props.transition == "slide" ? `right 200ms ease-in-out` : `opacity 200ms ease-in-out`,
            marginTop: this.props.marginTop != undefined ? this.props.marginTop : 0,
            height: this.props.marginTop != undefined ? `calc(100% - ${this.props.marginTop}px` : "100%",
            width: width,
          }}
        >
          <div
            className={styles.fullscreenPanelContentArea}
            id={`fullscreenPanelContentArea-${this.props.panelId}`}
            style={{
              paddingLeft: contenAreaPadding,
              paddingRight: contenAreaPadding,
              overflowY: this.props.printing ? "hidden" : "visible",
              height: this.props.printing && "auto"
            }}
          >
            <div
              className={styles.header}
              style={{
                opacity: this.state.opacity,
                transition: "opacity 200ms"
              }}>
              {((this.props.headerImage != undefined && this.props.headerImage !== "") || this.props.headerVideo != undefined) &&
                <div
                  className={styles.headerImage}
                  style={{
                    height: headerHeight
                  }}
                >
                  {((this.props.headerVideo == undefined && !this.props.isMobile && !this.props.printing) || keepHeaderImageWhenPrinting) &&
                    <img 
                      src={image} 
                      style={{ height: headerHeight, width: "100%", objectFit: "cover" }}
                    />
                  }

                  {(this.props.headerVideo == undefined && this.props.isMobile) &&
                    <QuickPinchZoom onUpdate={(pinch) => this.onPinchZoom(pinch)}>
                      <img 
                        ref={this.imgRef} 
                        src={image} 
                        style={{ height: headerHeight, width: "100%", objectFit: "cover" }}
                      />
                    </QuickPinchZoom>
                  }

                  {this.props.headerVideo && !(this.props.isMobile && (this.props.headerVideo.indexOf("microsoftstream") != -1) || (this.props.headerVideo.indexOf("sharepoint.com") != -1 && localStorage.getItem("loginType") != undefined)) && this.props.headerVideo.indexOf("sharepoint.com") === -1 && !this.props.printing &&
                    <iframe
                      width="100%"
                      height="100%"
                      allowFullScreen
                      src={this.props.headerVideo}
                    >
                    </iframe>
                  }
                  {this.props.headerVideo && !(this.props.isMobile && (this.props.headerVideo.indexOf("microsoftstream") != -1) || (this.props.headerVideo.indexOf("sharepoint.com") === -1 && localStorage.getItem("loginType") != undefined)) && this.props.headerVideo.indexOf("sharepoint.com") !== -1 && !this.props.printing &&
                    <video
                      height="100%"
                      width="100%"
                      controls
                      poster={image}
                    >
                      <source src={this.props.headerVideo}></source>
                    </video>
                  }

                  {removeHeaderImageWhenPrinting &&
                    <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center" }}>
                      <p style={{ color: "grey" }}>{new LocalizationService().strings.Messages_ViewPanel_UnableToPrintImage}</p>
                    </div>
                  }
                </div>
              }
              <div className={styles.title}>
                <h1>{this.props.title}</h1>
              </div>
              {this.props.date &&
                <div
                  style={{
                    float: "left",
                    marginBottom: 15,
                    fontSize: 13,
                    color: "#858585"
                  }}
                >
                  {this.props.date}
                </div>
              }
              {this.props.authors != undefined &&
                <IAAuthors
                  highlightColor={this.props.highlightColor}
                  authors={this.props.authors}
                />
              }
            </div>
            {this.props.children != undefined &&
              <div
                className={styles.content}
                style={{
                  opacity: this.state.opacity,
                  transition: "opacity 200ms"
                }}
              >
                {this.props.children}
                {this.props.iaConfiguration && 
                  <IAAttachments 
                    attachments={this.props.iaConfiguration.attachments}
                    linkColor={this.props.highlightColor}
                  />
                }
              </div>
            }
            {this.props.itemId != undefined && !this.props.printing && this.props.open &&
              <IALikesAndComments
                useNewEditor={false}
                top={this.props.marginTop}
                itemId={this.props.itemId}
                tenant={this.props.tenant}
                component={this.props.component === "Messages" ? "Message" : "Event"}
                instance={this.props.instance}
                token={this.props.token}
                environment={this.props.environment}
                user={this.props.user}
                imageUploadUrl={`${Helper.getMainAPIUrl(this.props.environment)}/api/${this.props.tenant}.sharepoint.com/image?relativeUrl=Lists/IAImages/Comments&site=IntraActive-Admin`}
                color={this.props.highlightColor}
                source={this.props.source}
                likesEnabled={this.props.likesEnabled}
                commentsEnabled={this.props.commentsEnabled}
                likesOnCommentsEnabled={this.props.likesOnCommentsEnabled}
                newCommentPopupIsShowing={() => { }}
                likes={this.state.likes}
                commentCount={this.state.commentCount}
                isMobile={this.props.isMobile}
                likesUpdated={(likes: any[]) => {
                  this.props.likesUpdated(likes);
                  this.setState({ likes }, () => this.forceUpdate());
                }}
                commentsUpdated={(comments: number) => {
                  this.props.commentsUpdated(comments);
                  this.setState({ commentCount: comments });
                }}
                style={{
                  marginBottom: 0,
                  opacity: this.state.opacity,
                  transition: "opacity 200ms"
                }}
                padding={40}
                commentClicked={() => {
                  if (this.props.commentClicked) {
                    this.props.commentClicked();
                    setTimeout(() => {
                      let heightPanel = document.querySelector("." + styles.fullscreenPanelContentArea).scrollHeight;
                      document.querySelector("." + styles.fullscreenPanelContentArea).scrollTop = heightPanel;
                    }, 400);
                  }
                }}
                commentCanceled={() => {
                  if (this.props.commentCanceled) {
                    this.props.commentCanceled();
                  }
                }}
              />
            }
          </div>
          <NavigatorBar
            approvers={this.props.approvers}
            showUniqueViews={this.props.showUniqueViews}
            uniqueViewsCount={this.props.uniqueViewsCount}
            navigatorTitle={this.props.navigatorTitle}
            highlightColor={this.props.highlightColor}
            user={this.props.user}
            itemId={this.props.itemId}
            isDraft={this.props.isDraft}
            tenant={this.props.tenant}
            instance={this.props.instance}
            token={this.props.token}
            environment={this.props.environment}
            component={this.props.component}
            source={this.props.source}
            commentsEnabled={this.props.commentsEnabled}
            likesEnabled={this.props.likesEnabled}
            enableNext={this.props.enableNext}
            enablePrevious={this.props.enablePrevious}
            likes={this.state.likes}
            commentCount={this.state.commentCount}
            isUpdating={this.props.isUpdating}
            isMobile={this.props.isMobile}
            disableSaving={this.props.disableSaving}
            onEdit={this.props.onEdit ? () => {
              this.props.onEdit();
              this.hidePanel();
            } : undefined}
            onDelete={this.props.onDelete ? () => {
              this.props.onDelete();
              this.hidePanel();
            } : undefined}
            onCancel={this.props.onCancel ? () => {
              this.props.onCancel();
              this.hidePanel();
            } : undefined}
            onCopyLink={this.props.onCopyLink ? () => this.props.onCopyLink() : undefined}
            onEmailLink={this.props.onEmailLink ? () => this.props.onEmailLink() : undefined}
            onSaveAndClose={this.props.onSaveAndClose ? () => {
              this.props.onSaveAndClose();
              this.hidePanel();
            } : undefined}
            onSaveAsDraft={this.props.onSaveAsDraft ? () => {
              this.props.onSaveAsDraft();
              this.hidePanel();
            } : undefined}
            onReviewReject={this.props.onReviewReject ? () => {
              this.props.onReviewReject();
              this.hidePanel();
            } : undefined}
            onReviewApprove={this.props.onReviewApprove ? () => {
              this.props.onReviewApprove();
              this.hidePanel();
            } : undefined}
            onReviewSave={this.props.onReviewSave ? () => {
              this.props.onReviewSave();
              this.hidePanel();
            } : undefined}
            enableTranslation={this.props.enableTranslation}
            dataAutomationIdPrefix={this.props.dataAutomationIdPrefix}
            previousMessage={this.props.previousMessage ?
              () => {
                this.setState({ opacity: 0 });
                setTimeout(() => {
                  this.props.previousMessage();
                  this.setState({ opacity: 1 });
                }, 400);
              } : undefined
            }
            nextMessage={this.props.nextMessage ?
              () => {
                this.setState({ opacity: 0 });
                setTimeout(() => {
                  this.props.nextMessage();
                  this.setState({ opacity: 1 });
                }, 400);
              } : undefined
            }
            close={() => {
              this.props.close();
              this.hidePanel();
            }}
            likesUpdated={(likes: any[]) => {
              this.props.likesUpdated(likes);
              this.setState({ likes }, () => this.forceUpdate());
            }}
            commentsUpdated={(comments: number) => {
              this.props.commentsUpdated(comments);
              this.setState({ commentCount: comments });
            }}
            languages={Helper.getLanguages(new LocalizationService())}
            isTranslating={this.props.isTranslating}
            onTranslate={(language: string) => {
              this.props.onTranslate(language);
            }}
            commentsClicked={
              () => {
                if (this.props.commentClicked) {
                  this.props.commentClicked();
                  setTimeout(() => {
                    document.querySelector(`#fullscreenPanelContentArea-${this.props.panelId}`).scrollTop = 10000;
                  }, 500);
                }
              }
            }
            enablePrint={this.props.enablePrint ??( this.props.instance.settings && this.props.instance.settings.enablePrinting)}
            onPrint={() => {
              Helper.imageHasCORS(this.props.headerImage).then((hasCORS) => {
                this.setState({ headerImageHasCORS: hasCORS }, () => this.props.printMessage());
              });
            }}
          />
          {this.props.printing &&
          <div 
            style={{ backgroundColor: this.props.highlightColor, width: "100vw" }}
            className={styles.IA_printOverlay}
          >
            <IAIcon
              title={"Print"}
              size={100}
              color="white"
            />
            <p className={styles.IA_printOverlayText}>
              {new LocalizationService().strings.Messages_ViewPanel_PrintOverlayText}
            </p>
          </div>
        }
        </div>
      </div>
    );
  }
}