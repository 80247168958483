exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".flipCounter_1cwnjC-jwX0sgqdbT82cNG {\n  display: block;\n  position: relative;\n  width: 140px;\n  height: 120px;\n  perspective-origin: 50% 50%;\n  perspective: 300px;\n  margin-bottom: 13%;\n  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);\n}\n\n.upperCard_2aJCvWpQ0G2inpLp7k_GCO, .lowerCard_3QyT6aaBAWBmt37SEQ8dvN {\n  display: flex;\n  position: relative;\n  justify-content: center;\n  width: 100%;\n  height: 50%;\n  overflow: hidden;\n}\n.upperCard_2aJCvWpQ0G2inpLp7k_GCO span, .lowerCard_3QyT6aaBAWBmt37SEQ8dvN span {\n  font-size: 5em;\n  font-family: \"Droid Sans Mono\", monospace;\n  font-weight: lighter;\n}\n\n.upperCard_2aJCvWpQ0G2inpLp7k_GCO {\n  align-items: flex-end;\n}\n.upperCard_2aJCvWpQ0G2inpLp7k_GCO span {\n  transform: translateY(50%);\n}\n\n.lowerCard_3QyT6aaBAWBmt37SEQ8dvN {\n  align-items: flex-start;\n}\n.lowerCard_3QyT6aaBAWBmt37SEQ8dvN span {\n  transform: translateY(-50%);\n}", ""]);

// Exports
exports.locals = {
	"flipCounter": "flipCounter_1cwnjC-jwX0sgqdbT82cNG",
	"upperCard": "upperCard_2aJCvWpQ0G2inpLp7k_GCO",
	"lowerCard": "lowerCard_3QyT6aaBAWBmt37SEQ8dvN"
};