exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_button_1W2QQw8uk3usbOc-0bSN-u {\n  border-style: none;\n  height: 30px;\n  width: auto;\n  float: left;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 14px;\n  line-height: 30px;\n  font-weight: 400;\n  cursor: pointer;\n  outline: none;\n  color:#333333;    \n  background-color:#f4f4f4;\n  padding: 0;\n  border-radius: 2px;\n}\n\n.IA_button_1W2QQw8uk3usbOc-0bSN-u .spinner_3pQ2G_tJofZUDoru2LFb8r {\n  width: 20px;\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_Cek-MSa6tyqoCQ-BkTulA 700ms linear infinite;\n  -moz-animation: spin_Cek-MSa6tyqoCQ-BkTulA 700ms linear infinite;\n  animation: spin_Cek-MSa6tyqoCQ-BkTulA 700ms linear infinite;\n }\n\n.IA_button_1W2QQw8uk3usbOc-0bSN-u .label_2qW2Sj1SkgdTxVuLZDQoTF {\n  text-align: center;\n}\n\n.IA_button_1W2QQw8uk3usbOc-0bSN-u .icon_sI1YYQ4QDQ3IldJT1wfy0 {\n  float: left;\n  margin-top: 3px;\n  margin-right: 5px;\n  line-height: normal;\n}\n@-moz-keyframes spin_Cek-MSa6tyqoCQ-BkTulA {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_Cek-MSa6tyqoCQ-BkTulA {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_Cek-MSa6tyqoCQ-BkTulA {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_button": "IA_button_1W2QQw8uk3usbOc-0bSN-u",
	"spinner": "spinner_3pQ2G_tJofZUDoru2LFb8r",
	"spin": "spin_Cek-MSa6tyqoCQ-BkTulA",
	"label": "label_2qW2Sj1SkgdTxVuLZDQoTF",
	"icon": "icon_sI1YYQ4QDQ3IldJT1wfy0"
};