exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_spider_1AuM45B0uNRULT_eDPRcqY {\n  position: absolute;\n  display: inline-block;\n  top: 0;\n  right: 10%;\n\n  /* 4. Animatie */\n  animation: swing_ahsu9IJWKELpIO1zM2m0 3s infinite;\n  transform-origin: top;\n\n  /* Bonus */\n  transition: 2s ease-in-out;\n}\n\n.IA_spiderWeb_1wTTC2bfeCGtbbKb_7cL9A {\n  background: rgba(255, 255, 255, .7);\n}\n\n.IA_spiderBody_3OWlgkFl4BmqZCjjC8MpLM {\n  position: relative;\n  background-image: url(\"https://intraactivestorage.blob.core.windows.net/cdn/play/spider.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.IA_spiderLeftEye_vP8NTkjR6OdIHrQiOBHaB {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderLeftEye_vP8NTkjR6OdIHrQiOBHaB:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_3mYzBf3WfwOztfFsr8YF0a 8s infinite;\n}\n\n.IA_spiderRightEye_10zoHEt_5x7MmB8VaimW5D {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderRightEye_10zoHEt_5x7MmB8VaimW5D:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_3mYzBf3WfwOztfFsr8YF0a 8s infinite;\n}\n\n/* 1. Animatie */\n@keyframes look_3mYzBf3WfwOztfFsr8YF0a {\n  0%, 40%, 100% {\n    transform: translateX(0);\n  }\n\n  45%, 95% {\n    transform: translateX(-110%);\n  }\n}\n\n\n/* 3. Animatie */\n@keyframes swing_ahsu9IJWKELpIO1zM2m0 {\n  0%, 100% {\n    transform: translateY(0);\n  }\n\n  50% {\n    transform: translateY(-20px);\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_spider": "IA_spider_1AuM45B0uNRULT_eDPRcqY",
	"swing": "swing_ahsu9IJWKELpIO1zM2m0",
	"IA_spiderWeb": "IA_spiderWeb_1wTTC2bfeCGtbbKb_7cL9A",
	"IA_spiderBody": "IA_spiderBody_3OWlgkFl4BmqZCjjC8MpLM",
	"IA_spiderLeftEye": "IA_spiderLeftEye_vP8NTkjR6OdIHrQiOBHaB",
	"look": "look_3mYzBf3WfwOztfFsr8YF0a",
	"IA_spiderRightEye": "IA_spiderRightEye_10zoHEt_5x7MmB8VaimW5D"
};