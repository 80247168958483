exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd .likes_iYl0GRIP_JytOWhik9v9s {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd .spinner_3Rjt5WVjbWGb8damMhgaas {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_16eNhKXP2rNzcFG7L52wNt 700ms linear infinite;\n  -moz-animation: spin_16eNhKXP2rNzcFG7L52wNt 700ms linear infinite;\n  animation: spin_16eNhKXP2rNzcFG7L52wNt 700ms linear infinite;\n}\n\n.likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd .likesText_13PQuw67vU80nHcDYAEg2q {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd .likesTextVertical_1tMi3fws8nPbk9LFPwcAxM {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_16eNhKXP2rNzcFG7L52wNt {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_16eNhKXP2rNzcFG7L52wNt {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_16eNhKXP2rNzcFG7L52wNt {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_GTdvZ_Z1xFn4YoCNDtKGd",
	"likes": "likes_iYl0GRIP_JytOWhik9v9s",
	"spinner": "spinner_3Rjt5WVjbWGb8damMhgaas",
	"spin": "spin_16eNhKXP2rNzcFG7L52wNt",
	"likesText": "likesText_13PQuw67vU80nHcDYAEg2q",
	"likesTextVertical": "likesTextVertical_1tMi3fws8nPbk9LFPwcAxM"
};