exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weatherWidgetContainer_3XrZ-prd5uBLjxQbfJN4ks {\n  height:100%;\n  width:100%;\n  display:flex;\n  flex-direction:row;\n}\n\n.IA_weatherDay_33rV6RymeiwCpKVImW1oB0 {\n  flex:1;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_iconAndPrecipationContainer_cQrkIO2nkThyQj4db-khT {\n  width:100%;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_weatherIcon_1Tz1qJ7yduUpdu-N80nAoq {\n  width: 45%;\n}\n\n.IA_precipation_2h1Zh6MmXgAw2D4q1kEMGS {\n  color:#0062bf;\n  font-weight:bold;\n}\n\n.IA_graphContainer_2DyfXFcLC2ygtO9QS2obG4 {\n  width:100%;\n  height:30%;\n  position:absolute;\n  bottom:0;\n  left:0;\n}\n\n.IA_weatherWidgetContainerVertical_1pp_RtYHYQsKjpqjDJT6vL {\n  height:100%;\n  width:100%;\n  background-color:#f0f2f4;\n  display:flex;\n  flex-direction: column;\n}\n\n.IA_weatherDayVertical_63_9lub_E1RALaRBvyG0n {\n  flex:1;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  justify-content: space-around;\n}\n\n.IA_exampleWeatherLabel_1RPL1ZavkfVTMcUZqC8aFk {\n  background-color: #ffffff;\n  color: #333333;\n  position: absolute;\n  text-align: center;\n  transform: rotate(-45deg);\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n}", ""]);

// Exports
exports.locals = {
	"IA_weatherWidgetContainer": "IA_weatherWidgetContainer_3XrZ-prd5uBLjxQbfJN4ks",
	"IA_weatherDay": "IA_weatherDay_33rV6RymeiwCpKVImW1oB0",
	"IA_iconAndPrecipationContainer": "IA_iconAndPrecipationContainer_cQrkIO2nkThyQj4db-khT",
	"IA_weatherIcon": "IA_weatherIcon_1Tz1qJ7yduUpdu-N80nAoq",
	"IA_precipation": "IA_precipation_2h1Zh6MmXgAw2D4q1kEMGS",
	"IA_graphContainer": "IA_graphContainer_2DyfXFcLC2ygtO9QS2obG4",
	"IA_weatherWidgetContainerVertical": "IA_weatherWidgetContainerVertical_1pp_RtYHYQsKjpqjDJT6vL",
	"IA_weatherDayVertical": "IA_weatherDayVertical_63_9lub_E1RALaRBvyG0n",
	"IA_exampleWeatherLabel": "IA_exampleWeatherLabel_1RPL1ZavkfVTMcUZqC8aFk"
};