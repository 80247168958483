exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_authors_3LbY4QT9IZZTxJ6UxAXJnO {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.IA_authorWrapper_S31aUIbMtPC8LAxk5Qd3K {\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.IA_authorBackground_24nCgNOzZ8C9BpdxEMTS7Z {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background-color: #f6f6f6;\n  border-radius: 34px;\n  padding-right: 10px;\n}\n\n.IA_authorsImageBackground_dTCWSwvIWYqkfGA-zeEbI {\n  height: 34px;\n  width: 34px;\n  /* border-radius: 32px; */\n  position: relative;\n  /* overflow: hidden; */\n  text-align: center;\n}\n\n.IA_authorsImageText_FHyqUpkY1nk0B7eZIC7j8 {\n  height: 100%;\n  width: 100%;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 28px;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsImage_2SLmgGfJ7vm-AznmUildha {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-position: center;\n  background-size: cover;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsDisplayName_bh6ZgdF8kOwrL-d3Tzdqn {\n  margin-left: 10px;\n  margin-bottom: 2px;\n  font-weight: 600;\n  font-size: 13px;\n}", ""]);

// Exports
exports.locals = {
	"IA_authors": "IA_authors_3LbY4QT9IZZTxJ6UxAXJnO",
	"IA_authorWrapper": "IA_authorWrapper_S31aUIbMtPC8LAxk5Qd3K",
	"IA_authorBackground": "IA_authorBackground_24nCgNOzZ8C9BpdxEMTS7Z",
	"IA_authorsImageBackground": "IA_authorsImageBackground_dTCWSwvIWYqkfGA-zeEbI",
	"IA_authorsImageText": "IA_authorsImageText_FHyqUpkY1nk0B7eZIC7j8",
	"IA_authorsImage": "IA_authorsImage_2SLmgGfJ7vm-AznmUildha",
	"IA_authorsDisplayName": "IA_authorsDisplayName_bh6ZgdF8kOwrL-d3Tzdqn"
};