exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_1YuS2rkHeBfRYrXvMC6eCx {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_2dS7hggTjNFltwpHvljVnm {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_33I1j3RafMdl4XAi0sGoD5 {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_18SsS4yddI8Lya2hilTU4F {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_Eomk6YdlfPJ4rmaHF1nya {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_r9vDf9SQlDQ5GPddLWlTc {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_sDFWYlZMeUKdXjvGL9bxq {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_2zfO8UAwgx5Bxq3gM5UDdQ {\n  -webkit-animation: spin_1GHtMiTZ0DGBS3tde9lOOk 700ms linear infinite;\n  -moz-animation: spin_1GHtMiTZ0DGBS3tde9lOOk 700ms linear infinite;\n  animation: spin_1GHtMiTZ0DGBS3tde9lOOk 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_2-N_nka310MdpX4PwowF7z {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_1GHtMiTZ0DGBS3tde9lOOk {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_1GHtMiTZ0DGBS3tde9lOOk {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_1GHtMiTZ0DGBS3tde9lOOk {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_1YuS2rkHeBfRYrXvMC6eCx",
	"IA_commandBar": "IA_commandBar_2dS7hggTjNFltwpHvljVnm",
	"IA_commandBarButton": "IA_commandBarButton_33I1j3RafMdl4XAi0sGoD5",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_18SsS4yddI8Lya2hilTU4F",
	"IA_commandBarButtonText": "IA_commandBarButtonText_Eomk6YdlfPJ4rmaHF1nya",
	"IA_comments": "IA_comments_r9vDf9SQlDQ5GPddLWlTc",
	"IA_likes": "IA_likes_sDFWYlZMeUKdXjvGL9bxq",
	"IA_spinner": "IA_spinner_2zfO8UAwgx5Bxq3gM5UDdQ",
	"spin": "spin_1GHtMiTZ0DGBS3tde9lOOk",
	"IA_personWhoLiked": "IA_personWhoLiked_2-N_nka310MdpX4PwowF7z"
};