exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_3ybHexC4WFoQ2dd25VsYDy {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_3qIUkz-sJH8o2GYdjy45e_, .IA_dividerDarkMode_1Pg1NeCETxBriQjWgDOgIx {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_1Pg1NeCETxBriQjWgDOgIx {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_2Y7VKBvAn2UWtWXyin0HFt {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_2Yf7RpiSN6n5FT_vNmkp9z {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_26vGxDJ5nGIHc0EAANxc0z {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_3ybHexC4WFoQ2dd25VsYDy",
	"IA_divider": "IA_divider_3qIUkz-sJH8o2GYdjy45e_",
	"IA_dividerDarkMode": "IA_dividerDarkMode_1Pg1NeCETxBriQjWgDOgIx",
	"IA_widgetType": "IA_widgetType_2Y7VKBvAn2UWtWXyin0HFt",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_2Yf7RpiSN6n5FT_vNmkp9z",
	"IA_swatches": "IA_swatches_26vGxDJ5nGIHc0EAANxc0z"
};