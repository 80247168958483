exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_2rxfYfpvrXy7XwDSr_81sD {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_3R2H4ZiU8HvG3KvoAd1r1W {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_BUs32ATspWA6ObRK5XtVD {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2dgllvCBWZXitMFd9tMuiE {\n  text-align: center;\n}\n\n.IA_tagButton_PDbzhHz9hmR8tyKWVGadT {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_nH2zL4djOh8TgxoNL9I_y {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_1Fhq-SULtW4b4R_koRf8Lh {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_HhTdgBwSbVu1I44H8Rocw {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_2rjo1elRFHHyWa6tns2L4U {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_2rjo1elRFHHyWa6tns2L4U:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_2rxfYfpvrXy7XwDSr_81sD",
	"IA_filters": "IA_filters_3R2H4ZiU8HvG3KvoAd1r1W",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_BUs32ATspWA6ObRK5XtVD",
	"IA_paginationPageCount": "IA_paginationPageCount_2dgllvCBWZXitMFd9tMuiE",
	"IA_tagButton": "IA_tagButton_PDbzhHz9hmR8tyKWVGadT",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_nH2zL4djOh8TgxoNL9I_y",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_1Fhq-SULtW4b4R_koRf8Lh",
	"IA_tagButtonTagName": "IA_tagButtonTagName_HhTdgBwSbVu1I44H8Rocw",
	"IA_tagButtonIcon": "IA_tagButtonIcon_2rjo1elRFHHyWa6tns2L4U"
};