import * as React from 'react';
import '../../../styles/BasicIA.css';
import './FroalaCustomStyling.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import * as styles from '../Froala.css';
// Froala
import FroalaEditorComponent from 'react-froala-wysiwyg';
import * as FroalaEditor from 'froala-editor';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/css/froala_editor.pkgd.css';
// Localizations
// I've fixed an error in Froala's localization, so we have to use a local copy
import "./localization/froala_da.js";
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/sv.js';
import 'froala-editor/js/languages/nb.js';
import { IABox } from '../box/Box';
import { Helper } from '../../../Helper';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAFontColor } from '../fontColor/FontColor';
import { IAFontBackgroundColor } from '../fontBackgroundColor/FontBackgroundColor';
import { ComposerHelper } from '../../composer/helper/ComposerHelper';
import { Environment } from '../../../services/Environment';
import { IASpinner } from '../../spinner/Spinner';
import { IAIcon } from '../../icon/Icon';
import { IAMentions } from '../mentions/Mentions';
import { IATableSettings } from '../tableSettings/TableSettings';
import { IFontSlots } from '../../../interfaces/IFontSlots';

export interface IFroalaProps {
  store?: Store;
  showTableBorder?: boolean;
  fontSlots?: IFontSlots;
  onChange: (html: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onStyleChange: (style: React.CSSProperties) => void;
  onTableSettingsChange?: (showTableBorder: boolean) => void;
}

export interface IFroalaState {
  initControls: any;
  isUploadingImage: boolean;
  showWordImagePasteErrorMessage: boolean;
  mentionsQuery: string;
  editorHasFocus: boolean;
}

@inject("store")
@observer
export class Froala extends React.Component<IFroalaProps, IFroalaState> {
  private wrapperRef: HTMLDivElement;
  private froalaRef: FroalaEditorComponent;
  private mentionsRef: HTMLDivElement;
  private readonly localizationService: LocalizationService = new LocalizationService();
  // private readonly BOX_PLUGIN = "boxPlugin";
  private readonly wordAllowedStyleProps = ["background", "color", "text-align", "vertical-align", "background-color", "padding", "margin", "margin-top", "margin-left", "margin-right", "margin-bottom", "text-decoration", "font-weight", "font-style", "text-indent", "border", "border-.*"];

  constructor(props: IFroalaProps) {
    super(props);
    this.state = {
      initControls: undefined,
      isUploadingImage: false,
      showWordImagePasteErrorMessage: false,
      mentionsQuery: undefined,
      editorHasFocus: false
    };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());

    // Importing the module normally is not working, so this is a typescript workaround
    const Froala = (FroalaEditor as unknown as typeof FroalaEditor.default);

    // Add custom icons from FluentUI
    Froala.DefineIconTemplate("svgCustomSize", '<svg viewBox="0 0 [WIDTH] [HEIGHT]" style="padding-top: [PADDINGTOP]px; padding-left: [PADDINGLEFT]px" xmlns="http://www.w3.org/2000/svg"><path d="[PATH]"></path></svg>');

    Froala.DefineIcon("clearFormatting", { template: "svgCustomSize", WIDTH: 2200, HEIGHT: 2200, PADDINGTOP: 2, PADDINGLEFT: 1, PATH: "M1243 1920h421v128H677l-248-248q-27-27-41-62t-15-74q0-38 14-73t42-63l435-435-65-197H353l-85 256H128L512 0h128l329 988 375-374 602 602-703 704zM756 768L576 228 396 768h360zm588 26l-550 550 422 422 550-550-422-422zm-283 1126l65-64-422-422-184 185q-19 19-19 45t19 45l211 211h330z" });
    Froala.DefineIcon("insertLink", { template: "svgCustomSize", WIDTH: 2200, HEIGHT: 2200, PADDINGTOP: 2, PADDINGLEFT: 0, PATH: "M1707 715q76 27 139 75t108 111 69 138 25 156q0 106-40 199t-110 162-163 110-199 41h-512q-106 0-199-40t-162-110-110-163-41-199q0-106 40-199t110-162 163-110 199-41h171q0 35-13 66t-37 54-55 36-66 14q-71 0-133 27t-108 73-73 109-27 133q0 71 27 133t73 108 108 73 133 27h512q70 0 132-27t109-73 73-108 27-133q0-92-46-168t-124-123V715zM171 683q0 91 46 167t124 124v189q-76-27-139-75T94 977 25 839 0 683q0-106 40-199t110-162 163-110 199-41h512q106 0 199 40t162 110 110 163 41 199q0 106-40 199t-110 162-163 110-199 41H853q0-35 13-66t37-54 54-37 67-14q70 0 132-27t109-73 73-108 27-133q0-70-26-132t-73-109-109-74-133-27H512q-71 0-133 27t-108 73-73 109-27 133z" });
    Froala.DefineIcon("boxColor", { template: "svgCustomSize", WIDTH: 2200, HEIGHT: 2200, PADDINGTOP: 2, PADDINGLEFT: 0, PATH: "M1690 960l-858 858-730-730 666-667V192q0-40 15-75t41-61 61-41 75-15q40 0 75 15t61 41 41 61 15 75v640h-128V192q0-26-19-45t-45-19q-26 0-45 19t-19 45v283l-549 549h1098l65-64-211-211 90-90 301 301zm-858 678l485-486H347l485 486zm1147-48q20 35 30 74t10 80q0 61-22 116t-61 97-92 66-116 25q-62 0-116-24t-94-64-63-95-24-116q0-79 40-148l257-450 251 439zm-251 330q36 0 66-14t52-39 34-57 12-67q0-49-24-90l-140-244-146 256q-23 40-23 84 0 35 13 66t37 54 53 37 66 14z" });
    Froala.DefineIcon("tableBorder", { template: "svgCustomSize", WIDTH: 28, HEIGHT: 28, PADDINGTOP: 0, PADDINGLEFT: 0, PATH: 'M3 6.75C3 4.67893 4.67893 3 6.75 3H21.25C23.3211 3 25 4.67893 25 6.75V13.0121C24.4935 12.9686 23.9789 13.0422 23.5 13.2328V11H18.5V17H19.356L17 19.356V18.5L11 18.5V23.5H13.542C13.535 23.5255 13.5283 23.5511 13.5219 23.5768L13.1661 25H6.75C4.67893 25 3 23.3211 3 21.25V6.75ZM4.5 18.5V21.25C4.5 22.4926 5.50736 23.5 6.75 23.5H9.5V18.5H4.5ZM9.5 17V11H4.5V17H9.5ZM17 17V11L11 11V17L17 17ZM23.5 6.75C23.5 5.50736 22.4926 4.5 21.25 4.5H18.5V9.5H23.5V6.75ZM17 4.5H11V9.5L17 9.5V4.5ZM9.5 4.5H6.75C5.50736 4.5 4.5 5.50736 4.5 6.75V9.5H9.5V4.5ZM23.0999 14.6695L15.1974 22.5719C14.8533 22.916 14.6092 23.3472 14.4911 23.8194L14.0334 25.6501C13.8344 26.4462 14.5556 27.1674 15.3517 26.9683L17.1824 26.5106C17.6545 26.3926 18.0857 26.1485 18.4299 25.8043L26.3323 17.9019C27.2249 17.0093 27.2249 15.5621 26.3323 14.6695C25.4397 13.7768 23.9925 13.7768 23.0999 14.6695Z' });

    // This icon is used when selecting a table background color - has been replaced by above fluentui icon
    // Froalaeditor.DefineIcon("boxColor", { PATH: "M16.6,12.4L7.6,3.5L6.2,4.9l2.4,2.4l-5.2,5.2c-0.6,0.6-0.6,1.5,0,2.1l5.5,5.5c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4  l5.5-5.5C17.2,14,17.2,13,16.6,12.4z M5.2,13.5L10,8.7l4.8,4.8H5.2z M19,15c0,0-2,2.2-2,3.5c0,1.1,0.9,2,2,2s2-0.9,2-2  C21,17.2,19,15,19,15z", template: "svg" });
    Froala.RegisterCommand(`boxColor_${this.props.store.editorInstanceId}`, {
      title: this.localizationService.strings.Editor_BoxStyle,
      icon: "boxColor",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        this.props.store.showBoxTool = !this.props.store.showBoxTool;
      },
    });

    Froala.RegisterCommand(`tableBorder${this.props.store.editorInstanceId}`, {
      title: "Table Border",
      icon: "tableBorder",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        this.props.store.showTableSettings = !this.props.store.showTableSettings;
      },
    });

    Froala.RegisterCommand(`fontColor_${this.props.store.editorInstanceId}`, {
      title: this.localizationService.strings.Editor_TextColor,
      icon: "textColor",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        const editor = this.getEditor();
        editor.selection.save();
        this.props.store.showFontColorTool = !this.props.store.showFontColorTool;
      },
    });

    Froala.RegisterCommand(`fontBackgroundColor_${this.props.store.editorInstanceId}`, {
      title: this.localizationService.strings.Editor_TextBackgroundColor,
      icon: "backgroundColor",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        const editor = this.getEditor();
        editor.selection.save();
        this.props.store.showFontBackgroundColorTool = !this.props.store.showFontBackgroundColorTool;
      },
    });

    addEventListener("mousemove", (event) => {
      if (!this.props.store.showTableSettings && !this.props.store.showBoxTool && !this.props.store.showFontColorTool && !this.props.store.showFontBackgroundColorTool) {
        this.props.store.cursorPositionLeft = event.clientX;
        this.props.store.cursorPositionTop = event.clientY;
      }
    });
  }

  public componentDidMount(): void {
    this.updateStyle();
    if (this.props.store.isMobile) {
      this.props.store.mentionsPopupMaxHeight = 250;
    }
  }

  private getEditor(): FroalaEditor.default {
    return this.froalaRef?.getEditor();
  }

  private updateStyle(): void {
    if (this.props.store.boxStyle !== Helper.defaultBoxStyle) {
      this.updateBoxStyling(this.props.store.boxStyle);
      if (this.props.onStyleChange) {
        this.props.onStyleChange(this.props.store.boxStyle);
      }
    }
  }

  private updateBoxStyling(style: React.CSSProperties): void {
    const editorWrappers = this.wrapperRef?.getElementsByClassName("fr-element");
    if (editorWrappers?.length > 0) {
      const editorWrapper: any = editorWrappers[0];
      editorWrapper.style.backgroundColor = style.backgroundColor;
      editorWrapper.style.borderColor = style.borderColor;
      editorWrapper.style.borderRadius = `${style.borderRadius}px`;
      editorWrapper.style.borderStyle = style.borderStyle;
      editorWrapper.style.borderWidth = "2px";
      editorWrapper.style.padding = style.padding === "0px" ? "10px" : style.padding;

    } else {
      setTimeout(() => {
        this.updateBoxStyling(style);
      }, 0);
    }
  }

  private getToolbox(): string[] {
    const toolbox: string[] = [];
    if (this.props.store.simple) {
      if (!this.props.store.isMobile) {
        toolbox.push("emoticons");
      }
      toolbox.push("insertImage");
    } else {
      if (this.props.store.fontSizeSupport) {
        toolbox.push("paragraphFormat");
      }
      if (this.props.store.fontFamilySupport) {
        toolbox.push("fontFamily");
      }
      if (this.props.store.fontStyleSupport) {
        toolbox.push("bold");
        toolbox.push("italic");
        toolbox.push("underline");
      }
      if (this.props.store.fontStyleAdditionalSupport) {
        toolbox.push("strikeThrough");
        toolbox.push("subscript");
        toolbox.push("superscript");
      }
      if (this.props.store.textColorSupport) {
        toolbox.push(`fontColor_${this.props.store.editorInstanceId}`); // custom plugin
        toolbox.push(`fontBackgroundColor_${this.props.store.editorInstanceId}`); // custom plugin
        // toolbox.push("textColor"); // froala plugin
        // toolbox.push("backgroundColor"); // froala plugin
      }
      if (this.props.store.boxSupport) {
        toolbox.push(`boxColor_${this.props.store.editorInstanceId}`);
      }
      if (this.props.store.linkSupport) {
        toolbox.push("insertLink");
      }
      if (this.props.store.textAlignmentSupport) {
        toolbox.push("alignLeft");
        toolbox.push("alignCenter");
        toolbox.push("alignRight");
        toolbox.push("alignJustify");
      }
      if (this.props.store.lineHeightSupport) {
        toolbox.push("lineHeight");
      }
      if (this.props.store.listsSupport) {
        toolbox.push("formatOL");
        toolbox.push("formatUL");
      }
      if (this.props.store.indentSupport) {
        toolbox.push("outdent");
        toolbox.push("indent");
      }
      if (this.props.store.imageSupport) {
        toolbox.push("insertImage");
      }
      if (this.props.store.videoSupport) {
        toolbox.push("insertVideo");
      }
      if (this.props.store.tableSupport) {
        toolbox.push("insertTable");
      }
      if (this.props.store.tableSettingsSupport) {
        toolbox.push(`tableBorder${this.props.store.editorInstanceId}`);
      }
      if (this.props.store.emojiSupport) {
        toolbox.push("emoticons");
      }
      if (this.props.store.paragraphStyleSupport) {
        toolbox.push("paragraphStyle");
      }
      if (this.props.store.quoteSupport) {
        toolbox.push("quote");
      }
      if (this.props.store.htmlSupport) {
        toolbox.push("html");
      }
      if (this.props.store.clearStylingSupport) {
        toolbox.push("clearFormatting");
        toolbox.push("undo");
        toolbox.push("redo");
      }
    }
    return toolbox;
  }

  private checkForMention() {
    if (!this.froalaRef) return;
    const editor = this.getEditor();
    const selection = editor.selection.get() as Selection;

    // check if user is typing next to a @
    const text = selection.focusNode?.textContent;

    if (!text) return;

    let index = selection.focusOffset - 1;
    let letter = text[index];
    let spacebarCounter = 0;
    while (letter !== "@" && spacebarCounter <= 3 && index >= 0 && letter !== "." && letter !== "," && letter !== "!" && letter !== "?") {
      if (/\s/.test(letter)) {
        spacebarCounter++;
      }
      letter = text[--index];
    }
    let isMentioning = letter === "@";
    if (isMentioning) {
      const mentionsQuery = text.substring(index, selection.focusOffset);
      this.setState({ mentionsQuery });

      // find position of the "@" on the page
      const range = document.createRange();
      range.setStart(selection.focusNode, index);
      range.setEnd(selection.focusNode, selection.focusOffset);
      this.props.store.mentionsRange = range;

      const rect = range.getBoundingClientRect();

      // set position of the popup
      this.props.store.mentionsPopupLeft = rect.left;
      this.props.store.mentionsPopupTop = rect.bottom;
      this.props.store.mentionsPopupBottom = rect.top;

      // calculate if the popup should open above or below the "@"
      if (this.props.store.mentionsPopupBottom - this.props.store.mentionsPopupMaxHeight < 0 && !this.props.store.isMobile) {
        this.props.store.mentionsPosition = "below";
      } else {
        this.props.store.mentionsPosition = "above";

        if (this.props.store.isMobile) {
          // make sure popup is not exceeding the top of the page
          this.props.store.mentionsPopupMaxHeight = this.props.store.mentionsPopupBottom - 50;
        }
      }

      if (mentionsQuery.length > 1) {
        this.props.store.showMentionsTool = true;
      } else {
        this.props.store.showMentionsTool = false;
      }
    } else {
      this.props.store.showMentionsTool = false;
    }
  }

  private isSelectionInsideMention(): { isMention: boolean, selectedElement: HTMLElement } {
    const selection = this.getEditor().selection.get() as Selection;
    let element: HTMLElement;
    if (selection.focusNode.nodeName === "A") {
      element = selection.focusNode as HTMLElement;
    } else {
      element = selection.focusNode?.parentElement;
    }

    const isMention = element?.nodeName === "A" && element?.getAttribute(this.props.store.mentionsAttributeName);
    return {
      isMention: !!isMention,
      selectedElement: element
    };
  }

  public render(): JSX.Element {
    let uploadingOverlayHeight = 0;
    let uploadingOverlayWidth = 0;
    if (this.wrapperRef) {
      const wrapper = this.wrapperRef.querySelector<HTMLElement>(".fr-wrapper");
      const element = this.wrapperRef.querySelector<HTMLElement>(".fr-element");
      if (element && this.props.store.minHeight) {
        element.style.minHeight = `${this.props.store.minHeight}px`;
      }
      if (this.props.store.simple) {
        this.wrapperRef.style.backgroundColor = "transparent";
        if (wrapper) {
          wrapper.style.width = "calc(100% - 60px)";
        }
        if (element) {
          element.style.minHeight = "unset";
        }
        const line = this.wrapperRef.querySelector<HTMLElement>(".fr-newline");
        if (line) {
          line.style.display = "none";
        }
        const toolbar = this.wrapperRef.querySelector<HTMLElement>(".fr-toolbar");
        if (toolbar) {
          toolbar.style.top = "0px";
          toolbar.style.height = "0px";
          toolbar.style.width = "100%";
          toolbar.style.position = "absolute";
          toolbar.querySelectorAll<HTMLElement>(".fr-btn").forEach((button, i) => {
            if (button && button.parentElement?.className === "fr-btn-grp fr-float-left") {
              button.style.position = "absolute";
              button.style.right = i === 0 ? "10px" : "40px";
              button.style.marginTop = "0px";
            }
          });
        }
      }
      uploadingOverlayHeight = this.wrapperRef.offsetHeight;
      uploadingOverlayWidth = this.wrapperRef.offsetWidth;
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 100);
    }

    const config: Partial<FroalaEditor.FroalaOptions> = {
      key: "GPD2tA9B2B1A3B1C2C1lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1A1H4A1D2B1D7B5==",
      emoticonsUseImage: false,
      language: navigator?.language?.substring(0, 2),
      placeholder: this.props.store.placeholder,
      autoFocus: true,
      toolbartop: false,
      pastePlain: this.props.store.pastePlain,
      wordPasteModal: false,
      wordAllowedStyleProps: this.props.store.pastePlain ? [] : this.wordAllowedStyleProps,
      quickInsertEnabled: false,
      toolbarBottom: this.props.store.simple,
      charCounterCount: false,
      toolbarSticky: false,
      listAdvancedTypes: false,
      spellcheck: false,
      pluginsEnabled: [
        "align",
        "charCounter",
        "codeBeautifier",
        "codeView",
        "colors",
        "draggable",
        "embedly",
        "emoticons",
        "entities",
        "file",
        "fontFamily",
        "fontSize",
        "fullscreen",
        "image",
        "inlineStyle",
        "inlineClass",
        "lineBreaker",
        "lineHeight",
        "link",
        "lists",
        "paragraphFormat",
        "paragraphStyle",
        "quickInsert",
        "quote",
        "save",
        "table",
        "url",
        "video",
        "wordPaste"
      ],
      imageDefaultAlign: "left",
      imageDefaultDisplay: "block",
      tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
      imageEditButtons: [
        "imageReplace",
        "imageAlign",
        "imageRemove",
        "|",
        "imageLink",
        "linkOpen",
        "linkEdit",
        "linkRemove",
        "-",
        "imageDisplay",
        "imageStyle",
        "imageSize"
      ],
      imageManager: false,
      linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
      linkAlwaysBlank: true,
      linkInsertButtons: [],
      paragraphFormat: {
        N: "Normal text",
        H1: "Heading 1",
        H2: "Heading 2",
      },
      paragraphFormatSelection: true,
      paragraphDefaultSelection: "Normal text",
      attribution: false,
      events: {
        "initialized": () => {
          const editor = this.getEditor();
          // check for undefined - edge case when opening certian older messages
          if (editor) {
            editor.events.on("keydown", (event: KeyboardEvent) => {
              // the following code block makes sure you can't edit a mention
              const isInsideMention = this.isSelectionInsideMention();
              if (isInsideMention.isMention && event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
  
                // if the user presses "backspace" or "delete" while inside a mention, the whole mention is removed
                if (event.key === "Backspace" || event.key === "Delete" && isInsideMention.selectedElement) {
                  isInsideMention.selectedElement.parentElement.removeChild(isInsideMention.selectedElement);
                }
  
                // for some reason spacebar is always propagated, so we remove the newly added space here
                if (event.key === " ") {
                  const selection = this.getEditor().selection.get() as Selection;
                  const range = document.createRange();
                  range.setStart(selection.focusNode, selection.focusOffset - 1);
                  range.setEnd(selection.focusNode, selection.focusOffset);
                  range.deleteContents();
                }
                return false;
              }
            });
          }
        },
        "keydown": (event: KeyboardEvent) => {
          // mention popup events
          if (this.props.store.showMentionsTool) {
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              event.preventDefault();
              this.mentionsRef.focus();
            }

            if (event.key === "Escape" && this.props.store.showMentionsTool) {
              event.stopImmediatePropagation();
              this.props.store.showMentionsTool = false;
            }
          }
        },
        "popups.show.link.edit": () => {
          // remove the "open link" and "edit link" buttons if clicking/typing on a mention
          const display = this.isSelectionInsideMention().isMention ? "none" : "inline-block";
          const openLinkButton = document.getElementById("linkOpen-1");
          const editLinkButton = document.getElementById("linkEdit-1");
          if (openLinkButton) openLinkButton.style.display = display;
          if (editLinkButton) editLinkButton.style.display = display;
        },
        "focus": () => {
          setTimeout(() => {
            if (this.props.onFocus) {
              this.props.onFocus();
            }
            this.setState({ editorHasFocus: true });
          }, 0);
        },
        "blur": () => {
          this.props.onChange(this.props.store.content);
          if (this.props.onBlur) {
            this.props.onBlur();
          }
          this.setState({ editorHasFocus: false });
        },
        "image.beforeUpload": (files) => {
          console.log("image.beforeUpload", files);
          const editor = this.state.initControls.getEditor();
          if (files.length && this.props.store.uploadImagesToSharepoint) {
            this.setState({ isUploadingImage: true });
            ComposerHelper.uploadFileDirectlyToSharepoint(
              this.props.store.environment,
              files[0],
              this.props.store.tenant,
              this.props.store.token,
              (progress) => console.log(`${progress}% uploaded`)
            ).then(image => {
              if (this.props.store.useImageAPI) {
                image = Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.store.environment), this.props.store.tenant, "00000000-0000-0000-0000-000000000000", image, undefined, "Full");
              }
              editor.image.remove(editor.image.get());
              editor.image.insert(image);
              editor.popups.hideAll();
            }).finally(() => this.setState({ isUploadingImage: false }));
          }
          return false;
        },
        "paste.beforeCleanup": (event: string) => {
          var wordImagesCount = (event?.match(/<v:imagedata/g) || []).length;
          if (wordImagesCount > 1) {
            // if user is trying to paste more than one image from word, show an error messae
            // Froala has a bug so that only one of these images is being uploaded: https://github.com/froala/wysiwyg-editor/issues/4637
            this.setState({ showWordImagePasteErrorMessage: true });
          }
        },
        "commands.after": (cmd: string) => {
          if (cmd === "emoticons") {
            const popup = document.querySelector<HTMLElement>(".fr-popup.fr-desktop.fr-active");
            // Position Emoji popup
            if (popup) {
              if (popup && popup.style) {
                popup.style.position = "fixed";
                const rect = this.wrapperRef?.getBoundingClientRect();
                popup.style.top = window.innerHeight - 300 > rect?.y ? `${rect?.y}px` : `${window.innerHeight - 300}px`;
                popup.style.left = "unset";
              }
              // Remove Emoji watermark at the bottom
              popup.querySelectorAll("p").forEach(p => {
                if (p.innerText.indexOf("Emoji free by Emoji One") !== -1) {
                  p.innerText = "";
                }
              })
            }
          }
          if (cmd === "insertTable") {
            const popup = document.querySelector<HTMLElement>(".fr-popup.fr-desktop.fr-active");
            // Position table popup
            if (popup) {
              if (popup && popup.style) {
                popup.style.position = "fixed";
                const rect = this.wrapperRef?.getBoundingClientRect();
                popup.style.top = window.innerHeight - 300 > rect?.y ? `${rect?.y}px` : `${window.innerHeight - 300}px`;
                popup.style.left = "unset";
              }
            }
          }
          if (cmd === "linkEdit") {
            // Make sure --highlight-color is used on popup that opens inside <body>
            const popup = document.querySelector<HTMLElement>(".fr-popup.fr-desktop.fr-active");
            if (popup) {
              popup.style.setProperty("--highlight-color", this.props.store.highlightColor ?? "#0098f7");
            }
          }
        },
      },
      toolbarButtons: this.getToolbox()
    };

    const style = this.props.store.style ?? {};
    const showWordImagePasteErrorMessage = this.state.showWordImagePasteErrorMessage && !this.state.isUploadingImage;
    return (
      <div
        id="Froala"
        ref={ref => this.wrapperRef = ref}
        className={this.state.editorHasFocus ? styles.editorHasFocus : styles.editorHasBlur}
        style={{
          position: "relative",
          ...style,
          ["--highlight-color" as any]: this.props.store.highlightColor,
          ["--fontFamilyHeading" as any]: this.props.fontSlots?.heading?.fontFamily,
          ["--fontFamilyBody" as any]: this.props.fontSlots?.body?.fontFamily
        }}
      >
        <div className={styles.wordImagePasteErrorMessageContainer}>
          <div
            className={styles.wordImagePasteErrorMessage}
            style={{
              paddingTop: showWordImagePasteErrorMessage ? 5 : 0,
              paddingBottom: showWordImagePasteErrorMessage ? 5 : 0,
              height: showWordImagePasteErrorMessage ? 40 : 0,
              opacity: showWordImagePasteErrorMessage ? 1 : 0
            }}
          >
            {this.localizationService.strings.Editor_WordImagePasteError}
            <IAIcon
              style={{
                position: "absolute",
                right: 12,
                marginTop: 4
              }}
              title={"Close"}
              color={"#000"}
              size={14}
              onClick={() => this.setState({ showWordImagePasteErrorMessage: false })}
            />
          </div>
        </div>
        <FroalaEditorComponent
          ref={ref => this.froalaRef = ref}
          tag="textarea"
          onManualControllerReady={(initControls) => {
            this.setState({ initControls });
            initControls.initialize();
          }}
          config={config}
          model={this.props.store.content != undefined ? this.props.store.content : ""}
          onModelChange={(content) => {
            this.props.store.content = content;
            this.props.onChange(this.props.store.content);
            if (this.props.store.mentionsSupport) {
              this.checkForMention();
            }
          }}
        />
        {
          this.state.isUploadingImage &&
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(256, 256, 256, 0.8)",
              height: uploadingOverlayHeight - 5,
              width: uploadingOverlayWidth - 10,
              zIndex: 2,
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
          </div>
        }
        {
          this.props.store.showBoxTool &&
          <IABox
            onStyleChange={(style: any) => {
              this.updateBoxStyling(style);
              this.props.onStyleChange(style);
            }}
          />
        }
        {
          this.props.store.showTableSettings &&
          <IATableSettings
            onTableSettingsChange={(showTableBorder: boolean) => this.props.onTableSettingsChange(showTableBorder)}
          />
        }
        {
          this.props.store.showFontColorTool &&
          <IAFontColor
            onClose={() => this.props.store.showFontColorTool = false}
            onStyleChange={(color) => {
              const editor = this.getEditor();
              editor.selection.restore();
              if (!editor.selection.isCollapsed()) {
                editor.colors.text(color);
              }
              this.props.onChange(this.props.store.content);
            }}
          />
        }
        {
          this.props.store.showFontBackgroundColorTool &&
          <IAFontBackgroundColor
            onClose={() => this.props.store.showFontBackgroundColorTool = false}
            onStyleChange={(color) => {
              const editor = this.getEditor();
              editor.selection.restore();
              if (!editor.selection.isCollapsed()) {
                editor.colors.background(color);
              }
              this.props.onChange(this.props.store.content);
            }}
          />
        }
        {
          this.props.store.showMentionsTool &&
          <IAMentions
            mentionsQuery={this.state.mentionsQuery}
            saveMentionRef={(ref) => this.mentionsRef = ref}
            onMentionPicked={(displayName, userPrincipalName) => {
              this.props.store.mentionsRange.deleteContents();
              const node = document.createElement("a");
              node.textContent = displayName;
              node.setAttribute(this.props.store.mentionsAttributeName, userPrincipalName);
              node.style.fontWeight = "bold";
              node.style.textDecoration = "none";
              node.style.whiteSpace = "nowrap";
              this.props.store.mentionsRange.insertNode(node);
              const whiteSpace = document.createElement("span");
              whiteSpace.textContent = "\u00A0";
              node.insertAdjacentElement("afterend", whiteSpace);
              this.props.store.showMentionsTool = false;

              const editor = this.getEditor();
              editor.selection.setAfter(whiteSpace);
              editor.selection.restore();
            }}
            closePopupAndFocusText={() => {
              this.props.store.showMentionsTool = false;
              const editor = this.getEditor();
              editor.selection.setAfter(this.props.store.mentionsRange.endContainer as Element);
              editor.selection.restore();
            }}
          />
        }
      </div >
    );
  }
}