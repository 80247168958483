exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".IA_counterBackground_3SuLYdWFVtaqpmmYgUGhMd {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_counterBackgroundOverlay_--ZE4ZUJ1aD0ATNsc4Ef_ {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_content_1psMZAkoBKILC6clX6pfi3 {\n  width: 100%;\n  position: absolute;\n  color: #ffffff;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n}\n\n.IA_headline_3MesP5-DjFcx5JlBVsYdLe {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_description_36Wtr_KTAFQ9GrN5_mwg02 {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_counter_vl1rpIQYagsrsF2FTuST0 {\n  display: flex;\n  flex-wrap: nowrap;\n  text-align: center;\n}\n\n.IA_days_2038fA_W7RzXPwc1SJ3baL {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_hours_1Nl_gER2ckHGcR5yqfHC-K {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_minuts_1soNg7gxmf88yqwv16bZIG {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_seconds_2Ncxig7I8F04WcQxZLoM4F {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_number_2HGp27tBoEK5Ovo-vAK5fo {\n  width: 100%;\n  font-family: \"Droid Sans Mono\", monospace;\n}\n\n.IA_numberInfo_2lla9OBLw7voXJuf5lEAqu {\n  width: 100%;\n  font-weight: lighter;\n}", ""]);

// Exports
exports.locals = {
	"IA_counterBackground": "IA_counterBackground_3SuLYdWFVtaqpmmYgUGhMd",
	"IA_counterBackgroundOverlay": "IA_counterBackgroundOverlay_--ZE4ZUJ1aD0ATNsc4Ef_",
	"IA_content": "IA_content_1psMZAkoBKILC6clX6pfi3",
	"IA_headline": "IA_headline_3MesP5-DjFcx5JlBVsYdLe",
	"IA_description": "IA_description_36Wtr_KTAFQ9GrN5_mwg02",
	"IA_counter": "IA_counter_vl1rpIQYagsrsF2FTuST0",
	"IA_days": "IA_days_2038fA_W7RzXPwc1SJ3baL",
	"IA_hours": "IA_hours_1Nl_gER2ckHGcR5yqfHC-K",
	"IA_minuts": "IA_minuts_1soNg7gxmf88yqwv16bZIG",
	"IA_seconds": "IA_seconds_2Ncxig7I8F04WcQxZLoM4F",
	"IA_number": "IA_number_2HGp27tBoEK5Ovo-vAK5fo",
	"IA_numberInfo": "IA_numberInfo_2lla9OBLw7voXJuf5lEAqu"
};