import * as React from 'react';
import ReactQuill from 'react-quill-with-table';
import * as styles from './Reader.css';
import '../../styles/BasicIA.css';
import { Helper } from '../../Helper';
import { IEnvironment } from '../../interfaces/IEnvironment';
import '../editor/froala/FroalaCustomStyling.css';
import { Environment } from '../../services/Environment';
import { MSPersonCard } from '../msPersonCard/MSPersonCard';
import { IFontSlots } from '../../interfaces/IFontSlots';

export interface IReaderProps {
  id?: string;
  content: string;
  useNewEditor: boolean;
  environment?: IEnvironment;
  tenant?: string;
  token?: string;
  style?: React.CSSProperties;
  removeMarginBottomFromLastParagraph?: boolean;
  highlightColor?: string;
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  isMobile?: boolean;
  showTableBorder?: boolean;
  tableBorderColor?: string;
  fontSlots?: IFontSlots;
  onOpenIAMessage?: (instance: string, messageId: string) => void;
  onImageClicked?: (imageUrl) => void;
}

export interface IReaderState {
  showPersonCard: boolean;
  personCardUPN: string;
  personCardChildRect: {
    width: number,
    height: number,
    top: number,
    left: number
  };
}

export class IAReader extends React.Component<IReaderProps, IReaderState> {
  private readerRef: HTMLDivElement;
  private id: string;

  constructor(props: IReaderProps) {
    super(props);
    this.state = {
      showPersonCard: false,
      personCardUPN: undefined,
      personCardChildRect: undefined
    };
    this.id = Helper.getRandomStringKey();
  }

  componentDidMount(): void {
    this.fixLinksAndImageOnClick(this.readerRef);
  }

  componentDidUpdate(prevProps: Readonly<IReaderProps>): void {
    if (prevProps.content !== this.props.content) {
      this.fixLinksAndImageOnClick(this.readerRef);
    }
  }

  private fixLinksAndImageOnClick(div: Element) {
    if (div) {
      const links = div?.getElementsByTagName("a");
      for (let i = 0; i < links?.length; i++) {
        const link = links[i];

        // find mentions
        if (link.getAttribute("data-upn")) {
          const upn = link.getAttribute("data-upn");
          const linkRect = link.getBoundingClientRect();
          const readerRect = this.readerRef.getBoundingClientRect();
          const newRect = {
            height: linkRect.height,
            width: linkRect.width,
            top: linkRect.top - readerRect.top,
            left: linkRect.left - readerRect.left
          };
          const callback = () => {
            if (this.state.personCardUPN !== upn || this.state.personCardChildRect !== newRect) {
              this.setState({
                showPersonCard: true,
                personCardUPN: upn,
                personCardChildRect: newRect
              });
            }
          };
          if (this.props.isMobile) {
            link.addEventListener("click", callback);
          } else {
            link.addEventListener("mouseenter", callback);
          }
          continue;
        }

        // abort if link is not defined or if link is just a blank space
        if (!link.href || /\s/.test(link.href)) {
          continue;
        }

        // handle links to intraactive messages
        const url = new URL(link.href);

        // ignore links that has the href = # - they have already been handled
        if (url.href === window.location.href || url.href === window.location.href + "#") {
          continue;
        }

        // identify links to intraactive messages, replace the href with # and add click handler
        const isLinkPortal = url.origin === Environment.getEnvironmentForLinkPortal(this.props.environment);
        const isMessageWithQuery = !!url.searchParams.get("instance") && !!url.searchParams.get("messageId");
        if (this.props.onOpenIAMessage && (isLinkPortal || isMessageWithQuery)) {
          link.setAttribute("href", "#");
          link.setAttribute("target", "_self");
          let instance: string;
          let messageId: string;
          if (isLinkPortal) {
            const urlPaths = url.pathname.replace("/", "").split("/");
            instance = urlPaths[1];
            messageId = urlPaths[2];
          }
          if (isMessageWithQuery) {
            instance = url.searchParams.get("instance");
            messageId = url.searchParams.get("messageId");
          }
          link.addEventListener("click", () => this.props.onOpenIAMessage(instance, messageId));
          continue;
        }

        // set target _blank on links
        if (link.href !== window.location + "#" && (link.classList.length === 0 || !link.className?.startsWith("ql-"))) {
          link.setAttribute("target", "_blank");
          link.setAttribute("data-interception", "off");
        }
      }

      if (this.props.onImageClicked) {
        const images = div?.getElementsByTagName("img");
        for (let i = 0; i < images?.length; i++) {
          const image = images[i];
          if (image.src) {
            image.style.cursor = "pointer";
            image.onclick = () => this.props.onImageClicked(image.src);
          }
        }
      }
    }
  }

  public render(): JSX.Element {
    const style = this.props.style ?? {};
    if (!style["---color"]) {
      style["--borderColor"] = this.props.showTableBorder ? (this.props.tableBorderColor ? this.props.tableBorderColor : (this.props.color ? this.props.color : "transparent")) : "transparent";
      style["--color"] = this.props.color ?? "lightgray";
      style["--fontSize"] = this.props.fontSize ?? "16px";
      style["--lineHeight"] = this.props.lineHeight ?? "20px";
      if (this.props.fontSlots) {
        style["--fontFamilyHeading"] = this.props.fontSlots?.heading?.fontFamily;
        style["--fontFamilyBody"] = this.props.fontSlots?.body?.fontFamily;
      }
    }
    return (
      <div
        id={this.props.id}
        ref={(ref) => this.readerRef = ref}
        className={styles.IA_reader}
      >
        {this.props.useNewEditor ?
          <div
            id={`reactQuill_${this.id}`}
            className={[styles.IA_froala, "fr-wrapper", "fr-element", "fr-view", this.props.removeMarginBottomFromLastParagraph ? styles.IA_froala_remove_margin : ""].join(" ")}
            style={{
              ...style,
              borderWidth: 2,
              ['--highlight-color' as any]: this.props.highlightColor,
            }}
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
          :
          <ReactQuill
            id={`reactQuill_${this.id}`}
            style={{ ...this.props.style, borderWidth: 1 }}
            className={["IA_quillReadOnlyContainer"].join(" ")}
            value={this.props.content}
            readOnly
            modules={{
              toolbar: false
            }}
          />
        }
        {this.state.showPersonCard &&
          <MSPersonCard
            userLoginName={this.state.personCardUPN}
            highlightColor={this.props.highlightColor}
          >
            <div style={{
              position: "absolute",
              height: this.state.personCardChildRect.height,
              width: this.state.personCardChildRect.width,
              top: this.state.personCardChildRect.top,
              left: this.state.personCardChildRect.left,
              zIndex: 1
            }} />
          </MSPersonCard>}
      </div>
    );
  }
}